"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _colors = require("@material-ui/core/colors");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var colorMap = {
  red: _colors.red[200],
  amber: _colors.amber[600],
  blue: _colors.blue[500],
  orange: _colors.deepOrange[400],
  lightGreen: _colors.lightGreen[200],
  green: _colors.green[200]
};

var _default = function _default() {
  var prop = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'color';
  var className = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : function (c) {
    return c;
  };
  return Object.keys(colorMap).reduce(function (memo, state) {
    return _objectSpread(_objectSpread({}, memo), {}, _defineProperty({}, className(state), _defineProperty({}, prop, colorMap[state])));
  }, {});
};

exports["default"] = _default;