import React from 'react';
import PropTypes from 'prop-types';
import { CoverView } from '@solar/solar-react';

import SubscribeData from '../../../SubscribeData';

import ACLRequestInfo from './ACLRequestInfo';

const ACLRequestSheet = ({ history, user, id, handleDetailViewToggle, ...other }) => {
  return (
    <SubscribeData>
      {({ aclRequests, grantACLRequest, helpers: { get, all } }) => {
        const aclRequest = get(aclRequests, id);
        const certification = aclRequest.certification();
        const { certificate: c, unit: u } = certification;
        const certificate = c();

        return (
          <CoverView title={'Toegangsaanvraag'} cover={certificate.coverSrc}>
            <ACLRequestInfo
              aclRequest={aclRequest}
              certification={certification}
              grantACLRequest={(v) => grantACLRequest(aclRequest, v, user)}
              openCustomer={(id) => handleDetailViewToggle('customer', id)}
            />
          </CoverView>
        );
      }}
    </SubscribeData>
  );
};

ACLRequestSheet.propTypes = {
  history: PropTypes.object,
  user: PropTypes.object,
  id: PropTypes.string,
};

export default ACLRequestSheet;
