import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import { memoizeWith, identity } from 'ramda';
import Fuse from 'fuse.js';

import Typography from '@material-ui/core/Typography';

import { Flex, ScrollableContent } from '@solar/solar-react';

import { ResponsiveSearchField } from '@probeton/solar-react';

import SubscribeData from '../../../SubscribeData';
import { users } from '@probeton/mockdata';
import FabView from '../FabView';

import { ContactList } from '@probeton/ui';

const ContactSearch = ({ contacts, onCreateContact, title = 'Contacten', ...other }) => {
  const fuseSearchMemoized = useMemo(() => {
    const options = {
      keys: ['fullName'],
      shouldSort: true,
      threshold: 0.3,
    };
    const fuse = new Fuse(contacts, options);
    return memoizeWith(identity, (search) => fuse.search(search));
  }, [contacts.length]);

  const [search, setSearch] = useState('');
  const filteredContacts = search === '' ? contacts : fuseSearchMemoized(search);

  return (
    <FabView
      title={
        <Flex alignItems="center" justifyContent="space-between" flexGrow={1}>
          <Typography variant="headline" color="inherit" noWrap>
            {title}
          </Typography>
          <ResponsiveSearchField
            InputProps={{
              id: 'searchContacts',
            }}
            placeholder="Zoeken"
            value={search}
            onChange={(value) => setSearch(value)}
          />
        </Flex>
      }
      titleOnTop
      disableShadow
      onClickFab={() => {
        onCreateContact();
      }}
    >
      <ScrollableContent>
        <ContactList contacts={filteredContacts} {...other} />
      </ScrollableContent>
    </FabView>
  );
};

const ContactView = (props) => {
  return (
    <SubscribeData>
      {({ manufacturers, helpers: { all } }) => {
        const contacts = all(manufacturers).reduce(
          (memo, { _id: manufacturerId, contact, units }) => [
            ...memo,
            { ...contact, manufacturerId },
            ...units().reduce(
              (memo2, { _id: unitId, contact: uContact }) => [
                ...memo2,
                ...(uContact
                  ? [
                      {
                        ...uContact,
                        unitId,
                        manufacturerId,
                      },
                    ]
                  : []),
              ],
              [],
            ),
          ],
          [],
        );
        return <ContactSearch contacts={contacts} {...props} />;
      }}
    </SubscribeData>
  );
};

export const UserView = (props) => {
  const contacts = users.map(({ role, ...other }) => ({ subheader: role, ...other }));
  return <ContactSearch contacts={contacts} {...props} />;
};

ContactView.propTypes = {
  openContact: PropTypes.func,
};

export default ContactView;
