"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _helpers = require("@probeton/helpers");

var _faker = _interopRequireDefault(require("faker"));

var _fixtures = require("../fixtures");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const randomCodes = 'EAN-BLU-ADEP-PRU-PLU-BTA'.split('-');
const possibleVariations = 'breedte-hoogte-inhoud-materiaal-afwerking';

const p = (certification, product = {}, generateFields = true) => ({
  coverSrc: `https://placeimg.com/800/400/tech?t=${(0, _helpers.rnd)(100)}`,
  ...(generateFields ? {
    name: [_faker.default.commerce.product(), _faker.default.commerce.productName(), _faker.default.commerce.productMaterial()].join(' '),
    code: `${(0, _helpers.getRandomElement)(randomCodes)}-${(0, _helpers.rnd)(999)}/${(0, _helpers.rnd)(20)}`,
    description: _faker.default.random.words(10 + (0, _helpers.rnd)(10)),
    specification: _faker.default.random.words(30 + (0, _helpers.rnd)(20)),
    variations: Array(...Array(3 + (0, _helpers.rnd)(10))).map(i => _faker.default.random.words((0, _helpers.rnd)(5)))
  } : {}),
  images: Array(...Array(2 + (0, _helpers.rnd)(3))).map(i => ({
    src: `https://placeimg.com/800/400/tech?t=${(0, _helpers.rnd)(100)}`,
    label: _faker.default.random.words(1 + (0, _helpers.rnd)(2))
  })),
  certificationId: certification._id,
  manufacturerId: certification.manufacturerId,
  unitId: certification.unitId,
  ...product.data,
  ...product,
  _id: product._id || (0, _helpers.uuid)()
});

const editProduct = (product, edits, editedBy, type = 'productEdit') => {
  const editedAt = new Date(); // if product was not valid yet, or only 1 edit (creation), that was not bundled
  // all changes are applied direcly
  // console.log({ product, edits });

  if (type === 'productEdit' && (!product.isValid || !product.edits || product.edits.length === 1 && product.edits[0].status === 'created')) {
    return {
      product: { ...product,
        ...edits,
        data: { ...product.data,
          ...edits.data
        },
        lastEdited: editedAt
      }
    };
  } // if an edit was found with status created (not bundled in ticket yet) from the same author
  // add edits to this existing edit


  if (product.edits && product.edits.length > 1) {
    const existingEdit = product.edits.find(({
      status,
      editedBy: author
    }) => status === 'created' && author._id === editedBy._id);

    if (existingEdit) {
      return {
        product: { ...product,
          edits: product.edits.map(edit => {
            if (edit._id === existingEdit._id) {
              return { ...edit,
                editedAt,
                state: { ...edit.state,
                  data: { ...edit.state.data,
                    ...edits.data
                  }
                }
              };
            }

            return edit;
          }),
          lastEdited: editedAt
        }
      };
    }
  } // console.log('edit product', { product, edits, editedBy });
  // else add to list of edits


  return {
    product: { ...product,
      edits: [...(product.edits || []), {
        _id: (0, _helpers.uuid)(),
        editedAt,
        editedBy,
        state: edits,
        type,
        status: 'created'
      }],
      lastEdited: editedAt
    }
  };
};

const createProduct = (certification, product, createdBy, generateFields = true) => {
  const createdAt = product.createdAt || new Date();
  const pr = p(certification, product, generateFields);
  return editProduct({ ...pr,
    createdAt,
    createdBy: createdBy._id,
    status: 'created'
  }, {
    fabricateId: pr._id,
    data: {}
  }, createdBy, 'productAdd');
};

const bundleEdits = product => {
  return {
    product: { ...product,
      edits: product.edits.map(({
        status,
        ...other
      }) => ({ ...other,
        status: 'bundled'
      }))
    }
  };
};

const generateProducts = async certifications => [...certifications.reduce((memo, certification) => [...memo, ...Array(...Array(1 + (0, _helpers.rnd)(15))).map(i => p(certification))], []), ..._fixtures.products.map(product => {
  const c = certifications.find(c => c.number === product.number);
  return p(c, product);
})];

var _default = {
  generators: {
    product: generateProducts
  },
  mutations: {
    product: stores => ({
      create: (certification, product, createdBy, generateFields = true) => {
        const {
          product: newlyCreatedproduct
        } = createProduct(certification, product, createdBy, generateFields);
        stores().crud.create('product')(newlyCreatedproduct);
        return newlyCreatedproduct;
      },
      edit: (product, edits, editedBy) => {
        const {
          product: updatedproduct
        } = editProduct(product, edits, editedBy);
        stores().crud.update('product')(updatedproduct);
        return updatedproduct;
      },
      duplicate: (product, createdBy, amount = 1) => {
        const c = product.certification(); // remove _id & code and make sure is invalid without code

        const duped = {
          data: { ...product.data,
            code: null
          },
          isValid: false,
          name: product.data.name,
          images: product.images || []
        }; // console.log({ duped, c });

        Array(...Array(amount)).forEach((a, i) => {
          const {
            product: newlyCreatedproduct
          } = createProduct(c, duped, createdBy, false);
          stores().crud.create('product')(newlyCreatedproduct);
        });
      },
      editsBundled: product => {
        const {
          product: updatedproduct
        } = bundleEdits(product);
        console.log({
          product,
          updatedproduct
        });
        stores().crud.update('product')(updatedproduct);
        return updatedproduct;
      }
    })
  },
  helpers: {
    product: (product, stores) => ({
      renderStatus: () => {
        const {
          status,
          certified
        } = product;

        if (!status) {
          return 'gepubliceerd';
        }

        const s = `${certified ? 'bestaand ' : 'nieuw '} fabricaat`;

        if (status === 'created') {
          return `${s} net aangemaakt`;
        }

        if (status === 'requested') {
          return `${s} aangevraagd`;
        }

        if (status === 'approved') {
          return `${s} goedgekeurd`;
        }
      },
      certification: () => stores().certifications[product.certificationId],
      unit: () => stores().units[product.unitId],
      manufacturer: () => stores().manufacturers[product.manufacturerId],
      unrequestedChanges: () => product.isValid ? product.edits || [] : [],
      draft: () => product.edits && product.edits.length ? product.edits.reduce((memo, {
        state
      }) => ({ ...memo,
        ...state
      }), product) : product
    })
  }
};
exports.default = _default;