import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import Fab from '@material-ui/core/Fab';

import Avatar from '@material-ui/core/Avatar';
import SubscribeData from '../../SubscribeData';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      position: 'absolute',
      top: theme.spacing.unit,
      right: theme.spacing.unit,
      height: 48,
      padding: 0,
      margin: 0,
      borderRadius: 24,
    },
    label: {
      padding: 0,
      paddingLeft: -16,
    },
  };
});

const ProfileInfo = ({ user, onClickLogout, onClickLogin, ...props }) => {
  const classes = useStyles();
  return (
    <SubscribeData>
      {({ certifications }) => {
        // console.log(certifications);
        // const openTickets = all(certifications).filter(({ status }) => status === 'created').length;
        return (
          <Fab
            variant="extended"
            size="medium"
            color="white"
            aria-label="add"
            className={classes.root}
            classes={{ root: classes.root, label: classes.label }}
            onClick={() => {
              user ? onClickLogout() : onClickLogin();
            }}
          >
            {user ? (
              <Avatar size="small" src={user.avatarUrl} style={{ width: 32, height: 32 }}>
                R
              </Avatar>
            ) : (
              ''
            )}
            {user ? 'Afmelden' : 'aanmelden'}
          </Fab>
        );
      }}
    </SubscribeData>
  );
};

ProfileInfo.propTypes = {
  onClickLogout: PropTypes.func.isRequired,
  onDrawerRequestClose: PropTypes.func.isRequired,
  onDrawerRequestOpen: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  profile: PropTypes.object,
};

ProfileInfo.defaultProps = {
  profile: null,
};

export default ProfileInfo;
