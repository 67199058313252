import React from 'react';

import { Route, Redirect } from 'react-router-dom';

import sheets from '../../sheets';
import dialogs from '../../dialogs';

import { App } from '@probeton/ui';

import NavigationDrawerAdmin from './NavigationDrawerAdmin';

import { UserView } from './Contact/ContactView';
import ManufacturerView from './Manufacturer/ManufacturerView';
import ProductView from './Product/ProductView';
import ProductDataEditor from './Product/ProductDataEditor';

import CertificateSheet from './Certificate/CertificateSheet';
// import CertifierList from './CertifierList';
import TicketView from './Ticket/TicketView';

import { CommonRoutes } from './CommonNav';

const AdminApp = ({ history, user, ...other }) => (
  <App
    history={history}
    user={user}
    app="ADMIN"
    sheets={sheets}
    dialogs={dialogs}
    navigationDrawer={NavigationDrawerAdmin}
    labels={{
      imageUpload: {
        actions: {},
        photoUpload: {},
      },
    }}
    {...other}
  >
    {({
      handleDetailViewToggle,
      handleDialog,
      getActionBarContainer,
      setImageOpen,
      setPdfOpen,
    }) => (
      <CommonRoutes
        handleDetailViewToggle={handleDetailViewToggle}
        handleDialog={handleDialog}
        history={history}
        user={user}
      >
        <Route path="/" exact render={() => <Redirect to="/certificates" />} />
        <Route
          path="/users"
          exact
          render={(props) => (
            <UserView
              title="Gebruikers"
              history={history}
              handleContactClick={(id) => handleDetailViewToggle('contact', id)}
              onCreateEmployee={() => handleDialog('createEmployee')}
              {...props}
            />
          )}
        />
        <Route
          path="/manufacturers"
          exact
          render={(props) => (
            <ManufacturerView
              history={history}
              openManufacturer={(id) => handleDetailViewToggle('manufacturer', id)}
              {...props}
            />
          )}
        />
        <Route
          path="/products"
          exact
          render={(props) => (
            <ProductView
              history={history}
              openProduct={(id) => handleDetailViewToggle('product', id)}
              onCreateProduct={() => handleDialog('createCertificate')}
              {...props}
            />
          )}
        />
        <Route
          path="/products/:id"
          exact
          render={({ match, ...props }) => (
            <ProductDataEditor
              id={match.params.id}
              history={history}
              openProduct={(id) => handleDetailViewToggle('product', id)}
              {...props}
            />
          )}
        />
        <Route
          path="/certificates"
          exact
          render={(props) => (
            <ProductView
              history={history}
              openProduct={(id) => handleDetailViewToggle('product', id)}
              onCreateProduct={() => handleDialog('createCertificate')}
              {...props}
            />
          )}
        />
        <Route
          path="/certificates/:id"
          exact
          render={({ match }) => {
            if (!getActionBarContainer()) {
              return 'hold it';
            }
            return (
              <CertificateSheet
                id={match.params.id}
                history={history}
                currentUser={user}
                openCertificate={(id) => history.push(`/certificates/${id}`)}
                handleDetailViewToggle={handleDetailViewToggle}
                openDialog={handleDialog}
                getActionBarContainer={getActionBarContainer}
                setImageOpen={setImageOpen}
                setPdfOpen={setPdfOpen}
              />
            );
          }}
        />
        <Route
          path="/tickets"
          exact
          render={(props) => (
            <TicketView
              history={history}
              openTicket={(id) => handleDetailViewToggle('ticket', id)}
              tabs={{
                inspector: {
                  label: 'bij Inspector',
                  statuses: ['created', 'accepted', 'rejected'],
                  dontWrap: true,
                },
                approved: {
                  label: 'goedgekeurd',
                  statuses: ['approved'],
                  dontWrap: true,
                },
                current: {
                  label: 'in behandeling',
                  statuses: ['reviewed'],
                  dontWrap: true,
                },
                published_provisional: {
                  label: 'gepubliceerd onder voorbehoud',
                  statuses: ['published_provisional'],
                  dontWrap: true,
                },
                published: {
                  label: 'gepubliceerd',
                  statuses: ['published'],
                  dontWrap: true,
                },
              }}
              {...props}
            />
          )}
        />
      </CommonRoutes>
    )}
  </App>
);

// prettier-ignore
export default AdminApp;

// <Route
//   path="/institutions"
//   exact
//   render={props => (
//     <CertifierList
//       history={history}
//       openCertifier={id => handleDetailViewToggle('certifier', id)}
//       onCreateCertifier={() => handleDialog('createCertifier')}
//       {...props}
//     />
//   )}
// />
// <Route
//   path="/manufacturers"
//   exact
//   render={props => (
//     <ManufacturerList
//       history={history}
//       openManufacturer={id => handleDetailViewToggle('manufacturer', id)}
//       onCreateManufacturer={() => handleDialog('createManufacturer')}
//       {...props}
//     />
//   )}
// />
