import React from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { List, Avatar } from '@material-ui/core';

import NewIcon from '@material-ui/icons/FiberNew';

import { FileListItem } from '@solar/solar-react';
import { CoverTabView } from '@solar/poc-ui';

import SubscribeData from '../../../SubscribeData';

import CustomerInfo from './CustomerInfo';

import { users } from '@probeton/mockdata';

import { ProfileItem } from '@probeton/ui';

const CustomerSheet = ({
  history,
  user,
  id,
  handleDetailViewToggle,
  setPdfOpen,
  setImageOpen,
  ...other
}) => {
  return (
    <SubscribeData>
      {({ downloads, aclRequests, helpers: { get, all } }) => {
        const customer = users.find(({ _id }) => _id === id);
        if (!customer) {
          return 404;
        }
        const customerDownloads = all(downloads).filter((d) => d.userId === customer._id);
        const customerACLRequests = all(aclRequests).filter((r) => r.userId === customer._id);

        console.log(customerDownloads);

        const { fullName, avatarUrl } = customer;
        const tabs = {
          info: {
            label: 'Info',
            component: <CustomerInfo customer={customer} />,
          },
          downloads: {
            label: 'Downloads',
            component: (
              <List>
                {customerDownloads.map(({ document, lastDownloadedAt }) => (
                  <FileListItem
                    interactive
                    onClickOpen={() => {
                      document.indexOf('.pdf') > 0 ? setPdfOpen(document) : setImageOpen(document);
                    }}
                    onClickDownloadFile={() => window.open(document, '__blank')}
                    label={`${format(new Date(lastDownloadedAt), 'yyyy.MM.dd')}`}
                    fileUrl={document}
                  />
                ))}
              </List>
            ),
          },
          aclRequest: {
            label: 'Aanvragen',
            component: (
              <List>
                {customerACLRequests.map(({ _id, grants, granter, createdAt, certification }) => {
                  const certificate = certification().certificate();
                  return (
                    <ProfileItem
                      onClick={(id) => handleDetailViewToggle('aclRequest', _id)}
                      textLabel={`${certificate.title} - ${certificate.renderCodes()}`}
                      {...(grants
                        ? {
                            icon: <Avatar src={granter().avatarUrl} />,
                            text: `${grants.granted ? 'goedgekeurd' : 'afgekeurd'} op ${format(
                              new Date(grants.grantedAt),
                              'yyyy.MM.dd',
                            )}`,
                          }
                        : {
                            icon: <NewIcon />,
                            text: `aangevraagd op ${format(new Date(createdAt), 'yyyy.MM.dd')}`,
                          })}
                    />
                  );
                })}
              </List>
            ),
          },
        };
        return <CoverTabView title={fullName} cover={avatarUrl} tabs={tabs} />;
      }}
    </SubscribeData>
  );
};

CustomerSheet.propTypes = {
  history: PropTypes.object,
  user: PropTypes.object,
  id: PropTypes.string,
};

export default CustomerSheet;
