export const personMapper = ({ avatarUrl, fullName, ...other }) => ({
  ...other,
  avatar: {
    src: avatarUrl,
  },
  firstName: fullName.split(' ')[0],
  lastName: fullName.split(' ')[1],
  fullName,
});

export const unitMapper = ({ address, certifications, geo, ...other }) => ({
  ...other,
  location: {
    formatted_address: address(),
    ...geo.location,
  },
  certificates: certifications().map(certificationMapper),
});

export const certificateMapper = ({ coverSrc, labels, certifications, ...other }) => ({
  ...other,
  cover: {
    src: coverSrc,
  },
  types: Object.keys(labels).map((k) => ({ key: k, title: labels[k] })),
  // Using certificationMapper results in a call stack loop
  certificates: certifications().map(({ manufacturer, unit, validityRange, number, ...other2 }) => {
    const mappedUnit = unit();
    // console.log(mappedUnit);
    const productionUnit = {
      ...mappedUnit,
      // location: {
      //   formatted_address: mappedUnit.address(),
      //   ...mappedUnit.geo.location,
      // },
    };
    return {
      ...other2,
      productionUnit,
      manufacturer: manufacturerMapper(manufacturer()),
      validityRange: validityRange(),
    };
  }),
});

export const certificationMapper = ({
  certificate,
  manufacturer,
  unit,
  // inspector,
  validityRange,
  labels,
  ...other
}) => ({
  ...other,
  product: certificateMapper(certificate()),
  manufacturer: manufacturerMapper(manufacturer()),
  // inspector: inspectorMapper(inspector()),
  // inspector: inspectorMapper({}),
  validityRange: validityRange(),
  productTypes: labels,
  labels,
});

export const manufacturerMapper = ({ _id, units, ...other }) => ({
  // Using unitMapper results in a call stack loop
  id: _id,
  units: units().map(({ address, geo, ...other2 }) => ({
    ...other2,
    // location: {
    //   formatted_address: address(),
    //   ...geo.location,
    // },
  })),
  ...other,
});

export const inspectorMapper = (i) => ({
  // TODO: for now static inspectors
  _id: 'inspetor-id-1',
  name: 'Inspector',
  representatives: [{ id: 'rep-1', person: { firstName: 'Inspector', lastName: 'Clouseau' } }],
});

export const productMapper = ({ certification, manufacturer, ...other }) => ({
  ...other,
  certificate: certificationMapper(certification()),
  manufacturer: manufacturer(),
});

export const aclRequestMapper = ({ author, certification, granter, ...other }) => ({
  ...other,
  author: author(),
  certificate: certificationMapper(certification()),
  granter,
});

export const mapper = ({ _id, coverSrc, logoSrc, person, units, certifications, ...other }) => ({
  id: _id,
  ...other,
  cover: {
    src: coverSrc,
  },
  logo: {
    src: logoSrc,
  },
  contacts: [{ ...personMapper(person()) }],
  units: units().map(unitMapper),
  certifications: certifications().map(certificationMapper),
  certificates: certifications().map(certificationMapper),
});
