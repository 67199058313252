"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.certifications = void 0;
const certifications = [{
  certificateId: '101',
  name: 'ALKERN VOR BETON NV, ROESELARE',
  phone: '051/232420',
  number: '101/110',
  date: '2016.11.01',
  labels: ['R', 'U']
}, {
  certificateId: '101',
  name: 'B.P.M.N. S.A., FLORIFFOUX',
  phone: '071/440225',
  number: '101/201',
  date: '2016.11.01',
  labels: ['R', 'U']
}, {
  certificateId: '101',
  name: 'BETON DE CLERCQ BVBA, BRUGGE',
  phone: '050/317361',
  number: '101/117',
  date: '2016.11.01',
  labels: ['R', 'U']
}, {
  certificateId: '101',
  name: 'BETONFABRIEK DE BONTE-VAN HECKE NV, WAASMUNSTER',
  phone: '052/473320',
  number: '101/278',
  date: '2017.02.08',
  labels: ['R']
}, {
  certificateId: '101',
  name: 'BLEIJKO BETONINDUSTRIE B.V., WALSOORDEN (HULST)',
  phone: '31/114684000',
  number: '101/104',
  date: '2016.11.01',
  labels: ['R', 'U']
}, {
  certificateId: '101',
  name: 'CHAUX DE CONTERN S.A., CONTERN',
  phone: '352/3588111',
  number: '101/152',
  date: '2016.11.01',
  labels: ['R', 'U']
}, {
  certificateId: '101',
  name: 'CIERS BETON BVBA, OOSTROZEBEKE',
  phone: '051/400889',
  number: '101/344',
  date: '2016.04.19',
  labels: ['R', 'U']
}, {
  certificateId: '101',
  name: 'DAUBY S.P.R.L., MARCHIENNE-AU-PONT',
  phone: '071/519991',
  number: '101/202',
  date: '2017.03.11',
  labels: ['R', 'U']
}, {
  certificateId: '101',
  name: 'FASSAERT BETON B.V., WESTDORPE',
  phone: '31/115456260',
  number: '101/193',
  date: '2017.12.09',
  labels: ['R', 'U']
}, {
  certificateId: '101',
  name: 'LITHOBETON NV, GISTEL-SNAASKERKE',
  phone: '059/276060',
  number: '101/116',
  date: '2016.11.01',
  labels: ['F', 'R', 'U']
}, {
  certificateId: '101',
  name: 'MARTENS BETON B.V., OOSTERHOUT',
  phone: '31/162422900',
  number: '101/443',
  date: '2016.11.01',
  labels: ['F', 'R', 'U']
}, {
  certificateId: '101',
  name: 'PROBEMAL S.A., MALMEDY',
  phone: '080/791220',
  number: '101/203',
  date: '2016.11.01',
  labels: ['R', 'U']
}, {
  certificateId: '101',
  name: 'SOCEA NV, OELEGEM-RANST',
  phone: '03/4750008',
  number: '101/120',
  date: '2016.11.01',
  labels: ['R', 'U']
}, {
  certificateId: '101',
  name: 'STRUYK VERWO AQUA B.V., NEDERWEERT',
  phone: '31/495534815',
  number: '101/445',
  date: '2016.04.13',
  labels: ['U']
}, {
  certificateId: '101',
  name: 'TUBOBEL AQUA NV, GENK',
  phone: '089/307979',
  number: '101/189',
  date: '2016.11.01',
  labels: ['R', 'U']
}, {
  certificateId: '101',
  name: 'TUBOBEL AQUA NV, NEEROETEREN',
  phone: '089/307979',
  number: '101/101',
  date: '2016.11.01',
  labels: ['R', 'U']
}, {
  certificateId: '101',
  name: 'TUBOBEL AQUA NV, RAVELS',
  phone: '089/307979',
  number: '101/111',
  date: '2016.07.03',
  labels: ['U']
}, {
  certificateId: '101',
  name: 'TUBOBEL NV, TESSENDERLO',
  phone: '013/670710',
  number: '101/107',
  date: '2016.11.01',
  labels: ['R', 'U']
}, {
  certificateId: '101',
  name: 'WEBECO NV, BRUSTEM',
  phone: '011/765719',
  number: '101/489',
  date: '2019.02.12',
  labels: ['U']
}, {
  certificateId: '101',
  name: 'WEBECO NV, SINT-TRUIDEN',
  phone: '011/765719',
  number: '101/460',
  date: '2017.02.12',
  labels: ['F', 'R', 'U']
}, {
  certificateId: '104',
  name: 'BOVIN BETON & NATUURSTEEN NV, KORTENAKEN',
  phone: '011/587151',
  date: '2018.06.09',
  labels: ['P'],
  number: '104/106'
}, {
  certificateId: '104',
  name: 'MARTENS BETON B.V., OOSTERHOUT',
  phone: '31/162422900',
  date: '2016.08.01',
  labels: ['P'],
  number: '104/143'
}, {
  certificateId: '104',
  name: 'TUBOBEL AQUA NV, GENK',
  phone: '089/307979',
  date: '2018.05.19',
  labels: ['P'],
  number: '104/189'
}, {
  certificateId: '105',
  name: 'BETON DE CLERCQ NV, BRUGGE',
  phone: '050/317361',
  number: '105/117',
  date: '2018.06.10'
}, {
  certificateId: '105',
  name: 'DE CLERCQ ED. (OUDE FIRMA) NV, GENT',
  phone: '09/2538296',
  number: '105/121',
  date: '2019.02.10'
}, {
  certificateId: '105',
  name: 'LITHOBETON NV, GISTEL-SNAASKERKE',
  phone: '059/276060',
  number: '105/116',
  date: '2018.10.14'
}, {
  certificateId: '105',
  name: 'STRUYK VERWO AQUA B.V., NEDERWEERT',
  phone: '31/495534815',
  number: '105/445',
  date: '2016.07.03'
}, {
  certificateId: '106',
  name: 'ALKERN VOR BETON NV, ROESELARE',
  phone: '051/232420',
  number: '106/110',
  date: '2016.11.01',
  labels: ['F', 'R', 'U']
}, {
  certificateId: '106',
  name: 'BETON DE CLERCQ BVBA, BRUGGE',
  phone: '050/317361',
  number: '106/117',
  date: '2016.11.01',
  labels: ['F', 'R', 'U']
}, {
  certificateId: '106',
  name: 'BLEIJKO BETONINDUSTRIE B.V., WALSOORDEN (HULST)',
  phone: '31/114684000',
  number: '106/104',
  date: '2016.11.01',
  labels: ['F', 'U']
}, {
  certificateId: '106',
  name: 'BOVIN BETON & NATUURSTEEN NV, KORTENAKEN',
  phone: '011/587151',
  number: '106/106',
  date: '2018.01.16',
  labels: ['F', 'U']
}, {
  certificateId: '106',
  name: 'CHAUX DE CONTERN S.A., CONTERN',
  phone: '352/3588111',
  number: '106/152',
  date: '2018.06.01',
  labels: ['R', 'U']
}, {
  certificateId: '106',
  name: 'DAUBY S.P.R.L., MARCHIENNE-AU-PONT',
  phone: '071/519991',
  number: '106/202',
  date: '2017.04.01',
  labels: ['F', 'U']
}, {
  certificateId: '106',
  name: 'DE CLERCQ ED. (OUDE FIRMA) NV, GENT',
  phone: '09/2538296',
  number: '106/121',
  date: '2016.11.01',
  labels: ['F', 'U']
}, {
  certificateId: '106',
  name: 'LITHOBETON NV, GISTEL-SNAASKERKE',
  phone: '059/276060',
  number: '106/116',
  date: '2016.11.01',
  labels: ['F', 'R', 'U']
}, {
  certificateId: '106',
  name: 'MARTENS BETON B.V., OOSTERHOUT',
  phone: '31/162422900',
  number: '106/143',
  date: '2016.11.01',
  labels: ['F', 'U']
}, {
  certificateId: '106',
  name: 'PROBEMAL S.A., MALMEDY',
  phone: '080/791220',
  number: '106/203',
  date: '2016.11.01',
  labels: ['R', 'U']
}, {
  certificateId: '106',
  name: 'SOCEA NV, OELEGEM-RANST',
  phone: '03/4750008',
  number: '106/120',
  date: '2016.11.01',
  labels: ['J', 'R']
}, {
  certificateId: '106',
  name: 'TUBOBEL AQUA NV, GENK',
  phone: '089/307979',
  number: '106/189',
  date: '2018.06.01',
  labels: ['F', 'U']
}, {
  certificateId: '106',
  name: 'TUBOBEL AQUA NV, NEEROETEREN',
  phone: '089/307979',
  number: '106/101',
  date: '2016.11.01',
  labels: ['R', 'U']
}, {
  certificateId: '106',
  name: 'TUBOBEL AQUA NV, RAVELS',
  phone: '089/307979',
  number: '106/111',
  date: '2016.11.01',
  labels: ['F', 'U']
}, {
  certificateId: '106',
  name: 'TUBOBEL NV, TESSENDERLO',
  phone: '013/670710',
  number: '106/107',
  date: '2016.11.01',
  labels: ['F', 'J', 'R', 'U']
}, {
  certificateId: '211',
  name: 'BLEIJKO BETONINDUSTRIE B.V., WALSOORDEN (HULST)',
  phone: '31/114684000',
  number: '211/104',
  date: '2017.03.01'
}, {
  certificateId: '211',
  name: 'BOVIN BETON & NATUURSTEEN NV, KORTENAKEN',
  phone: '011/587151',
  number: '211/106',
  date: '2018.01.16'
}, {
  certificateId: '211',
  name: 'BV M.K.S. BVBA, DESTELDONK',
  phone: '09/3451100',
  number: '211/425',
  date: '2017.10.07'
}, {
  certificateId: '211',
  name: 'COVARI NV p/a BETONFABRIEK COECK NV, NIEL',
  phone: '03/8807500',
  number: '211/161',
  date: '2016.07.01'
}, {
  certificateId: '211',
  name: 'EBEMA BETON RIJKEVORSEL NV (E.B.R.), RIJKEVORSEL',
  phone: '03/3120847',
  number: '211/108',
  date: '2017.09.09'
}, {
  certificateId: '211',
  name: 'EBEMA NV, ZUTENDAAL',
  phone: '089/610011',
  number: '211/122',
  date: '2017.03.01'
}, {
  certificateId: '211',
  name: 'K-BETON NV, HEUSDEN-ZOLDER',
  phone: '013/550523',
  number: '211/167',
  date: '2017.03.01'
}, {
  certificateId: '211',
  name: 'VAN DE VELDE BETON NV, MAZENZELE',
  phone: '052/356969',
  number: '211/416',
  date: '2017.03.01'
}, {
  certificateId: '311',
  name: 'ALKERN VOR BETON NV, ROESELARE',
  phone: '051/232420',
  number: '311/110',
  date: '2017.03.01'
}, {
  certificateId: '311',
  name: 'BLEIJKO BETONINDUSTRIE B.V., WALSOORDEN (HULST)',
  phone: '31/114684000',
  number: '311/104',
  date: '2017.03.01'
}, {
  certificateId: '311',
  name: 'BLEIJKO ROESELARE NV, ROESELARE',
  phone: '051/228321',
  number: '311/114',
  date: '2017.03.01'
}, {
  certificateId: '311',
  name: 'BOVIN BETON & NATUURSTEEN NV, KORTENAKEN',
  phone: '011/587151',
  number: '311/106',
  date: '2018.01.16'
}, {
  certificateId: '311',
  name: 'BV M.K.S. BVBA, DESTELDONK',
  phone: '09/3451100',
  number: '311/425',
  date: '2017.03.01'
}, {
  certificateId: '311',
  name: 'COVARI NV p/a BETONFABRIEK COECK NV, NIEL',
  phone: '03/8807500',
  number: '311/161',
  date: '2017.03.01'
}, {
  certificateId: '311',
  name: 'EBEMA BETON RIJKEVORSEL NV (E.B.R.), RIJKEVORSEL',
  phone: '03/3120847',
  number: '311/108',
  date: '2017.02.01'
}, {
  certificateId: '311',
  name: 'EBEMA NV, ZUTENDAAL',
  phone: '089/610011',
  number: '311/122',
  date: '2017.03.01'
}, {
  certificateId: '311',
  name: 'EUROBLOC S.A., LIBRAMONT-CHEVIGNY',
  phone: '061/222931',
  number: '311/401',
  date: '2017.03.21'
}, {
  certificateId: '311',
  name: 'HENLOTON NV, ZEVENEKEN',
  phone: '09/3555995',
  number: '311/436',
  date: '2018.09.25'
}, {
  certificateId: '311',
  name: 'K-BETON NV, HEUSDEN-ZOLDER',
  phone: '013/550523',
  number: '311/167',
  date: '2017.03.01'
}, {
  certificateId: '311',
  name: 'LITHOBETON NV, GISTEL-SNAASKERKE',
  phone: '059/276060',
  number: '311/116',
  date: '2017.03.01'
}, {
  certificateId: '311',
  name: 'MAKO BETON BVBA, GROBBENDONK',
  phone: '014/516892',
  number: '311/297',
  date: '2016.12.19'
}, {
  certificateId: '311',
  name: 'MARTENS BETON NV, MOL',
  phone: '014/811251',
  number: '311/112',
  date: '2017.03.01'
}, {
  certificateId: '311',
  name: 'MBI BV, VEGHEL',
  phone: '31/413349400',
  number: '311/105',
  date: '2017.04.07'
}, {
  certificateId: '311',
  name: 'REMALUX S.A., JEMEPPE SUR SAMBRE',
  phone: '071/750270',
  number: '311/434',
  date: '2017.03.07'
}, {
  certificateId: '311',
  name: 'ROOSENS BETONS S.A., FAMILLEUREUX',
  phone: '064/556307',
  number: '311/266',
  date: '2017.03.01'
}, {
  certificateId: '311',
  name: 'VAN DE VELDE BETON NV, MAZENZELE',
  phone: '052/356969',
  number: '311/416',
  date: '2016.07.06'
}, {
  certificateId: '311',
  name: 'WINDMOLDERS BETON NV, HERK-DE-STAD',
  phone: '013/551651',
  number: '311/449',
  date: '2016.05.08'
}, {
  certificateId: '490',
  name: 'RBB NV, TESSENDERLO',
  phone: '013/670940',
  number: '490/390',
  date: '2017.05.08'
}, {
  name: 'ALKERN VOR BETON NV, ROESELARE',
  certificateId: '001',
  phone: '051/232420',
  number: '001/110',
  date: '2017.12.23'
}, {
  name: 'BETON DE LA LOMME S.A., ROCHEFORT',
  certificateId: '001',
  phone: '084/213440',
  number: '001/162',
  date: '2018.11.20'
}, {
  name: 'BETONAGGLOMERATEN GUBBELS NV, MAASMECHELEN',
  certificateId: '001',
  phone: '089/657885',
  number: '001/312',
  date: '2018.06.21'
}, {
  name: 'CHANTIER BERTRAND SPRL, HERMALLE-sous-HUY',
  certificateId: '001',
  phone: '085/311875',
  number: '001/280',
  date: '2017.06.30'
}, {
  name: 'CLAESEN BETONBEDRIJF NV, LUMMEN',
  certificateId: '001',
  phone: '011/454970',
  number: '001/315',
  date: '2018.04.26'
}, {
  name: 'COLLA NV, MUNSTERBILZEN',
  certificateId: '001',
  phone: '089/415280',
  number: '001/321',
  date: '2018.02.12'
}, {
  name: 'CONFORBETON S.A., MONTIGNIES-SUR-SAMBRE',
  certificateId: '001',
  phone: '071/202202',
  number: '001/417',
  date: '2018.04.01'
}, {
  name: 'COVARI NV p/a BETONFABRIEK COECK NV, NIEL',
  certificateId: '001',
  phone: '03/8807500',
  number: '001/161',
  date: '2018.04.01'
}, {
  name: 'DAUBY S.P.R.L., MARCHIENNE-AU-PONT',
  certificateId: '001',
  phone: '071/519991',
  number: '001/202',
  date: '2018.06.01'
}, {
  name: 'DOU-BETON NV, SPIERE',
  certificateId: '001',
  phone: '056/230740',
  number: '001/335',
  date: '2017.08.28'
}, {
  name: 'DOUTERLOIGNE S.A., VAULX-LEZ-TOURNAI',
  certificateId: '001',
  phone: '069/843941',
  number: '001/174',
  date: '2018.04.01'
}, {
  name: 'EDELBETON NV, GEEL',
  certificateId: '001',
  phone: '014/588455',
  number: '001/235',
  date: '2018.04.01'
}, {
  name: 'EUROBLOC S.A., LIBRAMONT-CHEVIGNY',
  certificateId: '001',
  phone: '061/222931',
  number: '001/401',
  date: '2018.04.01'
}, {
  name: 'HENDRICKX BOUWCENTER NV, BEKKEVOORT',
  certificateId: '001',
  phone: '013/311558',
  number: '001/277',
  date: '2017.02.27'
}, {
  name: 'HENLOTON NV, ZEVENEKEN',
  certificateId: '001',
  phone: '09/3555995',
  number: '001/436',
  date: '2018.10.29'
}, {
  name: 'J. JANSSENS EN ZONEN NV, BRECHT',
  certificateId: '001',
  phone: '03/3130351',
  number: '001/163',
  date: '2018.04.01'
}, {
  name: 'MAKO BETON BVBA, GROBBENDONK',
  certificateId: '001',
  phone: '014/516892',
  number: '001/297',
  date: '2018.10.13'
}, {
  name: 'PAESEN BETONFABRIEK NV, HOUTHALEN-HELCHTEREN',
  certificateId: '001',
  phone: '011/523654',
  number: '001/295',
  date: '2017.11.27'
}, {
  name: 'PREFER S.A., FLEMALLE',
  certificateId: '001',
  phone: '04/2737200',
  number: '001/237',
  date: '2018.04.01'
}, {
  name: 'REWA BETON AG/SA, SAINT-VITH',
  certificateId: '001',
  phone: '080/280818',
  number: '001/227',
  date: '2018.04.01'
}, {
  name: 'ROOSENS BETONS S.A., FAMILLEUREUX',
  certificateId: '001',
  phone: '064/556307',
  number: '001/266',
  date: '2018.04.01'
}, {
  certificateId: '002',
  name: 'CIRKEL GmbH & CoKG, Haltern am See',
  phone: '49/236493810',
  number: '002/314',
  date: '2018.10.22'
}, {
  certificateId: '002',
  name: 'DHAHAN SAS, SAINT-SAULVE',
  phone: '33/327515805',
  number: '002/379',
  date: '2018.03.24'
}, {
  certificateId: '002',
  name: 'H+H DEUTSCHLAND GmbH, HAMM-UENTROP',
  phone: '49/238830700',
  number: '002/615',
  date: '2018.06.05'
}, {
  certificateId: '002',
  name: 'XELLA BE NV, BURCHT',
  phone: '03/2504700',
  number: '002/253',
  date: '2018.04.01'
}, {
  certificateId: '003',
  name: 'CALDURAN KALKZANDSTEEN BV, HARDERWIJK',
  phone: '31/341464000',
  number: '003/317',
  date: '2018.06.29'
}, {
  certificateId: '003',
  name: 'XELLA BE NV, BURCHT',
  phone: '03/2504700',
  number: '003/253',
  date: '2016.05.31'
}, {
  certificateId: '003',
  name: 'XELLA KALKZANDSTEENFABRIEK de HAZELAAR B.V., ECHT-SUSTEREN',
  phone: '31/183671234',
  number: '003/264',
  date: '2018.04.01'
}, {
  certificateId: '102',
  name: 'BETONFABRIEK DE BONTE NV, LAAKDAL',
  phone: '013/531900',
  number: '102/381',
  date: '2017.09.09'
}, {
  certificateId: '102',
  name: 'BETONFABRIEK DE BONTE-VAN HECKE NV, WAASMUNSTER',
  phone: '052/473320',
  number: '102/278',
  date: '2016.05.13'
}, {
  certificateId: '102',
  name: 'TUBOBEL AQUA NV, GENK',
  phone: '089/307979',
  number: '102/189',
  date: '2016.07.12'
}, {
  certificateId: '102',
  name: 'WEBECO NV, BRUSTEM',
  phone: '011/765719',
  number: '102/489',
  date: '2019.02.12'
}, {
  certificateId: '102',
  name: 'WEBECO NV, SINT-TRUIDEN',
  phone: '011/765719',
  number: '102/460',
  date: '2016.05.13'
}, {
  certificateId: '132',
  name: 'CBS BETON NV, SINT-BAAFS-VIJVE',
  phone: '056/617537',
  number: '132/437',
  date: '2018.10.01',
  labels: ['GL_GT']
}, {
  certificateId: '132',
  name: 'PREFACO NV - Site LOMMEL, LOMMEL',
  phone: '053/731002',
  number: '600/378',
  date: '2016.06.27',
  labels: ['G']
}, {
  certificateId: '132',
  name: 'PREFAXIS BVBA, GELUVELD',
  phone: '057/468351',
  number: '600/287',
  date: '2017.01.02',
  labels: ['G']
}, {
  certificateId: '132',
  name: 'VERHELST BOUWMATERIALEN NV, OUDENBURG',
  phone: '059/255050',
  number: '600/219',
  date: '2019.01.01',
  labels: ['G']
}, {
  certificateId: '132',
  name: 'VERHEYEN BETONPRODUCTEN NV, ARENDONK',
  phone: '014/689150',
  number: '600/307',
  date: '2016.07.01',
  labels: ['G', 'V']
}, {
  certificateId: '601',
  name: 'VERHEYEN BETONPRODUCTEN NV, ARENDONK',
  phone: '014/689150',
  number: '601/307',
  date: '2016.06.17'
}, {
  certificateId: '603',
  name: 'ERGON NV, LIER',
  phone: '03/4900411',
  number: '603/215',
  date: '2019.01.01',
  labels: ['V']
}, {
  certificateId: '603',
  name: 'PREFACO NV - Site LOMMEL, LOMMEL',
  phone: '053/731002',
  number: '603/378',
  date: '2016.12.19',
  labels: ['V']
}, {
  certificateId: '604',
  name: 'CONCRETON NV, GERAARDSBERGEN',
  phone: '054/415577',
  number: '604/388',
  date: '2017.04.01',
  labels: ['G']
}, {
  certificateId: '604',
  name: 'CORDEEL ZETEL TEMSE NV, TEMSE',
  phone: '03/7105551',
  number: '604/328',
  date: '2017.10.10',
  labels: ['G']
}, {
  certificateId: '604',
  name: 'ERGON NV, LIER',
  phone: '03/4900411',
  number: '604/215',
  date: '2019.01.01',
  labels: ['G', 'V']
}, {
  certificateId: '604',
  name: 'MEGATON NV, NINOVE',
  phone: '054/334511',
  number: '604/224',
  date: '2019.01.01',
  labels: ['V']
}, {
  certificateId: '604',
  name: 'PREFACO NV - Site LOMMEL, LOMMEL',
  phone: '053/731002',
  number: '604/378',
  date: '2019.01.01',
  labels: ['G']
}, {
  certificateId: '604',
  name: 'PREFACO NV, LEBBEKE',
  phone: '053/767373',
  number: '604/243',
  date: '2019.01.01',
  labels: ['G']
}, {
  certificateId: '604',
  name: 'PREFAXIS BVBA, GELUVELD',
  phone: '057/468351',
  number: '604/287',
  date: '2017.01.02',
  labels: ['G']
}, {
  certificateId: '604',
  name: 'PRETON BVBA, HEUSDEN-ZOLDER',
  phone: '011/525585',
  number: '604/380',
  date: '2016.10.08',
  labels: ['G']
}, {
  certificateId: '604',
  name: 'VERHEYEN BETONPRODUCTEN NV, ARENDONK',
  phone: '014/689150',
  number: '604/307',
  date: '2016.11.23',
  labels: ['G', 'V']
}, {
  certificateId: '605',
  name: 'BETONWERKEN VETS EN ZONEN BVBA, MOL',
  phone: '014/813208',
  number: '605/210',
  date: '2016.05.01',
  labels: ['G']
}, {
  certificateId: '605',
  name: 'BUDEX BVBA, LEMBEEK',
  phone: '02/3610186',
  number: '605/270',
  date: '2016.04.26',
  labels: ['G']
}, {
  certificateId: '605',
  name: 'DAERDEN & CO BVBA, RIEMST',
  phone: '012/250070',
  number: '605/269',
  date: '2016.03.29',
  labels: ['G']
}, {
  certificateId: '605',
  name: 'DOU-BETON FLOORING SYSTEMS NV, LEUZE-EN-HAINAUT',
  phone: '069/889210',
  number: '605/604',
  date: '2016.03.29',
  labels: ['G']
}, {
  certificateId: '605',
  name: 'DOUTERLOIGNE ANZEGEM, ANZEGEM',
  phone: '056/694040',
  number: '605/175',
  date: '2016.04.19',
  labels: ['G']
}, {
  certificateId: '605',
  name: 'DOUTERLOIGNE BEERSE, BEERSE',
  phone: '014/611918',
  number: '605/211',
  date: '2016.04.26',
  labels: ['G']
}, {
  certificateId: '605',
  name: 'DOUTERLOIGNE S.A., VAULX-LEZ-TOURNAI',
  phone: '069/843941',
  number: '605/174',
  date: '2016.05.01',
  labels: ['G']
}, {
  certificateId: '605',
  name: 'ERGON NV, LIER',
  phone: '03/4900411',
  number: '605/215',
  date: '2016.02.15',
  labels: ['V']
}, {
  certificateId: '605',
  name: 'FB GROUPE Luxembourg, ESCH-SUR-ALZETTE',
  phone: '352/5559691',
  number: '605/213',
  date: '2016.04.26',
  labels: ['V']
}, {
  certificateId: '605',
  name: 'FINGO NV, MALLE',
  phone: '03/3092626',
  number: '605/382',
  date: '2016.03.29',
  labels: ['V']
}, {
  certificateId: '605',
  name: 'FINGO NV, MALLE',
  phone: '03/3092626',
  number: '605/223',
  date: '2016.03.29',
  labels: ['G']
}, {
  certificateId: '605',
  name: 'FINGO-FREDERICKX BVBA, MESSELBROEK',
  phone: '013/780078',
  number: '605/214',
  date: '2016.05.01',
  labels: ['G']
}, {
  certificateId: '605',
  name: 'G&J INDUSTRIES NV, TONGEREN',
  phone: '012/391380',
  number: '605/369',
  date: '2016.12.09',
  labels: ['V']
}, {
  certificateId: '605',
  name: 'HEDRA BETON NV, HERDEREN',
  phone: '012/452162',
  number: '605/207',
  date: '2016.04.26',
  labels: ['G']
}, {
  certificateId: '605',
  name: 'INTER-SHIPPING NV, BORNEM',
  phone: '03/8255507',
  number: '605/326',
  date: '2016.05.01',
  labels: ['V']
}, {
  certificateId: '605',
  name: 'MEGATON NV, NINOVE',
  phone: '054/334511',
  number: '605/224',
  date: '2016.04.26',
  labels: ['V']
}, {
  certificateId: '605',
  name: 'NERVA NV, HARELBEKE',
  phone: '056/735010',
  number: '605/268',
  date: '2016.04.19',
  labels: ['V']
}, {
  certificateId: '605',
  name: 'PAULI NV, HOESELT',
  phone: '089/411300',
  number: '605/296',
  date: '2016.10.08',
  labels: ['V']
}, {
  certificateId: '605',
  name: 'PAULI NV, HOESELT',
  phone: '089/411300',
  number: '605/225',
  date: '2016.04.26',
  labels: ['G']
}, {
  certificateId: '605',
  name: 'PREFACO NV - Site Houthalen, HOUTHALEN',
  phone: '089/840311',
  number: '605/212',
  date: '2016.05.01',
  labels: ['V']
}, {
  certificateId: '605',
  name: 'PREFACO NV - Site Zutendaal, ZUTENDAAL',
  phone: '089/840311',
  number: '605/385',
  date: '2016.05.01',
  labels: ['V']
}, {
  certificateId: '605',
  name: 'PREFER SA, FLEMALLE',
  phone: '04/2348340',
  number: '605/613',
  date: '2018.03.26',
  labels: ['G']
}, {
  certificateId: '605',
  name: 'RONVEAUX (Ets E.) S.A., CRISNEE',
  phone: '083/212901',
  number: '605/222',
  date: '2016.04.26',
  labels: ['V']
}, {
  certificateId: '605',
  name: 'ROOSENS BETONS S.A., FAMILLEUREUX',
  phone: '064/556307',
  number: '605/266',
  date: '2016.05.01',
  labels: ['G']
}, {
  certificateId: '605',
  name: 'SPANBETON HOLLEVOET CHARLES NV, TORHOUT',
  phone: '050/212692',
  number: '605/284',
  date: '2016.03.29',
  labels: ['V']
}, {
  certificateId: '605',
  name: 'STEENBAKKERIJEN VAN PLOEGSTEERT NV, PLOEGSTEERT',
  phone: '056/565656',
  number: '605/288',
  date: '2018.02.16',
  labels: ['V']
}, {
  certificateId: '605',
  name: 'STRUCTO NV, BRUGGE',
  phone: '050/444342',
  number: '605/216',
  date: '2017.03.01',
  labels: ['V']
}, {
  certificateId: '605',
  name: 'VAHEJA NV, NEERPELT',
  phone: '011/640041',
  number: '605/218',
  date: '2016.04.08',
  labels: ['G']
}, {
  certificateId: '605',
  name: 'VAN THUYNE - IDE BVBA, WIELSBEKE',
  phone: '056/608020',
  number: '605/636',
  date: '2018.09.26',
  labels: ['V']
}, {
  certificateId: '606',
  name: 'ABI (Andernacher Bimswerk) GmbH & Co.KG, BEDBURG',
  phone: '49/227240940',
  number: '606/320',
  date: '2016.05.01',
  labels: ['G']
}, {
  certificateId: '606',
  name: 'ALPHA BETON GmbH, SAINT-VITH',
  phone: '080/281212',
  number: '606/336',
  date: '2016.04.26',
  labels: ['G']
}, {
  certificateId: '606',
  name: 'DE DONCKER BVBA, ROOSDAAL',
  phone: '054/335563',
  number: '606/283',
  date: '2016.02.25',
  labels: ['G']
}, {
  certificateId: '606',
  name: 'DE HOOP PEKSO B.V., OOSTERHOUT',
  phone: '31/115680911',
  number: '606/311',
  date: '2016.04.16',
  labels: ['G']
}, {
  certificateId: '606',
  name: 'DE SMEDT BETON NV, NIEUWENRODE',
  phone: '015/711839',
  number: '606/234',
  date: '2016.04.25',
  labels: ['G']
}, {
  certificateId: '606',
  name: 'ISODAL BVBA, WICHELEN',
  phone: '052/423443',
  number: '606/329',
  date: '2017.12.01',
  labels: ['G']
}, {
  certificateId: '606',
  name: 'KERKSTOEL 2000+ NV, GROBBENDONK',
  phone: '014/507761',
  number: '606/245',
  date: '2016.04.26',
  labels: ['G']
}, {
  certificateId: '606',
  name: 'MARMORITH Betonindustrie NV, HOUTHALEN-HELCHTEREN',
  phone: '053/767373',
  number: '606/246',
  date: '2016.05.01',
  labels: ['G']
}, {
  certificateId: '606',
  name: 'OETERBETON NV, MAASEIK-NEEROETEREN',
  phone: '089/860100',
  number: '606/242',
  date: '2016.05.01',
  labels: ['G', 'V']
}, {
  certificateId: '606',
  name: 'PREDALCO BVBA, LOKEREN',
  phone: '09/3269250',
  number: '606/603',
  date: '2016.05.01',
  labels: ['G']
}, {
  certificateId: '606',
  name: 'PREFACO NV, LEBBEKE',
  phone: '053/767373',
  number: '606/243',
  date: '2016.05.01',
  labels: ['G']
}, {
  certificateId: '606',
  name: 'PREFAXIS BVBA, GELUVELD',
  phone: '057/468351',
  number: '606/287',
  date: '2017.01.02',
  labels: ['G']
}, {
  certificateId: '606',
  name: 'VAN THUYNE - IDE BVBA, WIELSBEKE',
  phone: '056/608020',
  number: '606/636',
  date: '2019.02.05',
  labels: ['G']
}, {
  certificateId: '606',
  name: 'VERHELST BOUWMATERIALEN NV, OUDENBURG',
  phone: '059/255050',
  number: '606/219',
  date: '2016.03.15',
  labels: ['G']
}, {
  certificateId: '611',
  name: 'CORDEEL ZETEL TEMSE NV, TEMSE',
  phone: '03/7105551',
  number: '611/328',
  date: '2017.10.10',
  labels: ['G']
}, {
  certificateId: '611',
  name: 'PREFACO NV - Site LOMMEL, LOMMEL',
  phone: '053/731002',
  number: '611/378',
  date: '2017.10.14',
  labels: ['G']
}, {
  certificateId: '612',
  name: 'ABI (Andernacher Bimswerk) GmbH & Co.KG, BEDBURG',
  phone: '49/227240940',
  number: '612/320',
  date: '2016.05.01',
  labels: ['D', 'G']
}, {
  certificateId: '612',
  name: 'ALPHA BETON GmbH, SAINT-VITH',
  phone: '080/281212',
  number: '612/336',
  date: '2016.04.26',
  labels: ['D', 'G']
}, {
  certificateId: '612',
  name: 'KERKSTOEL 2000+ NV, GROBBENDONK',
  phone: '014/507761',
  number: '612/245',
  date: '2016.04.26',
  labels: ['D', 'G']
}, {
  certificateId: '612',
  name: 'OETERBETON NV, MAASEIK-NEEROETEREN',
  phone: '089/860100',
  number: '612/242',
  date: '2016.05.01',
  labels: ['D', 'G']
}, {
  certificateId: '612',
  name: 'PREFACO NV - Site LOMMEL, LOMMEL',
  phone: '053/731002',
  number: '612/378',
  date: '2016.04.26',
  labels: ['A', 'G']
}, {
  certificateId: '612',
  name: 'PREFACO NV, LEBBEKE',
  phone: '053/767373',
  number: '612/243',
  date: '2016.05.01',
  labels: ['A', 'D', 'G']
}, {
  certificateId: '612',
  name: 'PREFAXIS BVBA, GELUVELD',
  phone: '057/468351',
  number: '612/287',
  date: '2017.01.02',
  labels: ['A', 'B', 'D', 'G']
}, {
  certificateId: '612',
  name: 'PRETON BVBA, HEUSDEN-ZOLDER',
  phone: '011/525585',
  number: '612/380',
  date: '2016.10.08',
  labels: ['A', 'B', 'G']
}, {
  certificateId: '612',
  name: 'VERHELST BOUWMATERIALEN NV, OUDENBURG',
  phone: '059/255050',
  number: '612/219',
  date: '2017.01.17',
  labels: ['D', 'G']
}, {
  certificateId: '612',
  name: 'VERHEYEN BETONPRODUCTEN NV, ARENDONK',
  phone: '014/689150',
  number: '612/307',
  date: '2016.04.26',
  labels: ['A', 'B', 'E', 'G']
}, {
  certificateId: '616',
  name: 'G&J INDUSTRIES NV, TONGEREN',
  phone: '012/391380',
  number: '616/369',
  date: '2016.03.15',
  labels: ['V']
}, {
  certificateId: '620',
  name: 'CBS BETON NV, SINT-BAAFS-VIJVE',
  phone: '056/617537',
  number: '620/437',
  date: '2018.05.07',
  labels: ['HS']
}, {
  certificateId: '620',
  name: 'COBEFA BVBA, KOMEN',
  phone: '056/554853',
  number: '620/272',
  date: '2019.01.04',
  labels: ['A', 'N']
}, {
  certificateId: '620',
  name: 'GROBETON NV, GROBBENDONK',
  phone: '014/512129',
  number: '620/304',
  date: '2018.12.07',
  labels: ['A', 'R']
}, {
  certificateId: '620',
  name: 'VAN DER VELDEN BETON NV, MEER',
  phone: '03/3157272',
  number: '620/254',
  date: '2018.12.07',
  labels: ['A', 'N']
}, {
  certificateId: '620',
  name: 'VAN HESSCHE BVBA, PITTEM',
  phone: '051/466758',
  number: '620/275',
  date: '2018.11.20',
  labels: ['A', 'N']
}, {
  certificateId: '004',
  name: 'XELLA BE NV, BURCHT',
  phone: '03/2504700',
  number: '004/253',
  date: '2019.01.16'
}];
exports.certifications = certifications;