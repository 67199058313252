import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import CertificateIcon from '@material-ui/icons/AssignmentTurnedIn';
import EditIcon from '@material-ui/icons/Edit';

import { List, Avatar } from '@material-ui/core';
import { EmptyState, CardList } from '@solar/solar-react';

import { ContactCard, ProfileItem } from '@probeton/ui';

const TicketCardList = ({ tickets, openTicket, emptyLabel = 'Geen aanvragen.' }) => {
  if (!tickets || tickets.length === 0) {
    return <EmptyState Icon={EditIcon} label={emptyLabel} />;
  }
  return (
    <CardList items={R.sortBy(R.prop('createdAt'))(tickets)}>
      {({ _id, author, actor, statusLabel, status, manufacturer, certification }) => {
        const c = certification().certificate();
        const a = author();
        return (
          <ContactCard
            key={_id}
            avatarUrl={a.avatarUrl}
            fullName={a.fullName}
            email={a.email}
            subheader={manufacturer}
            onClick={() => openTicket(_id)}
          >
            <List>
              <ProfileItem
                icon={<CertificateIcon />}
                text={c.renderTitle()}
                textLabel={c.renderCodes()}
              />
              <ProfileItem
                icon={<Avatar src={actor().avatarUrl} />}
                text={actor().fullName}
                textLabel={statusLabel()}
              />
            </List>
          </ContactCard>
        );
      }}
    </CardList>
  );
};

TicketCardList.propTypes = {
  className: PropTypes.string,
  tickets: PropTypes.array,
};

TicketCardList.defaultProps = {
  className: '',
  tickets: [],
};

export default TicketCardList;
