import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { format } from 'date-fns';
import DownloadIcon from '@material-ui/icons/GetApp';

import { List } from '@material-ui/core';

import { FileListItem, EmptyState, CardList } from '@solar/solar-react';

import { ContactCard } from '@probeton/ui';

const CertificationDownloadList = ({ downloads = [], setPdfOpen, setImageOpen, openCustomer }) => {
  if (!downloads || downloads.length === 0) {
    return (
      <EmptyState
        Icon={DownloadIcon}
        label="Er zijn nog geen gebruikers die een bestand hebben gedownload."
      />
    );
  }
  return (
    <CardList items={R.sortBy(R.prop('lastDownloadedAt'))(downloads)}>
      {({ _id, document, author, lastDownloadedAt }) => {
        return (
          <ContactCard
            key={_id}
            avatarUrl={author().avatarUrl}
            fullName={author().fullName}
            email={author().email}
            subheader={author().company}
            onClick={() => openCustomer(author()._id)}
          >
            <List>
              <FileListItem
                interactive
                onClickOpen={() => {
                  document.indexOf('.pdf') > 0 ? setPdfOpen(document) : setImageOpen(document);
                }}
                onClickDownloadFile={() => window.open(document, '__blank')}
                label={`${format(new Date(lastDownloadedAt), 'yyyy.MM.dd')}`}
                fileUrl={document}
              />
            </List>
          </ContactCard>
        );
      }}
    </CardList>
  );
};

CertificationDownloadList.propTypes = {
  className: PropTypes.string,
  downloads: PropTypes.array,
};

CertificationDownloadList.defaultProps = {
  className: '',
  downloads: [],
};

export default CertificationDownloadList;
