"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _exportNames = {
  scope: true,
  startup: true,
  dialogs: true,
  sheets: true
};
Object.defineProperty(exports, "startup", {
  enumerable: true,
  get: function get() {
    return _startup["default"];
  }
});
Object.defineProperty(exports, "dialogs", {
  enumerable: true,
  get: function get() {
    return _dialogs["default"];
  }
});
Object.defineProperty(exports, "sheets", {
  enumerable: true,
  get: function get() {
    return _sheets["default"];
  }
});
exports.scope = void 0;
var _startup = _interopRequireDefault(require("./startup"));
var _dialogs = _interopRequireDefault(require("./dialogs"));
var _sheets = _interopRequireDefault(require("./sheets"));
var _services = require("./services");
Object.keys(_services).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _services[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _services[key];
    }
  });
});
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
// export { default as ActionBar } from './accounts';

var scope = 'probeton';
exports.scope = scope;