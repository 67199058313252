import React from 'react';
import PropTypes from 'prop-types';

import { ScrollableContent } from '@solar/solar-react';

import SubscribeData from '../../../SubscribeData';
import FabView from '../FabView';

import ACLRequestCardList from './ACLRequestCardList';

const ACLRequestViewRep = ({ user, openACLRequest, ...other }) => {
  return (
    <SubscribeData>
      {({ aclRequests, helpers: { all } }) => {
        // TODO: filter on unit(s)
        // const repsACLRequests = all(aclRequests).filter(({ userId }) => userId === user._id);
        return (
          <FabView title={'Aanvragen'} titleOnTop disableShadow>
            <ScrollableContent>
              <ACLRequestCardList
                aclRequests={all(aclRequests)}
                openACLRequest={openACLRequest}
                emptyLabel="Er hebben nog geen klanten extra informatie aangevraagd over 1 van uw certificaten."
                {...other}
              />
            </ScrollableContent>
          </FabView>
        );
      }}
    </SubscribeData>
  );
};

ACLRequestViewRep.propTypes = {
  openACLRequest: PropTypes.func,
};

export default ACLRequestViewRep;
