import React from 'react';

import { UnitSheet as CommonUnitSheet } from '@probeton/ui';

import SubscribeData from '../../../SubscribeData';

import { unitMapper } from '../Manufacturer/helpers';

const UnitSheet = ({ id, history, handleDetailViewToggle }) => {
  return (
    <SubscribeData>
      {({ units, helpers: { get } }) => {
        return (
          <CommonUnitSheet
            unit={unitMapper(get(units, id))}
            history={history}
            handleContactClick={() => (contactId) => handleDetailViewToggle('contact', contactId)}
          />
        );
      }}
    </SubscribeData>
  );
};

export default UnitSheet;
