import lightTheme from "./lightTheme";
import darkTheme from "./darkTheme";

export const themes = {
  light: lightTheme,
  dark: darkTheme
};

export { default as light } from "./lightTheme";
export { default as dark } from "./darkTheme";
