import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import { format } from 'date-fns';

import {
  List,
  Avatar,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  Button,
  Card,
  CardHeader,
  CardContent,
  CardActions,
} from '@material-ui/core';

import { Header } from '@solar/solar-react';

import { ProfileItem, CertificateCard, ContactCard } from '@probeton/ui';

const useStyles = makeStyles((theme) => {
  return {
    root: {},
    avatar: {
      backgroundColor: theme.palette.primary.main,
    },
    media: {
      paddingTop: '50%',
    },
    field: {
      margin: 12,
      marginLeft: 24,
      marginRight: 24,
    },
    card: {
      margin: 12,
    },
  };
});

const ACLRequestInfo = ({ aclRequest, certification, grantACLRequest, openCustomer }) => {
  const classes = useStyles();
  const [granted, setGranted] = useState(null);
  const { createdAt, grants } = aclRequest;
  const author = aclRequest.author();
  const fields = Object.keys(certification.acl).filter(
    (prop) => certification.acl[prop] === 'ON_REQUEST',
  );
  const g = granted || (grants ? (grants.access ? 'access' : 'noaccess') : 'undefined');
  return (
    <Fragment>
      <CertificateCard
        className={classes.card}
        certification={certification}
        noMedia
        noCertificate
        onClick={() => {}}
      />

      <ContactCard
        className={classes.card}
        key={author._id}
        avatarUrl={author.avatarUrl}
        fullName={`${author.fullName} - ${author.company}`}
        email={author.email}
        subheader={`aangevraagd op ${format(new Date(createdAt), 'yyyy.MM.dd')}`}
        onClick={() => openCustomer(author._id)}
      />
      <Header>Afgeschermde velden</Header>
      <List>
        {fields.map((field) => (
          <ProfileItem text={field} />
        ))}
      </List>
      <Card className={classes.card}>
        <CardHeader
          {...(grants
            ? {
                avatar: <Avatar src={aclRequest.granter().avatarUrl} />,
                subheader: `${grants.granted ? 'goedgekeurd' : 'afgekeurd'} op ${format(
                  new Date(grants.grantedAt),
                  'yyyy.MM.dd',
                )}`,
              }
            : {})}
          title={'Goedkeuring'}
        />
        <CardContent>
          <FormControl component="fieldset" className={classes.formControl}>
            <RadioGroup
              aria-label="Grant Access"
              name="acl"
              className={classes.group}
              value={g}
              onChange={(event, value) => {
                setGranted(value);
              }}
            >
              <FormControlLabel
                value="access"
                control={<Radio />}
                label="wel toegang op alle afgeschermde informatie"
              />
              <FormControlLabel
                value="noaccess"
                control={<Radio />}
                label="geen toegang op alle afgeschermde informatie"
              />
            </RadioGroup>
          </FormControl>
        </CardContent>
        <CardActions>
          <div style={{ flexGrow: 2 }} />
          {grants ? (
            <Button
              variant="contained"
              color="primary"
              disabled={!granted || (granted === 'access') === grants.granted}
              onClick={() => grantACLRequest(granted === 'access')}
            >
              Wijzigen
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              disabled={g === 'undefined'}
              onClick={() => grantACLRequest(granted === 'access')}
            >
              Indienen
            </Button>
          )}
        </CardActions>
      </Card>
    </Fragment>
  );
};

ACLRequestInfo.propTypes = {
  className: PropTypes.string,
  certificate: PropTypes.object.isRequired,
};

ACLRequestInfo.defaultProps = {
  className: '',
};

export default ACLRequestInfo;
