"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.parseJson = exports.parseAllProductJson = exports.constructAllProductJson = exports.constructAllJson = void 0;

var _deepmerge = _interopRequireDefault(require("deepmerge"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var general = {
  formJSON: {
    type: 'object',
    properties: {
      name: {
        type: 'string',
        maxLength: 64,
        description: 'Name of the fabricate'
      },
      code: {
        type: 'string',
        maxLength: 16,
        description: 'Code (internal identifier) used for this fabricate'
      },
      commercialName: {
        type: 'string',
        description: 'Alternative commercial name for the fabricate'
      },
      description: {
        type: 'string',
        description: 'An informative text describing the fabricate use, construction and application'
      }
    },
    required: ['name', 'code']
  },
  formUIJSON: {
    type: 'VerticalLayout',
    elements: [{
      type: 'Control',
      scope: '#/properties/name',
      options: {
        restrict: true
      }
    }, {
      type: 'Control',
      scope: '#/properties/code',
      options: {
        restrict: true
      }
    }, {
      type: 'Control',
      scope: '#/properties/commercialName'
    }, {
      type: 'Control',
      scope: '#/properties/description',
      options: {
        multi: true
      }
    }]
  },
  formData: {}
};

var parseJson = function parseJson(json) {
  try {
    return typeof json === 'string' && json !== '' && json !== '{}' ? JSON.parse(json) : json || {};
  } catch (e) {
    console.log(json);
    return {};
  }
};

exports.parseJson = parseJson;

var constructAllJson = function constructAllJson() {
  var schema = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var productType = arguments.length > 1 ? arguments[1] : undefined;
  return ['formJSON', 'formUIJSON', 'formData'].reduce(function (memo, prop) {
    var s = schema[prop] || {};

    if (productType === 'general') {
      return _objectSpread(_objectSpread({}, memo), {}, _defineProperty({}, prop, (0, _deepmerge["default"])(general[prop], parseJson(s.general))));
    }

    return _objectSpread(_objectSpread({}, memo), {}, _defineProperty({}, prop, (0, _deepmerge["default"])((0, _deepmerge["default"])(general[prop], parseJson(s.general)), parseJson(s[productType]))));
  }, {});
};

exports.constructAllJson = constructAllJson;

var constructAllProductJson = function constructAllProductJson() {
  var schema = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var types = arguments.length > 1 ? arguments[1] : undefined;
  return ['formJSON', 'formUIJSON', 'formData'].reduce(function (memo, prop) {
    var s = schema[prop] || {};
    return _objectSpread(_objectSpread({}, memo), {}, _defineProperty({}, prop, ['general'].concat(_toConsumableArray(types)).reduce(function (memo2, productType) {
      if (productType === 'general') {
        return _objectSpread(_objectSpread({}, memo2), {}, _defineProperty({}, productType, (0, _deepmerge["default"])(general[prop], parseJson(s.general))));
      }

      return _objectSpread(_objectSpread({}, memo2), {}, _defineProperty({}, productType, (0, _deepmerge["default"])((0, _deepmerge["default"])(general[prop], parseJson(s.general)), parseJson(s[productType]))));
    }, {})));
  }, {});
};

exports.constructAllProductJson = constructAllProductJson;

var parseAllProductJson = function parseAllProductJson(product) {
  return ['formJSON', 'formUIJSON', 'formData'].reduce(function (memo, prop) {
    return _objectSpread(_objectSpread({}, memo), {}, _defineProperty({}, prop, [{
      key: 'general'
    }].concat(_toConsumableArray(product.types)).reduce(function (memo2, _ref) {
      var key = _ref.key;

      if (product[prop] && product[prop][key]) {
        return _objectSpread(_objectSpread({}, memo2), {}, _defineProperty({}, key, parseJson(product[prop][key])));
      }

      return memo2;
    }, {})));
  }, {});
};

exports.parseAllProductJson = parseAllProductJson;