"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.usePublishedProductData = exports.useProductData = exports.useFabricateEdits = exports.nullable = exports["default"] = exports._useFabricateEdits = void 0;

var _react = require("react");

var _helpers = require("./helpers");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var useBothProductData = function useBothProductData(product) {
  return (0, _react.useMemo)(function () {
    var published = (0, _helpers.constructAllProductJson)(product.publishedFormSchema, product.types.map(function (_ref) {
      var key = _ref.key;
      return key;
    }));
    var draft = (0, _helpers.constructAllProductJson)(product, product.types.map(function (_ref2) {
      var key = _ref2.key;
      return key;
    })); // console.log({ published, draft, changes: JSON.stringify(published) !== JSON.stringify(draft) });

    return _objectSpread(_objectSpread({}, draft), {}, {
      changes: JSON.stringify(published) !== JSON.stringify(draft)
    });
  }, [product, product.publishedFormSchema]);
};

var useProductData = function useProductData(_ref3, productType) {
  var formJSON = _ref3.formJSON,
      formUIJSON = _ref3.formUIJSON,
      formData = _ref3.formData;
  return (0, _react.useMemo)(function () {
    return (0, _helpers.constructAllJson)({
      formJSON: formJSON,
      formUIJSON: formUIJSON,
      formData: formData
    }, productType);
  }, [formJSON, formUIJSON, formData, productType]);
};

exports.useProductData = useProductData;

var nullable = function nullable(d) {
  // console.log(d);
  return ['formJSON', 'formUIJSON', 'formData'].reduce(function (memo, prop) {
    if (!d[prop] || Object.keys(d[prop]).length === 0) {
      return _objectSpread(_objectSpread({}, memo), {}, _defineProperty({}, prop, null));
    }

    return memo;
  }, d);
};

exports.nullable = nullable;

var usePublishedProductData = function usePublishedProductData(product, productType) {
  return useProductData(product.publishedFormSchema, productType);
};

exports.usePublishedProductData = usePublishedProductData;

var _useFabricateEdits = function _useFabricateEdits(data) {
  var edits = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  return edits.reduce(function (memo, _ref4) {
    var editedData = _ref4.state.data;
    return _objectSpread(_objectSpread({}, memo), Object.keys(editedData).reduce(function (memo2, key) {
      return data[key] !== editedData[key] ? _objectSpread(_objectSpread({}, memo2), {}, _defineProperty({}, key, {
        value: editedData[key],
        state: 'new'
      })) : memo2;
    }, {}));
  }, {});
};

exports._useFabricateEdits = _useFabricateEdits;

var useFabricateEdits = function useFabricateEdits(data) {
  var edits = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];

  var e = _useFabricateEdits(data, edits);

  return Object.keys(e).reduce(function (_ref5, key) {
    var changes = _ref5.changes,
        states = _ref5.states;
    return {
      changes: _objectSpread(_objectSpread({}, changes), {}, _defineProperty({}, key, e[key].value)),
      states: _objectSpread(_objectSpread({}, states), {}, _defineProperty({}, key, e[key].state))
    };
  }, {
    changes: {},
    states: {}
  });
};

exports.useFabricateEdits = useFabricateEdits;
var _default = useBothProductData;
exports["default"] = _default;