// TODO: port to ui lib

import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

import { FabricateTranslationForm } from '@probeton/ui';

import { Flex, Header } from '@solar/solar-react';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      // maxWidth: 345,
    },
    sheet: {
      margin: 12,
      marginLeft: 24,
      marginRight: 24,
      padding: 12,
    },
  };
});

const FabricateTranslations = ({ fabricate, formJSON, formUIJSON, onChange, ...other }) => {
  const classes = useStyles();
  return (
    <Flex fullColumn className={classes.root} {...other}>
      <Header>Nederlands</Header>
      <FabricateTranslationForm
        data={fabricate.data}
        formJSON={formJSON}
        formUIJSON={formUIJSON}
        onChange={({ errors, data }) => onChange({ lang: 'nl', data })}
        classes={{ root: classes.sheet }}
      />
      <Header>Frans</Header>
      <FabricateTranslationForm
        data={fabricate.translations?.fr ?? {}}
        formJSON={formJSON}
        formUIJSON={formUIJSON}
        onChange={({ errors, data }) => onChange({ lang: 'fr', data })}
        classes={{ root: classes.sheet }}
      />
      <Header>Engels</Header>
      <FabricateTranslationForm
        data={fabricate.translations?.en ?? {}}
        formJSON={formJSON}
        formUIJSON={formUIJSON}
        onChange={({ errors, data }) => onChange({ lang: 'en', data })}
        classes={{ root: classes.sheet }}
      />
    </Flex>
  );
};

FabricateTranslations.propTypes = {
  fabricate: PropTypes.object.isRequired,
};

FabricateTranslations.defaulProps = {};

export default FabricateTranslations;
