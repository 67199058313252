import React from 'react';

import { Redirect, Route } from 'react-router-dom';

import sheets from '../../sheets';
import dialogs from '../../dialogs';

import { App } from '@probeton/ui';

import NavigationDrawerInspector from './NavigationDrawerInspector';

import { CommonRoutes } from './CommonNav';

import ContactView from './Contact/ContactView';
import CertificateSheet from './Certificate/CertificateSheet';
import ProductView from './Product/ProductView';
import TicketView from './Ticket/TicketView';

const InspectorApp = ({ history, user, ...other }) => (
  <App
    history={history}
    user={user}
    app="INSP"
    sheets={sheets}
    dialogs={dialogs}
    navigationDrawer={NavigationDrawerInspector}
    {...other}
  >
    {({
      handleDetailViewToggle,
      handleDialog,
      getActionBarContainer,
      setImageOpen,
      setPdfOpen,
    }) => (
      <CommonRoutes
        handleDetailViewToggle={handleDetailViewToggle}
        handleDialog={handleDialog}
        history={history}
        user={user}
      >
        <Route path="/" exact render={() => <Redirect to="/certificates" />} />
        <Route
          path="/users"
          exact
          render={(props) => (
            <ContactView
              history={history}
              openContact={(id) => handleDetailViewToggle('contact', id)}
              onCreateEmployee={() => handleDialog('createEmployee')}
              {...props}
            />
          )}
        />
        <Route
          path="/certificates"
          exact
          render={(props) => (
            <ProductView
              history={history}
              openProduct={(id) => handleDetailViewToggle('product', id)}
              onCreateCertificate={() => handleDialog('createCertificate')}
              {...props}
            />
          )}
        />
        <Route
          path="/certificates/:id"
          exact
          render={({ match }) => {
            if (!getActionBarContainer()) {
              return 'hold it';
            }
            return (
              <CertificateSheet
                id={match.params.id}
                history={history}
                currentUser={user}
                openCertificate={(id) => handleDetailViewToggle('certificate', id)}
                handleDetailViewToggle={handleDetailViewToggle}
                openDialog={handleDialog}
                getActionBarContainer={getActionBarContainer}
                setImageOpen={setImageOpen}
                setPdfOpen={setPdfOpen}
              />
            );
          }}
        />
        <Route
          path="/tickets"
          exact
          render={(props) => (
            <TicketView
              tabs={{
                new: {
                  label: 'Nieuw',
                  statuses: ['created'],
                  dontWrap: true,
                },
                current: {
                  label: 'in behandeling',
                  statuses: ['accepted', 'rejected'],
                  dontWrap: true,
                },
                waiting: {
                  label: 'bij Probeton',
                  statuses: ['approved', 'reviewed'],
                  dontWrap: true,
                },
                published: {
                  label: 'gepubliceerd',
                  statuses: ['published', 'published_provisional'],
                  dontWrap: true,
                },
              }}
              history={history}
              openTicket={(id) => handleDetailViewToggle('ticket', id)}
              {...props}
            />
          )}
        />
      </CommonRoutes>
    )}
  </App>
);

// prettier-ignore
export default InspectorApp;
