import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';

import DownloadIcon from '@material-ui/icons/GetApp';
import { FileListItem, EmptyState } from '@solar/solar-react';
import { List } from '@material-ui/core';

const DownloadList = ({
  downloads,
  setPdfOpen,
  setImageOpen,
  emptyLabel = 'Je hebt nog geen bestanden gedownload.',
}) => {
  if (!downloads || downloads.length === 0) {
    return <EmptyState Icon={DownloadIcon} label={emptyLabel} />;
  }
  return (
    <List>
      {downloads.map(({ document, formattedDate }) =>
        document ? (
          <FileListItem
            interactive
            onClickOpen={() => {
              document.indexOf('.pdf') > 0 ? setPdfOpen(document) : setImageOpen(document);
            }}
            onClickDownloadFile={() => window.open(document, '__blank')}
            label={formattedDate}
            fileUrl={document}
          />
        ) : (
          ''
        ),
      )}
    </List>
  );
};

DownloadList.propTypes = {
  openContact: PropTypes.func,
};

export default DownloadList;
