import React from 'react';
import PropTypes from 'prop-types';

import { Typography, Badge } from '@material-ui/core';
import { CoverTabView, useEditable } from '@solar/poc-ui';

import { ResponsiveSheet } from '@probeton/solar-react';

import {
  ActionBar,
  CertificatePDF,
  CertificateInfo,
  CertificateBenor,
  CertificateFabricates,
} from '@probeton/ui';

import SubscribeData from '../../../SubscribeData';

import CertificateDocuments from './CertificateDocuments';
import CertificateDownloadList from './CertificateDownloadList';
import ACLRequestCardList from './ACLRequestCardList';
import CertificateTicketList from './CertificateTicketList';
import { certificationMapper, certificateMapper } from '../Manufacturer/helpers';

const t = {
  core: {
    actions: {
      cancel: 'Annuleren',
      close: 'Annuleren',
      confirm: 'Bevestigen',
      delete: 'Verwijderen',
      edit: 'Bewerken',
      invite: 'Uitnodigen',
      login: 'Aanmelden',
      logout: 'Afmelden',
      ok: 'Ok',
      save: 'Opslaan',
      search: 'Zoeken',
    },
  },
  acl: {
    ON_REQUEST: 'enkel op aanvraag',
    PRIVATE: 'enkel voor medewerkers',
    PUBLIC: 'publiek',
    REGISTERED: 'enkel wanneer aangemeld',
  },
  attest: 'attest',
  benor: {
    addPdf: 'Voeg PDF toe',
    description: 'Beschrijving',
    newVersion: 'Nieuwe versie opladen',
    public: 'Publieke bijlage',
    restricted: 'Bijlage met toegangsbeheer',
    searchable: 'Zoekbare informatie',
    version: 'Versie',
  },
  docs: {
    addDoc: 'Voeg document toe',
    addImage: 'Voeg afbeelding toe',
    cover: 'Omslag',
    docs: 'Documenten',
    images: 'Afbeeldingen',
  },
  fabricates: {
    create: {
      action: {
        single: 'Fabricaat aanmaken',
        multiple: 'Alle Fabricaten aanmaken',
        import: 'Alle Fabricaten importeren',
      },
      code: 'Fabricaatcode',
      description: 'Fabricaatbeschrijving',
      info: 'Algemeen',
      isNotCertified: 'Nog niet gecertificeerd',
      name: 'Naam',
      specification: 'Fabricaatspecificatie',
      title: 'Nieuw fabricaat',
      titleMultiple: 'Nieuwe fabricaten',
    },
    edit: {
      restrictedTechnicalSheetRef: 'Fabricaatreferentie BENOR Bijlage met toegangsbeheer',
      technicalSheetRef: 'Fabricaatreferentie publieke BENOR Bijlage',
    },
    empty: 'Er werden nog geen fabrikaten aangemaakt binnen dit gecertifieerd gamma.',
  },
  info: {
    certificate: 'Certificaat informatie',
    codes: 'ReferentieDocumenten',
    inspector: 'Keuringsinstelling',
    inspectorAssignment:
      'Wanneer niemand werd toegekend, blijft de verantwoordelijke van de keuringsinstelling verantwoordelijk',
    preview: 'Bekijk publieke informatie fiche',
    product: 'Product informatie',
  },
  pageRef: {
    create: 'Maak referentie aan',
    empty: 'nog geen referentie',
    end: 'zet als eind',
    pages: 'page {{start}} - {{end}}',
    start: 'zet als start',
  },
  productTypes: 'Producttypes',
  rep: {
    empty: 'Geen certificaten',
    title: 'Onze certificaten',
  },
  table: {
    certificate: 'Certificaat',
    manufacturer: 'Fabrikant',
    unit: 'Productiezetel',
  },
  tabs: {
    aclRequests: 'Aanvragen',
    allFabricates: 'Alle producten binnen dit certificaat',
    benor: 'BENOR bijlage',
    docs: 'Docs',
    downloads: 'Downloads',
    fabricates: 'Fabricaten',
    info: 'Info',
    tickets: 'Aanpassingen',
  },
  tickets: {
    create: {
      action: 'Ticket aanmaken',
      confirmation:
        'Het ticket werd aangemaakt. U ontvangt een melding wanneer het wordt opgevolgd.',
      error: 'Er was een probleem met het aanmaken van het ticket.',
      message: 'Heb je nog een opmerking die je wilt meesturen met je aanvraag?',
      title:
        'Door deze aanpassing aan te vragen, wordt een ticket aangemaakt om alle aanpassingen te controleren. Er wordt een melding uitgestuurd om de juiste personen op de hoogte te brengen. Deze actie kan dus niet meer ongedaan gemaakt worden.',
    },
    createTicket: 'Aanvraag publicatie aanpassingen opstarten',
    currentTickets: 'Lopende aanvragen',
    edits: {
      CertificateEdit: 'beschrijving certificaat aangepast',
      FabricateAdd: 'nieuw fabricaat aangemaakt',
      FabricateEdit: 'fabricaat aangepast',
      TechnicalSheetVersion: 'nieuwe versie BENOR Bijlage',
      TechnicalSheetVersionRestricted: 'nieuwe versie afgeschermde BENOR Bijlage',
      certificate: {
        description: 'Beschrijving',
      },
      description: 'op {{date}} door {{author}}',
      empty: 'Geen wijzigingen',
      title: 'Aanpassingen',
    },
    empty: 'Er werden nog geen aanpassingen gedaan voor dit gamma fabricaten.',
    merge: {
      action: 'Ticket aanpassen',
      confirmation:
        'Het ticket werd aangepast. U ontvangt een melding wanneer het wordt opgevolgd.',
      error: 'Er was een probleem met het aanpassen van het ticket.',
      title:
        'Alle aanpassingen zullen worden toegevoegd aan het geselecteerd ticket. Er wordt geen melding meer uitgestuurd. Deze actie kan niet worden ongedaan gemaakt. U moet zelf nog het ticket opnieuw insturen wanneer het was geweigerd.',
    },
    pulishedTickets: 'Gepubliceerde aanvragen',
    recentChanges: 'Recente aanpassingen',
    select: {
      merge: 'Samenvoegen',
      message1:
        'Selecteer een bestaand ticket om alle recente aanpassingen aan toe te voegen. Dit kan enkel voor tickets die nog niet aanvaard werden of voor ticket die terug geweigerd werden.',
      message2:
        'U kan ook steeds een nieuw ticket aanmaken om de aanpassingen afzonderlijk goed te keuren en te laten publiceren door het geselecteerde ticket te deselecteren.',
      newTicket: 'Nieuw ticket aanmaken',
      title: 'Wil je aanpassingen samenvoegen?',
    },
    tickets_one: '{{count}} aanpassing',
    tickets_other: '{{count}} aanpassingen',
  },
  title: 'Certificaten',
  validity: {
    inOpschorting: 'in opschorting vanaf {{date}}',
    inToelating: 'in toelatingsperiode',
    inValid: 'certificaat was geldig van {{date}}',
    valid: 'certificaat geldig van {{date}}',
  },
};
const CertificateContainer = ({
  history,
  currentUser,
  createdId,
  certification,
  id,
  handleDetailViewToggle,
  openDialog,
  getActionBarContainer,
  editCertification,
  changeCertificationACL,
  addProduct,
  duplicateProduct,
  createTicket,
  setPdfOpen,
  setImageOpen,
}) => {
  const [isDirty, setEdits, acl, cancelEdits] = useEditable(
    ['technicalSheet', 'images', 'documents'],
    certification.acl,
    id,
  );

  const [cIsDirty, cSetEdits, cMerged, cCancelEdits] = useEditable(
    ['specification'],
    certification,
    id,
  );

  const { certificate: c, unit: u } = certification;
  const unit = u();

  const certificate = certificationMapper(certification);
  // console.log({ certification, certificate });
  const manufacturer = unit.manufacturer();
  const tabs = {
    info: {
      label: 'Info',
      component: (
        <ResponsiveSheet noPadding>
          <CertificateInfo
            labels={{
              ...t.info,
              attest: t.attest,
              productTypes: t.productTypes,
              validity: (key) => t.validity[key],
              table: t.table,
            }}
            certificate={certificate}
            openRequest={(id) => openDialog('editFabricate', id)}
          />
        </ResponsiveSheet>
      ),
    },
    benor: {
      label: 'Benor bijlage',
      component: (
        <ResponsiveSheet noPadding>
          {
            <CertificateBenor
              acl={acl}
              certificate={certificate}
              onChangeACL={(prop) => (acl) => {
                setEdits({ [prop]: acl });
              }}
              onPropChange={(prop) => (v) => {
                cSetEdits({ [prop]: v });
              }}
              setPdfOpen={setPdfOpen}
              setImageOpen={setImageOpen}
            />
          }
        </ResponsiveSheet>
      ),
    },
    docs: {
      label: 'Docs',
      component: (
        <ResponsiveSheet noPadding>
          <CertificateDocuments
            acl={acl}
            certificate={certificate}
            onChangeACL={(prop) => (acl) => {
              setEdits({ [prop]: acl });
            }}
            setPdfOpen={setPdfOpen}
            setImageOpen={setImageOpen}
          />
        </ResponsiveSheet>
      ),
    },
    products: {
      label: 'Fabricaten',
      component: (
        <CertificateFabricates
          certificate={certificate}
          product={certificate.product}
          fabricates={certificate.products().map((f) => ({ ...f, ...f.data }))}
          createFabricate={(product, amount = 1) => {
            const p = addProduct(certificate, product, currentUser);
            if (amount > 1) {
              // console.log('do duplication!', amount, p);
              duplicateProduct(
                { ...product, certification: () => certificate },
                currentUser,
                amount - 1,
              );
            }
          }}
          openFabricate={(id) => handleDetailViewToggle('fabricate', id)}
          openFabricateEdits={(id) =>
            openDialog('fabricateTechSheetEdits', null, { propsForUrl: { fabricateId: id } })
          }
          labels={{
            ...t.fabricates,
            ...t.core.actions,
          }}
        />
      ),
    },
    editRequests: {
      label: (
        <Badge color="primary" badgeContent={certification.unrequestedChanges().length}>
          Aanpassingen
        </Badge>
      ),
      component: (
        <CertificateTicketList
          tickets={certification.tickets()}
          onCreateTicket={() => createTicket(certification, currentUser)}
          unrequestedChanges={certification.unrequestedChanges()}
          openTicket={(id) => handleDetailViewToggle('ticket', id)}
          openFabricate={(id) => handleDetailViewToggle('fabricate', id)}
          openFabricateEdits={(id) =>
            openDialog('fabricateTechSheetEdits', null, { propsForUrl: { fabricateId: id } })
          }
        />
      ),
    },
    downloads: {
      label: 'Downloads',
      component: (
        <CertificateDownloadList
          downloads={certification.downloads()}
          openCustomer={(id) => handleDetailViewToggle('customer', id)}
          setPdfOpen={setPdfOpen}
          setImageOpen={setImageOpen}
        />
      ),
    },
    aclRequests: {
      label: (
        <Badge color="primary" badgeContent={certification.unrespondedAclRequests().length}>
          Aanvragen
        </Badge>
      ),
      component: (
        <ACLRequestCardList
          aclRequests={certification.aclRequests()}
          openACLRequest={(id) => handleDetailViewToggle('aclRequest', id)}
          emptyLabel="Er zijn nog geen gebruikers die extra informatie hebben aangevraagd voor dit gamma fabricaten."
        />
      ),
    },
  };
  return (
    <CoverTabView
      title={
        <div>
          <Typography variant="heading" color="inherit" noWrap>
            {manufacturer.name}.
          </Typography>
          <Typography variant="title" color="inherit" noWrap>
            {certificate._id}. {certificate.title}
          </Typography>
          <CertificatePDF certification={certification} width={144} />
        </div>
      }
      cover={certificate.coverSrc}
      tabs={tabs}
      action={
        <ActionBar
          open={isDirty || cIsDirty}
          affirmativeButtonProps={{
            onClick: (c) => {
              if (isDirty) {
                changeCertificationACL(certification, acl, currentUser);
              }
              if (cIsDirty) {
                editCertification(certification, cMerged, currentUser);
              }
            },
          }}
          dismissiveButtonProps={{
            onClick: (c) => {
              if (isDirty) {
                cancelEdits();
              }
              if (cIsDirty) {
                cCancelEdits();
              }
            },
          }}
          getContainer={getActionBarContainer}
        />
      }
    />
  );
};

const CertificateSheet = ({ id, ...other }) => {
  return (
    <SubscribeData>
      {({
        certifications,
        editCertification,
        changeCertificationACL,
        addProduct,
        duplicateProduct,
        createTicket,
        createdId,
        helpers: { get, all },
      }) => {
        const certification = get(certifications, id);
        if (!certification) {
          return '404';
        }
        return (
          <CertificateContainer
            id={id}
            certification={certification}
            editCertification={editCertification}
            changeCertificationACL={changeCertificationACL}
            addProduct={addProduct}
            duplicateProduct={duplicateProduct}
            createTicket={createTicket}
            createdId={createdId}
            {...other}
          />
        );
      }}
    </SubscribeData>
  );
};

CertificateSheet.propTypes = {
  history: PropTypes.object,
  user: PropTypes.object,
  id: PropTypes.string,
};

export default CertificateSheet;
