import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import { DialogContent, IconButton } from '@material-ui/core';

import PreviewIcon from '@material-ui/icons/Visibility';

import { ActionBar, TodoMessage } from '@probeton/ui';

import { TabView } from '@probeton/solar-react';

import SubscribeData from '../../../SubscribeData';
import FabricateEdit from './FabricateEdit';
import FabricateTranslations from './FabricateTranslations';
import FabricatePermissions from './FabricatePermissions';

import { usePublishedProductData, useFabricateEdits } from '@probeton/ui';
import { certificationMapper } from '../Manufacturer/helpers';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    height: '80vh !important',
  },
}));

export const FabricateEditor = ({
  fabricate,
  duplicateProduct,
  currentUser,
  id,
  user,
  editFabricate,
  formJSON,
  formUIJSON,
  getActionBarContainer,
  handleDetailViewToggle,
}) => {
  const classes = useStyles();
  const [edits, setEdits] = useState({});
  const [formValid, setFormValid] = useState(false);

  const { name, code } = fabricate.data;

  const isDirty =
    Object.keys(edits).length > 0 && JSON.stringify(fabricate.data) !== JSON.stringify(edits);

  const tabs = {
    info: {
      label: 'info',
      component: (
        <FabricateEdit
          fabricate={{ ...fabricate, data: { ...fabricate.data, ...edits } }}
          onChange={({ errors, data }) => {
            console.log({ errors, data });
            setFormValid(errors.length === 0);
            const changes = Object.keys(data).reduce(
              (memo, key) =>
                data[key] === fabricate.data[key] ||
                data[key] === edits[key] ||
                (data[key] === '' && !fabricate.data[key]) ||
                (!data[key] && !fabricate.data[key])
                  ? memo
                  : { ...memo, [key]: data[key] },
              {},
            );
            if (Object.keys(changes).length > 0) {
              setEdits({ ...edits, ...changes });
            }
          }}
          onDuplicate={() => {
            duplicateProduct(fabricate, currentUser, 1);
          }}
          formJSON={formJSON}
          formUIJSON={formUIJSON}
        />
      ),
    },
    translations: {
      label: 'vertalingen',
      component: (
        <FabricateTranslations
          fabricate={fabricate}
          onChange={({ lang, data }) => {
            // console.log({ lang, data });
            // setEdits({ trans: lang });
          }}
          formJSON={formJSON}
          formUIJSON={formUIJSON}
        />
      ),
    },
    acl: {
      label: 'permissies',
      component: (
        <FabricatePermissions
          fabricate={fabricate}
          onChange={({ lang, data }) => {
            // console.log({ lang, data });
            // setEdits({ trans: lang });
          }}
          formJSON={formJSON}
          formUIJSON={formUIJSON}
        />
      ),
    },
  };
  return (
    <TabView
      classes={{ scrollContainer: classes.wrapper }}
      appBarProps={{
        title: `${code} - ${name}`,
        // titleOnTop: false,
        actions: (
          <Fragment>
            <ActionBar
              open={isDirty}
              affirmativeButtonProps={{
                onClick: (c) => {
                  editFabricate(
                    fabricate,
                    { data: edits, isValid: formValid, fabricateId: fabricate._id },
                    user,
                  );
                  setEdits({});
                },
              }}
              dismissiveButtonProps={{
                onClick: (c) => setEdits({}),
              }}
              getContainer={getActionBarContainer}
            />
          </Fragment>
        ),
      }}
      tabs={tabs}
      default="info"
    />
  );
};

export const FabricateEditContainer = ({ fabricate, ...other }) => {
  const certificate = certificationMapper(fabricate.certification());

  const { product } = certificate;

  const { changes } = useFabricateEdits(fabricate.data, fabricate.edits);

  const { formJSON, formUIJSON } = usePublishedProductData(product, fabricate.productType);

  return (
    <FabricateEditor
      fabricate={{
        ...fabricate,
        ...fabricate.data,
        data: { name: fabricate.name, code: fabricate.code, ...fabricate.data, ...changes },
      }}
      formJSON={formJSON}
      formUIJSON={formUIJSON}
      {...other}
    />
  );
};

const EditFabricateSheet = ({ id, ...other }) => {
  return (
    <SubscribeData>
      {({ products, editProduct, duplicateProduct, helpers: { get, all } }) => {
        const fabricate = get(products, id);
        if (!fabricate) {
          return '404';
        }
        <FabricateEditContainer
          fabricate={fabricate}
          editFabricate={editProduct}
          id={id}
          duplicateProduct={duplicateProduct}
        />;
      }}
    </SubscribeData>
  );
};

EditFabricateSheet.propTypes = {
  history: PropTypes.object,
  user: PropTypes.object,
  id: PropTypes.string,
};

export default EditFabricateSheet;
