import React, { Fragment, useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';

import * as R from 'ramda';

import SubscribeData from '../../../SubscribeData';

import elasticlunr from 'elasticlunr';

import {
  CardListOverviewToTableDetail,
  ProfileItem,
  Favourites,
  SearchResultCardBody,
} from '@probeton/ui';

import { Flex } from '@solar/solar-react';

import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

import { Typography } from '@material-ui/core';
import { TextField } from '@material-ui/core';
import { Fade } from '@material-ui/core';

import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';

import { makeStyles } from '@material-ui/core/styles';

const backgrounds = [
  '/images/probeton-slider-home1.jpg',
  '/images/probeton-slider-home2.jpg',
  '/images/probeton-slider-home3.jpg',
  '/images/probeton-slider-home4.jpg',
  '/images/probeton-slider-home5.jpg',
];

const useStylesSearchBar = (unit) =>
  makeStyles((theme) => ({
    root: {
      flexGrow: 2,
      transition: 'flex-basis 500ms ease-in-out',
      border: `${Math.ceil(unit / 3)}px solid #e2e2e1`,
      overflow: 'hidden',
      borderRadius: unit * 2,
      margin: 0,
      marginTop: unit,
      marginBottom: unit,
      maxWidth: 1200,
      width: '60vw',
      backgroundColor: '#fcfcfb',
      fontSize: unit * 2,
      padding: unit / 2,
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      fontWeight: 500,
      '&:hover': {
        backgroundColor: '#fff',
      },
      '&$focused': {
        color: theme.palette.primary.main,
        backgroundColor: '#fff',
        // boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
        borderColor: '#666',
      },
    },

    input: {
      textTransform: 'uppercase',
    },
    focused: {},
    icon: {
      margin: unit,
    },
    toggleWrapper: {
      height: 'auto',
    },
  }))();

const useStylesSearch = (unit, selectedBackground) =>
  makeStyles((theme) => ({
    wallpaper: {
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: '75%',
      left: 0,
      zIndex: -1,
      // backgroundImage: 'url(/images/backgrounds/death_valley.jpg)',
      backgroundImage: `url(${selectedBackground})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center 10%',
      backgroundRepeat: 'no-repeat',
    },
    logo: {
      display: 'block',
      background: 'url("/images/probeton_logo_t.png")',
      height: unit * 8,
      width: unit * 8,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      marginBottom: 0,
    },
    emptyState: {
      color: '#aaa',
      fontSize: unit * 2,
    },
    selectedToggle: {
      backgroundColor: '#fff',
      // boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
      borderColor: theme.palette.primary.main,
    },
    toggleButtonGroup: {
      margin: 4,
    },
    searchBarWrapper: {
      width: '100%',
      padding: 0,
      paddingLeft: unit,
      paddingRight: unit,
    },
    searchBarSmall: {
      width: 300,
      fontSize: unit / 2,
      padding: unit / 2,
    },
  }))();

function SearchBar({ className, unit, value, onChange, ...props }) {
  const classes = useStylesSearchBar(unit);

  return (
    <TextField
      InputProps={{
        classes,
        className,
        disableUnderline: true,
        startAdornment: (
          <InputAdornment position="start" className={classes.icon}>
            <SearchIcon color="inherit" fontSize={unit > 20 ? 'large' : 'small'} />
          </InputAdornment>
        ),
        ...(value.length > 0
          ? {
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton color="inherit" onClick={() => onChange('')}>
                    <CloseIcon color="inherit" fontSize={unit > 20 ? 'large' : 'small'} />
                  </IconButton>
                </InputAdornment>
              ),
            }
          : {}),
      }}
      onChange={(event) => onChange(event.target.value)}
      value={value}
      {...props}
    />
  );
}

const SearchProducts = ({
  products,
  domains,
  categories,
  certificates,
  classes,
  unit,
  openProduct,
  resultsChanged,
  favourites,
  favouritesSelected,
  setFavouritesSelected,
}) => {
  const [search, setSearch] = useState('');
  const [domain, setDomain] = useState(null);
  const [selectedCategories, setSelectedCategories] = useState({});

  const mappedProducts = useMemo(() => {
    return products.map(({ certification, unit, manufacturer, ...other }) => {
      const certificate = certification().certificate();
      // console.log(unit, unit());
      return {
        ...other,
        number: certification().number,
        manufacturerName: manufacturer().name,
        unitAddress: unit() && unit().geo ? unit().geo.formatted_address : '',
        certificationId: certification()._id,
        ...(certificate
          ? {
              certificateId: certificate._id,
              categoryId: certificate.categoryId,
              domainId: certificate.category().domainId,
              certificateTitle: certificate.title,
              certificateDescription: certificate.description,
              certificateCodes: certificate.codes,
            }
          : {}),
      };
    });
  }, [products.length]);

  const productMap = useMemo(
    () => mappedProducts.reduce((memo, p) => ({ ...memo, [p._id]: p }), {}),
    [products.length],
  );

  const index = useMemo(() => {
    var index = elasticlunr(function () {
      var self = this;
      [
        'code',
        'name',
        'description',
        'number',
        'manufacturerName',
        'unitAddress',
        'certificateTitle',
        'certificateDescription',
        'certificateCodes',
      ].forEach((field) => {
        self.addField(field);
      });
      self.setRef('_id');
      self.saveDocument(false);
    });
    mappedProducts.forEach((p) => {
      index.addDoc(p);
    });

    // return [memoizeWith(identity, searchTerm => fuse.search(searchTerm))];
    return index;
  }, [products.length]);

  const domainCategories = useMemo(() => {
    return categories.filter(({ domainId }) => domainId === domain);
  }, [domain]);

  const results = useMemo(
    () =>
      search.length > 2
        ? index
            .search(search, {
              fields: {
                code: { boost: 4 },
                name: { boost: 4 },
                number: { boost: 4 },
                description: { boost: 2 },
                manufacturerName: { boost: 2 },
                certificateTitle: { boost: 2 },
                certificateDescription: { boost: 2 },
                certificateCodes: { boost: 2 },
                unitAddress: { boost: 1 },
              },
              expand: true,
              bool: 'OR',
            })
            .filter(({ score }) => score > 0.2)
            .map(({ ref }) => productMap[ref])
        : mappedProducts,
    [products.length, search],
  );

  let filteredResults = results;

  if (domain) {
    if (selectedCategories[domain] && selectedCategories[domain].length > 0) {
      filteredResults = results.filter(({ categoryId }) =>
        selectedCategories[domain].includes(categoryId),
      );
    } else {
      filteredResults = results.filter(({ domainId }) => domainId === domain);
    }
  }

  if (favouritesSelected) {
    filteredResults = filteredResults.filter(({ _id }) => favourites.includes(_id));
  }

  // group by certificate and certification
  const groupByCertificateCertification = R.pipe(
    R.groupBy(R.prop('certificateId')),
    // R.map(R.groupBy(R.prop('certificationId'))),
  );

  const sC = domain && selectedCategories[domain] ? selectedCategories[domain].join('-') : '';

  const sortedResults = useMemo(() => {
    const g = groupByCertificateCertification(filteredResults);
    const withCount = Object.keys(g).reduce(
      (memo, certificateId) => [
        ...memo,
        {
          certificate: certificates[certificateId],
          results: g[certificateId],
          nbProducts: -1 * g[certificateId].length, //-1 * Object.values(g[certificateId]).reduce((memo, m) => memo + m.length, 0),
        },
      ],
      [],
    );
    return R.sortBy(R.prop('nbProducts'))(withCount);
  }, [favouritesSelected, domain, sC, search]);

  useEffect(
    () =>
      resultsChanged(
        !!favouritesSelected || domain || (search.length > 2 && filteredResults.length > 0),
      ),
    [favouritesSelected, domain, search, filteredResults.length],
  );

  const searchActive =
    (!!favouritesSelected || !!domain || search.length > 2) && sortedResults.length > 0;

  return (
    <Fragment>
      <Flex
        classes={{ root: classes.searchBarWrapper }}
        alignItems={'center'}
        justifyContent={searchActive ? 'space-between' : 'flex-start'}
        flexDirection={searchActive ? 'row' : 'column'}
      >
        <div className={classes.logo} />
        <Favourites
          amount={favourites.length}
          active={favouritesSelected}
          onClick={() => setFavouritesSelected(!favouritesSelected)}
        />
        <SearchBar
          className={searchActive ? classes.searchBarSmall : ''}
          unit={unit}
          placeholder="type hier om te zoeken... "
          onChange={setSearch}
          value={search}
        />
      </Flex>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <ToggleButtonGroup
          className={classes.toggleButtonGroup}
          value={domain}
          exclusive
          onChange={(event, value) => {
            setDomain(value);
          }}
        >
          {domains.map(({ _id, title }) => (
            <ToggleButton classes={{ selected: classes.selectedToggle }} value={_id}>
              <Typography>{title}</Typography>
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
        {domain ? (
          <ToggleButtonGroup
            className={classes.toggleButtonGroup}
            value={selectedCategories[domain]}
            onChange={(event, value) => {
              setSelectedCategories({
                ...selectedCategories,
                [domain]: value,
              });
            }}
          >
            {domainCategories.map(({ _id, title }) => (
              <ToggleButton classes={{ selected: classes.selectedToggle }} value={_id}>
                <Typography>{title}</Typography>
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        ) : (
          ''
        )}
      </div>
      {search.length > 0 || domain || favouritesSelected ? (
        search.length > 0 && search.length < 3 && !domain && !favouritesSelected ? (
          <Typography align="center" className={classes.emptyState}>
            type minstens 3 karakters
          </Typography>
        ) : (filteredResults && filteredResults.length) > 0 ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              justifyContent: 'center',
              width: '100%',
              flexGrow: 2,
            }}
          >
            <Typography
              align="center"
              variant="caption"
              style={{ paddingTop: 8, marginRight: unit, alignSelf: 'flex-end' }}
            >
              {filteredResults.length} resultaten gevonden
            </Typography>
            <CardListOverviewToTableDetail
              cardListOverview={{
                items: sortedResults,
                renderCardBody: ({ certificate, nbProducts }) => (
                  <SearchResultCardBody
                    certificate={certificates[certificate._id]}
                    nbProducts={nbProducts}
                  />
                ),
              }}
              tableDetail={{
                title: ({ certificate }) => `${certificate._id}. ${certificate.title}`,
                subtitle: ({ certificate }) => certificate.renderCodes(),
                columns: [
                  {
                    width: 240,
                    flexGrow: 1.0,
                    label: 'Produkt',
                    dataKey: 'product',
                  },
                  {
                    width: 240,
                    flexGrow: 1.0,
                    label: 'Fabrikant',
                    dataKey: 'manufacturer',
                  },
                  {
                    width: 120,
                    flexGrow: 3.0,
                    label: 'Beschrijving',
                    dataKey: 'description',
                  },
                ],
                items: ({ results: detailItems }) => detailItems,
                renderTableRow: (item) => {
                  const { _id, code, name, description, manufacturerName, unitAddress } = item;
                  return {
                    _id,
                    product: <ProfileItem textLabel={name} text={code} style={{ padding: 0 }} />,
                    manufacturer: (
                      <ProfileItem
                        textLabel={unitAddress}
                        text={manufacturerName}
                        style={{ padding: 0 }}
                      />
                    ),
                    description,
                  };
                },
                onItemClick: (item) => openProduct(item.rowData._id),
              }}
            />
          </div>
        ) : (
          <Typography align="center" className={classes.emptyState}>
            geen resultaten
          </Typography>
        )
      ) : (
        ''
      )}
    </Fragment>
  );
};

const Search = ({ noWallPaper, user, history, ...props }) => {
  const unit = 24;
  const classes = useStylesSearch(unit);
  const [hasResults, setHasResults] = useState(false);
  const favouritesSelected = history.getQueryParam('favourites') === '1';
  return (
    <SubscribeData>
      {({ domains, categories, products, certificates, favourites }) => {
        const favs = user && user._id && favourites[user._id] ? favourites[user._id] : [];
        const all = Object.values;
        return (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              justifyContent: 'center',
              height: '100%',
              maxWidth: 1920,
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
          >
            {!noWallPaper ? (
              <Fade in={!hasResults}>
                <div className={classes.wallpaper} />
              </Fade>
            ) : (
              ''
            )}
            <SearchProducts
              products={all(products)}
              domains={all(domains)}
              categories={all(categories)}
              certificates={certificates}
              classes={classes}
              unit={unit}
              favourites={favs}
              resultsChanged={setHasResults}
              favouritesSelected={favouritesSelected}
              setFavouritesSelected={() => {
                history.addQueryParams({ favourites: favouritesSelected ? '0' : '1' });
              }}
              {...props}
            />
          </div>
        );
      }}
    </SubscribeData>
  );
};

Search.propTypes = {
  openProduct: PropTypes.func,
  noWallPaper: PropTypes.bool.isRequired,
};

Search.defaultProps = {
  noWallPaper: false,
};

export default Search;
