import React, { useState, useEffect } from 'react';
import { Provider } from 'react-contextual';

import { Typography, CircularProgress } from '@material-ui/core';

import { default as dataStore, fillDataStore } from '../../dataStore';

// componentDidMount() {
//   this.fetchData();
//   var self = this;
//   setTimeout(() => {
//     if (self.state.loading) {
//       self.setState({ generating: true });
//     }
//   }, 3000);
// }

const DataStoreProvider = ({ children, context: Context }) => {
  const [loading, setLoading] = useState(true);
  const [generating, setGenerating] = useState(false);

  useEffect(async () => {
    await fillDataStore();
    if (generating) {
      window.location.reload();
    } else {
      setLoading(false);
    }
  }, []);

  if (loading) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-evenly',
          width: '100%',
          height: '60vh',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            display: 'block',
            background: 'url("/images/probeton_logo_t.png")',
            height: 180,
            width: 180,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            marginBottom: 0,
          }}
        />
        <Typography
          align="center"
          alignContent="center"
          variant="display2"
          style={{ padding: 24, maxWidth: 500 }}
        >
          Gelieve even te wachten tijdens het genereren van de data...
        </Typography>
        <Typography align="center" alignContent="center" variant="body2">
          Dit gebeurt enkel wanneer u dit prototype voor de eerste keer in deze browser opstart.
        </Typography>
        <CircularProgress />
      </div>
    );
  }
  return <Provider store={dataStore} id="dataStore" children={children} />;
};

export default DataStoreProvider;
