import React, { useState } from 'react';
import { Router } from 'react-router';

import store from 'store2';

import DateFnsUtils from '@date-io/date-fns';

import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';

import { createHistory } from '@solar/solar-react';

import { create } from '@solar/poc-ui';
// import i18n from "i18next";

import { users } from '@probeton/mockdata';

// import dialogStore from "./dialogStore";

import DataStoreProvider from './DataStoreProvider';
import Root from './Root';

import sheets from '../../sheets';

import { themes } from './style';

import { scope } from '@probeton/core';
import { ErrorBoundary } from '@probeton/solar-react';

const history = createHistory(Object.keys(sheets));
const accounts = create(users, store.namespace(scope));

const RootContainer = () => {
  const [theme, setTheme] = useState(store(`${scope}.theme`) || 'light');

  const handleThemeToggle = () => {
    const newTheme = theme === 'light' ? 'dark' : 'light';
    store(`${scope}.theme`, newTheme);
    setTheme(newTheme);
  };

  return (
    <MuiThemeProvider theme={themes[theme]}>
      <DataStoreProvider>
        <Router history={history}>
          <ErrorBoundary history={history}>
            <Root
              onToggleTheme={handleThemeToggle}
              accounts={accounts}
              onLogout={() => {
                accounts.logout();
                history.push('/');
              }}
              onReset={() => {
                // dataStore.destroy();
              }}
              history={history}
            />
          </ErrorBoundary>
        </Router>
      </DataStoreProvider>
    </MuiThemeProvider>
  );
};
export default RootContainer;
