import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

import { List, Typography, IconButton } from '@material-ui/core';
import FavoriteIcon from '@material-ui/icons/Favorite';
import NoFavoriteIcon from '@material-ui/icons/FavoriteBorder';
import EditIcon from '@material-ui/icons/Edit';
import {
  Flex,
  FileListItem,
  ResponsiveAppBar,
  ScrollableContent,
  Header,
} from '@solar/solar-react';

import { useACLDispatch } from '@probeton/core';

import { CertificateCard, FabricateForm, ProfileItem, usePublishedProductData } from '@probeton/ui';

import DarkMuiThemeProvider from '../DarkMuiThemeProvider';

import FabricateList from './FabricateList';

import { certificationMapper } from '../Manufacturer/helpers';
import Business from '@material-ui/icons/Business';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      // maxWidth: 345,
    },
    sheet: {
      margin: 12,
      marginLeft: 24,
      marginRight: 24,
      padding: 12,
    },
  };
});

const FabricateInfo = ({
  fabricate,
  user,
  isFavourite,
  isEditable,
  isFavourable,
  openCertificate,
  onClickFavorite,
  onClickNavButton,
  openFabricate,
  openFabricateTechSheet,
  editFabricate,
  setPdfOpen,
  setImageOpen,
  ...other
}) => {
  // const { checkACL, checkDownload, setOnboardingDialogOpen } = useACLDispatch();

  const certificate = certificationMapper(fabricate.certification());

  const { product } = certificate;

  const { formJSON, formUIJSON } = usePublishedProductData(product, fabricate.productType);

  const classes = useStyles();
  const { name, code, description, specification, certification: c } = fabricate;
  const certification = c();
  const { acl } = certification;
  const technicalSheet = fabricate.technicalSheet || certification.technicalSheet;
  // console.log(unit, unit.manufacturer());
  // console.log(certification, certification.certificate());
  return (
    <Flex fullColumn className={classes.root} {...other}>
      <DarkMuiThemeProvider>
        <ResponsiveAppBar
          position="static"
          onClickNav={onClickNavButton}
          title={
            <Typography variant="heading" color="inherit" noWrap>
              {`${code} - ${name}`}
            </Typography>
          }
          actions={
            <Flex>
              {isEditable ? (
                <IconButton
                  color="inherit"
                  onClick={() => {
                    editFabricate(fabricate._id);
                  }}
                >
                  <EditIcon />
                </IconButton>
              ) : (
                ''
              )}
              {isFavourable ? (
                <IconButton
                  color="inherit"
                  onClick={() => {
                    // user ? onClickFavorite(!isFavourite) : setOnboardingDialogOpen(true);
                  }}
                >
                  {!user || !isFavourite ? <NoFavoriteIcon /> : <FavoriteIcon />}
                </IconButton>
              ) : (
                ''
              )}
            </Flex>
          }
        />
      </DarkMuiThemeProvider>
      <ScrollableContent>
        <Header>Fabrikaat Informatie</Header>
        <ProfileItem textLabel="code" text={fabricate.data.code}></ProfileItem>
        <ProfileItem textLabel="naam" text={fabricate.data.name}></ProfileItem>
        <ProfileItem
          textLabel="commerciele naam"
          text={fabricate.data.commercialName || '-'}
        ></ProfileItem>
        <ProfileItem
          textLabel="beschrijving"
          text={fabricate.data.description || '-'}
        ></ProfileItem>
        <List>
          <FileListItem
            interactive
            onClickOpen={() => {
              openFabricateTechSheet(fabricate._id);
            }}
            onClickDownloadFile={() => {
              openFabricateTechSheet(fabricate._id);
            }}
            label="Technische fiche"
            fileUrl={`versie 2.1`}
          />
        </List>
        <Header>Certificaat</Header>
        <CertificateCard certification={certification} />

        <Header>Afbeeldingen</Header>
        <List>
          <FileListItem interactive label="Omslag" fileUrl={fabricate.coverSrc} isImage />
          {fabricate.images.map(({ src, label }) => (
            <FileListItem
              interactive
              label={label}
              fileUrl={src}
              isImage
              onClickOpen={
                () => {}
                // checkACL({ certification, prop: 'images', acl }, () => setImageOpen(src))
              }
              onClickDownloadFile={
                () => {}
                // checkACL({ certification, prop: 'images', acl }, () => {
                //   checkDownload({ certification, doc: src }, () => window.open(src, '__blank'));
                // })
              }
            />
          ))}
        </List>
        <Header>Historiek</Header>
        <List>
          <FileListItem
            interactive
            onClickOpen={() => {}}
            onClickDownloadFile={() => {}}
            label="Technische fiche"
            fileUrl={`versie 2.1`}
          />
          <FileListItem
            interactive
            onClickOpen={() => {}}
            onClickDownloadFile={() => {}}
            label="Technische fiche"
            fileUrl={`versie 2.0`}
          />
          <FileListItem
            interactive
            onClickOpen={() => {}}
            onClickDownloadFile={() => {}}
            label="Technische fiche"
            fileUrl={`versie 1.3`}
          />
          <FileListItem
            interactive
            onClickOpen={() => {}}
            onClickDownloadFile={() => {}}
            label="Technische fiche"
            fileUrl={`versie 1.2`}
          />
          <FileListItem
            interactive
            onClickOpen={() => {}}
            onClickDownloadFile={() => {}}
            label="Technische fiche"
            fileUrl={`versie 1.1`}
          />
          <FileListItem
            interactive
            onClickOpen={() => {}}
            onClickDownloadFile={() => {}}
            label="Technische fiche"
            fileUrl={`versie 1.0`}
          />
        </List>
        <Header>Documenten</Header>
        <List>
          <FileListItem
            interactive
            onClickOpen={
              () => {}
              // checkACL({ certification, prop: 'technicalSheet', acl }, () =>
              //   setPdfOpen(`/technical_sheets/${technicalSheet}.pdf`),
              // )
            }
            onClickDownloadFile={
              () => {}
              // checkACL({ certification, prop: 'technicalSheet', acl }, () => {
              //   checkDownload(
              //     { certification, doc: `/technical_sheets/${technicalSheet}.pdf` },
              //     () => {
              //       window.open(`/technical_sheets/${technicalSheet}.pdf`, '__blank');
              //     },
              //   );
              // })
            }
            label="Technische fiche"
            fileUrl={`/technical_sheets/${technicalSheet}.pdf`}
          />
          <FileListItem
            interactive
            label="Certificaat"
            fileUrl="http://www.pdf995.com/samples/dpa.pdf"
          />
        </List>
        <Header>Andere fabricaten binnen dit certificaat</Header>
        <FabricateList fabricates={certification.products()} openFabricate={openFabricate} />
      </ScrollableContent>
    </Flex>
  );
};

FabricateInfo.propTypes = {
  fabricate: PropTypes.object.isRequired,
  isFavourite: PropTypes.bool.isRequired,
  openFabricate: PropTypes.func.isRequired,
  editFabricate: PropTypes.func.isRequired,
};

FabricateInfo.defaulProps = {
  isFavourite: false,
};

export default FabricateInfo;
