"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "AppDialogs", {
  enumerable: true,
  get: function get() {
    return _Dialogs["default"];
  }
});

var _Dialogs = _interopRequireDefault(require("./Dialogs"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }