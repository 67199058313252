import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { List, Avatar } from '@material-ui/core';

import { ProfileItem } from '@probeton/ui';

import TicketActions from './TicketActions';
import EditList from '../Certificate/EditList';

const useStyles = makeStyles((theme) => {
  return {
    padding: {
      padding: 24,
    },
  };
});

const TicketInfo = ({ ticket, user, actionProps, openFabricate, openFabricateEdits }) => {
  const classes = useStyles();
  return (
    <Fragment>
      <List>
        {ticket.lifecycleChanges().map(({ actor, label, ts }) => (
          <ProfileItem
            icon={<Avatar src={actor.avatarUrl} />}
            textLabel={actor.fullName}
            text={label}
          />
        ))}
      </List>
      <TicketActions user={user} ticket={ticket} {...actionProps} />
      <EditList
        edits={ticket.edits}
        openFabricate={openFabricate}
        openFabricateEdits={openFabricateEdits}
      />
    </Fragment>
  );
};

export default TicketInfo;
