import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import { memoizeWith, identity } from 'ramda';
import Fuse from 'fuse.js';

import Typography from '@material-ui/core/Typography';

import { Flex, ScrollableContent } from '@solar/solar-react';

import { ResponsiveSearchField } from '@probeton/solar-react';

import SubscribeData from '../../../SubscribeData';
import FabView from '../FabView';

import { CertificateCardList } from '@probeton/ui';

export const CertificateSearch = ({ certifications, title = 'Certificaten', ...other }) => {
  const fuseSearchMemoized = useMemo(() => {
    const options = {
      keys: ['title', '_id', 'description'],
      shouldSort: true,
      threshold: 0.3,
    };
    const fuse = new Fuse(certifications, options);
    return memoizeWith(identity, (search) => fuse.search(search));
  }, [certifications.length]);

  const [search, setSearch] = useState('');
  const filteredCertifications = search === '' ? certifications : fuseSearchMemoized(search);

  return (
    <FabView
      title={
        <Flex alignItems="center" justifyContent="space-between" flexGrow={1}>
          <Typography variant="headline" color="inherit" noWrap>
            {title}
          </Typography>
          <ResponsiveSearchField
            InputProps={{
              id: 'searchCertifications',
            }}
            placeholder="Zoeken"
            value={search}
            onChange={(value) => setSearch(value)}
          />
        </Flex>
      }
      titleOnTop
      disableShadow
    >
      <ScrollableContent>
        <CertificateCardList certifications={filteredCertifications} noCertificate {...other} />
      </ScrollableContent>
    </FabView>
  );
};

const CertificationView = (props) => {
  return (
    <SubscribeData>
      {({ certifications, helpers: { all } }) => {
        return <CertificateSearch certifications={all(certifications)} {...props} />;
      }}
    </SubscribeData>
  );
};

export const CertificateViewUnit = ({ unit, ...props }) => {
  return (
    <SubscribeData>
      {({ certifications, helpers: { all } }) => {
        const unitCerts = all(certifications).filter(({ unitId }) => unitId === unit._id);
        return <CertificateSearch certifications={unitCerts} {...props} />;
      }}
    </SubscribeData>
  );
};

CertificationView.propTypes = {
  openCertificate: PropTypes.func,
};

export default CertificationView;
