"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _styles = require("@material-ui/core/styles");
var _colors = require("@material-ui/core/colors");
var _solarReact = require("@solar/solar-react");
var _ansiColors = require("ansi-colors");
var _colors2 = require("../colors");
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }
function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
var palette = _objectSpread(_objectSpread({
  type: 'dark',
  primary: _colors2.probetonBlue,
  secondary: _colors.deepOrange,
  error: _colors.red,
  warning: _colors.pink
}, _solarReact.extraColors), {}, {
  background: {
    appBar: _colors2.probetonBlue
  }
});
var _default = (0, _styles.createTheme)(_objectSpread({
  palette: palette
}, _solarReact.themeBase));
exports["default"] = _default;