import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { List } from '@material-ui/core';

import { ProfileItem } from '@probeton/ui';

const useStyles = makeStyles((theme) => {
  return {
    padding: {
      padding: 24,
    },
  };
});

const CustomerInfo = ({ customer: { firstName, lastName, company, email, role } }) => {
  const classes = useStyles();
  return (
    <List>
      <ProfileItem textLabel={`voornaam`} text={firstName} />
      <ProfileItem textLabel={`achternaam`} text={lastName} />
      <ProfileItem textLabel={`bedrijf`} text={company} />
      <ProfileItem textLabel={`e-mail`} text={email} />
      <ProfileItem textLabel={`rol`} text={role} />
    </List>
  );
};

export default CustomerInfo;
