"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.img = exports.mergeModifier = exports.omitDeep = exports.getRandomElements = exports.shuffleArray = exports.getRandomElement = exports.rndBetween = exports.getRandomArbitrary = exports.asTime = exports.minutes = exports.hours = exports.padding = exports.uuid = exports.rnd = exports.shuffle = exports.allPropsNotEmpty = exports.allPropsExist = exports.compose = exports.throttle = exports.debounce = void 0;
var _faker = _interopRequireDefault(require("faker"));
var R = _interopRequireWildcard(require("ramda"));
var _arguments = typeof arguments === "undefined" ? void 0 : arguments,
  _this = void 0;
function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }
function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }
function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }
function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }
var debounce = function debounce(callback, wait) {
  var context = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _this;
  var timeout = null;
  var callbackArgs = null;
  var later = function later() {
    return callback.apply(context, callbackArgs);
  };
  return function () {
    callbackArgs = _arguments;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};
exports.debounce = debounce;
var throttle = function throttle(callback, wait) {
  var context = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _this;
  var timeout = null;
  var callbackArgs = null;
  var later = function later() {
    callback.apply(context, callbackArgs);
    timeout = null;
  };
  return function () {
    if (!timeout) {
      callbackArgs = _arguments;
      timeout = setTimeout(later, wait);
    }
  };
};
exports.throttle = throttle;
var compose = function compose() {
  for (var _len = arguments.length, functions = new Array(_len), _key = 0; _key < _len; _key++) {
    functions[_key] = arguments[_key];
  }
  return function () {
    for (var _len2 = arguments.length, start = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
      start[_key2] = arguments[_key2];
    }
    return functions.reduce(function (result, f) {
      return _typeof(result) === 'object' ? f.apply(void 0, _toConsumableArray(result)) : f(result);
    }, start);
  };
};
exports.compose = compose;
var allPropsExist = function allPropsExist(object, props) {
  return props.reduce(function (acc, prop) {
    return acc && Object.prototype.hasOwnProperty.call(object, prop);
  }, true);
};
exports.allPropsExist = allPropsExist;
var allPropsNotEmpty = function allPropsNotEmpty(object, props) {
  return props.reduce(function (acc, prop) {
    return acc && (typeof object[prop] === 'boolean' || typeof object[prop] === 'number' || !!object[prop]);
  }, true);
};

/**
 * Shuffles array in place.
 * @param {Array} a items An array containing the items.
 */
exports.allPropsNotEmpty = allPropsNotEmpty;
var shuffle = function shuffle(a) {
  var j, x, i;
  for (i = a.length - 1; i > 0; i--) {
    j = Math.floor(Math.random() * (i + 1));
    x = a[i];
    a[i] = a[j];
    a[j] = x;
  }
  return a;
};
exports.shuffle = shuffle;
var rnd = function rnd(amt) {
  return Math.floor(Math.random() * amt);
};
exports.rnd = rnd;
var uuid = function uuid() {
  return _faker["default"].random.uuid().split('-')[0];
};
exports.uuid = uuid;
var padding = function padding(number) {
  var pad = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 2;
  var r = String(number);
  if (r.length < pad) {
    r = '00000000000'.substring(0, pad - r.length) + r;
  }
  return r;
};
exports.padding = padding;
var hours = function hours(input) {
  return Math.floor(input / 60);
};
exports.hours = hours;
var minutes = function minutes(input) {
  return padding(input % 60);
};
exports.minutes = minutes;
var asTime = function asTime(input) {
  return "".concat(hours(input), ":").concat(minutes(input));
};
exports.asTime = asTime;
var getRandomArbitrary = function getRandomArbitrary(min, max) {
  return Math.random() * (max - min) + min;
};
exports.getRandomArbitrary = getRandomArbitrary;
var rndBetween = function rndBetween(min, max) {
  return Math.floor(getRandomArbitrary(min, max));
};
exports.rndBetween = rndBetween;
var getRandomElement = function getRandomElement(arr) {
  return arr[Math.floor(getRandomArbitrary(0, arr.length))];
};
exports.getRandomElement = getRandomElement;
var shuffleArray = function shuffleArray(arr) {
  return arr.map(function (a) {
    return [Math.random(), a];
  }).sort(function (a, b) {
    return a[0] - b[0];
  }).map(function (a) {
    return a[1];
  });
};
exports.shuffleArray = shuffleArray;
var getRandomElements = function getRandomElements(arr, amount) {
  var skip = Math.floor(getRandomArbitrary(0, arr.length - amount * 3));
  return shuffleArray(arr.slice(skip, skip + amount * 3)).slice(0, amount);
};
exports.getRandomElements = getRandomElements;
var omitDeep = R.curry(function (keys, value) {
  if (R.type(keys) !== 'Array') {
    return value;
  }
  if (typeof value === 'undefined') {
    return {};
  }
  if (R.type(value) === 'Array') {
    return R.map(omitDeep(keys), value);
  }
  if (R.type(value) === 'Object') {
    return R.map(omitDeep(keys), R.omit(keys, value));
  }
  return value;
});

// Merge Base object with modifier object
// Main difference with  merge deep is that when you have a subprop that is an empty object
// merge deep will use the base object, but we would like the modifier

// example
// const leftObject = { profile: { avatar: { url: 'jens' } } };
// const rightObject = { profile: { avatar: {} } };

// mergeDeepRight returns
// { profile: { avatar: { url: 'jens' } } }
// mergeModifier  retur
// { profile: { avatar: {} } }
exports.omitDeep = omitDeep;
var mergeModifier = R.mergeWith(function (lObj, rObj) {
  if (R.type(lObj) === 'Object' && R.type(rObj) === 'Object') {
    if (R.keys(rObj).length > 0) {
      return mergeModifier(lObj, rObj);
    }
    return rObj;
  }
  return rObj;
});
exports.mergeModifier = mergeModifier;
var window = global;
var defaultImgOptions = {
  width: 1920,
  height: 1080,
  transform: 'fit',
  dpr: window && window.devicePixelRatio || 2
};

// console.log(defaultImgOptions);

var img = function img(url) {
  var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var o = _objectSpread(_objectSpread({}, defaultImgOptions), options);
  var width = o.width,
    height = o.height,
    transform = o.transform,
    dpr = o.dpr,
    crop = o.crop;
  var finalUrl = url;
  if (crop) {
    finalUrl = encodeURIComponent("//images.weserv.nl/?url=".concat(url, "&crop=").concat(crop));
  }
  return "//images.weserv.nl/?url=".concat(finalUrl).concat(width ? "&w=".concat(width) : '').concat(height ? "&h=".concat(height) : '', "&t=").concat(transform, "&dpr=").concat(dpr);
};
exports.img = img;