import React from 'react';

import { FabView } from '@solar/poc-ui';
import darkTheme from './style/darkTheme';

const FabViewDark = ({ appBarProps, ...props }) => (
  <FabView appBarProps={{ ...appBarProps, theme: darkTheme }} {...props} />
);

FabViewDark.propTypes = {};

export default FabViewDark;
