import React, { useState } from 'react';
import { useHistory } from 'react-router';
import queryString from 'query-string';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Switch,
  FormControlLabel,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { red, blue, lightGreen } from '@material-ui/core/colors';

import SubscribeData from '../../../SubscribeData';
import { usePublishedProductData, FabricateForm, useFabricateEdits } from '@probeton/ui';
import { certificationMapper } from '../Manufacturer/helpers';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      width: '90vw',
      maxWidth: 1024,
    },
    formControl: {
      width: '100%',
    },
  };
});

const useDialogStyles = makeStyles((theme) => ({
  root: ({ edits, changes, states, colors }) =>
    !!edits
      ? Object.keys(changes).reduce(
          (memo, key) => ({
            ...memo,
            [`& [id="#/properties/${key}"]`]: {
              border: `solid 2px ${colors[states[key]][500]}`,
              backgroundColor: colors[states[key]][50],
              padding: 8,
              marginBottom: 8,
              '& label': {
                top: 8,
                left: 8,
              },
            },
            // [`& [id^="#/properties/${key}-input"]`]: {
            //   backgroundColor: 'purple',
            // },
          }),
          {},
        )
      : {},
  switch: {
    position: 'absolute',
    top: 16,
    right: 24,
  },
}));

const FabricateTechDialogContent = ({ fabricate, edits }) => {
  const certificate = certificationMapper(fabricate.certification());

  const [_showEdits, setShowEdits] = useState(true);

  const showEdits = !!edits && _showEdits;

  const { product } = certificate;

  const { formJSON, formUIJSON } = usePublishedProductData(product, fabricate.productType);

  const { changes, states } = useFabricateEdits(fabricate.data, fabricate.edits);

  const classes = useDialogStyles({
    edits,
    changes,
    states,
    colors: { valid: lightGreen, invalid: red, new: blue },
  });

  return (
    <>
      <DialogTitle>{fabricate.code}</DialogTitle>
      {!!edits && (
        <div className={classes.switch}>
          <FormControlLabel
            control={
              <Switch
                checked={_showEdits}
                onChange={(event) => setShowEdits(event.target.checked)}
                name="checkedB"
                color="primary"
              />
            }
            label="Toon aanpassingen"
          />
        </div>
      )}
      <DialogContent classes={{ root: classes.root }}>
        <FabricateForm
          data={showEdits ? { ...fabricate.data, ...changes } : fabricate.data}
          formJSON={formJSON}
          formUIJSON={formUIJSON}
          readonly
        />
      </DialogContent>
    </>
  );
};

const FabricateTechDialogContainer = ({ id, ...other }) => {
  return (
    <SubscribeData>
      {({ products, helpers: { get, all } }) => {
        const fabricate = get(products, id);
        if (!fabricate) {
          return '404';
        }

        return <FabricateTechDialogContent fabricate={fabricate} {...other} />;
      }}
    </SubscribeData>
  );
};

const FabricateTechDialog = ({ open, onClose, ...other }) => {
  const classes = useStyles();
  const history = useHistory();
  const qs = queryString.parse(history.location.search);
  return (
    <Dialog open={open} onClose={onClose} classes={{ paper: classes.root }}>
      {open ? <FabricateTechDialogContainer id={qs.fabricateId} {...other} /> : ''}
    </Dialog>
  );
};

export const FabricateTechEditsDialog = (props) => {
  return <FabricateTechDialog {...props} edits />;
};

export default FabricateTechDialog;
