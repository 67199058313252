import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useEditable } from '@solar/poc-ui';

import SubscribeData from '../../../SubscribeData';
import { certificateMapper } from '../Manufacturer/helpers';
import { ProductDataEditor as CommonProductDataEditor } from '@probeton/ui';

const ProductDataEdit = ({ product, id, onSave, ...props }) => {
  const [isDirty, setEdits, merged, cancelEdits, edits] = useEditable(
    ['formJSON', 'formUIJSON', 'formData'],
    product,
    id,
  );

  // TODO: fix useEditable to correctly update edits
  const [editsDuped, setEditsDuped] = useState({});
  const _setEdits = (e) => {
    setEdits(e);
    setEditsDuped({ ...editsDuped, ...e });
  };

  const { formJSON, formUIJSON, formData } = merged;
  const { title, types } = product;

  return (
    <CommonProductDataEditor
      title={title}
      types={types}
      formJSON={formJSON}
      formUIJSON={formUIJSON}
      formData={formData}
      labels={{ search: 'zoeken' }}
      onChangeFormJSON={(update, label) => {
        _setEdits({ formJSON: { ...formJSON, [label]: update } });
      }}
      onChangeFormUIJSON={(update, label) => {
        _setEdits({ formUIJSON: { ...formUIJSON, [label]: update } });
      }}
      onChangeFormData={(update, label) => {
        _setEdits({ formData: { ...formData, [label]: update } });
      }}
      onRestore={() => {
        cancelEdits();
        setEditsDuped({});
      }}
      onSave={() => {
        console.log(editsDuped);
        onSave(editsDuped);
      }}
      isDirty={isDirty}
      {...props}
    />
  );
};

const ProductDataEditor = ({ id, ...props }) => {
  return (
    <SubscribeData>
      {({ certificates, editCertificate, helpers: { get } }) => {
        const c = get(certificates, id);
        const product = certificateMapper(c);

        return (
          <ProductDataEdit
            product={product}
            id={id}
            {...props}
            onSave={(edits) => editCertificate(c, edits)}
          />
        );
      }}
    </SubscribeData>
  );
};

ProductDataEditor.propTypes = {
  openCertificate: PropTypes.func,
};

export default ProductDataEditor;
