// TODO: port to ui lib

import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';

import { FabricateForm } from '@probeton/ui';

import { Flex, FileListItem, Header } from '@solar/solar-react';

import {
  List,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from '@material-ui/core';

import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      // maxWidth: 345,
    },
    sheet: {
      margin: 12,
      marginLeft: 24,
      marginRight: 24,
      padding: 12,
    },
    removeButtonWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      flexGrow: 1,
    },
    removeButton: {
      width: 280,
    },
  };
});

const FabricateEdit = ({ fabricate, formJSON, formUIJSON, onChange, onDuplicate, ...other }) => {
  const classes = useStyles();
  const [confirmRemove, setConfirmRemove] = useState(false);
  return (
    <Flex fullColumn className={classes.root} {...other}>
      <Header>Fabrikaat Informatie</Header>
      <FabricateForm
        data={fabricate.data}
        formJSON={formJSON}
        formUIJSON={formUIJSON}
        onChange={onChange}
        classes={{ root: classes.sheet }}
      />
      <Header>Afbeeldingen</Header>
      <List>
        <FileListItem interactive label="Omslag" fileUrl={fabricate.coverSrc} isImage />
        {fabricate.images.map(({ src, label }) => (
          <FileListItem interactive label={label} fileUrl={src} isImage />
        ))}
      </List>
      <Header>Documenten</Header>
      <List>
        <FileListItem
          interactive
          label="Technische fiche"
          fileUrl="http://www.pdf995.com/samples/RFP.pdf"
        />
        <FileListItem
          interactive
          label="Certificaat"
          fileUrl="http://www.pdf995.com/samples/dpa.pdf"
        />
      </List>
      <div className={clsx(classes.removeButtonWrapper, classes.sheet)}>
        <Button
          variant="contained"
          color="primary"
          className={classes.removeButton}
          onClick={() => onDuplicate()}
        >
          Duplicate
          <AddIcon className={classes.rightIcon} />
        </Button>
        <Button
          variant="contained"
          color="secondary"
          className={classes.removeButton}
          onClick={() => setConfirmRemove(true)}
        >
          Delete
          <DeleteIcon className={classes.rightIcon} />
        </Button>
      </div>
      <Dialog open={confirmRemove} onClose={() => setConfirmRemove(false)}>
        <DialogTitle>Ben je zeker?</DialogTitle>
        <DialogContent>
          <DialogContentText>Dit kan niet ongedaan gemaakt worden</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setConfirmRemove(false);
            }}
          >
            annuleren
          </Button>
          <Button
            onClick={() => {
              setConfirmRemove(false);
            }}
            color="secondary"
          >
            Ja
          </Button>
        </DialogActions>
      </Dialog>
    </Flex>
  );
};

FabricateEdit.propTypes = {
  fabricate: PropTypes.object.isRequired,
};

FabricateEdit.defaulProps = {};

export default FabricateEdit;
