import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { List } from '@material-ui/core';

import { ProfileItem } from '@probeton/ui';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      // maxWidth: 345,
    },
    fab: {
      position: 'absolute',
      bottom: theme.spacing.unit * 2,
      right: theme.spacing.unit * 2,
    },
  };
});

const FabricateList = ({ fabricates, openFabricate, ...other }) => {
  const classes = useStyles();

  return (
    <Fragment>
      <List>
        {fabricates.map(
          ({ name, code, _id, description, status }) =>
            !status && (
              <ProfileItem
                textLabel={name}
                text={code}
                interactive
                onClick={() => openFabricate(_id)}
              />
            ),
        )}
      </List>
    </Fragment>
  );
};

export default FabricateList;
