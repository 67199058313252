"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.users = exports.default = void 0;
const users = {
  psoudan: {
    _id: 'psoudan',
    email: 'pieter@lemon.be',
    username: 'psoudan',
    person: {
      firstName: 'Pieter',
      lastName: 'Soudan',
      company: 'Lemon',
      fullName: 'Pieter Soudan'
    },
    role: 'Gebruiker',
    auth: ['customer'],
    avatarUrl: 'https://pbs.twimg.com/profile_images/439750314309083136/sM8jV45c.jpeg'
  },
  jhorckmans: {
    _id: 'jhorckmans',
    email: 'johan.horckmans@probeton.be',
    username: 'jhorckmans',
    person: {
      fullName: 'Johan Horckmans'
    },
    role: 'Admin',
    auth: ['probeton'],
    avatarUrl: '/images/probeton-johan-horckmans.png'
  },
  mmammerickx: {
    _id: 'mmammerickx',
    email: 'mireille.mammerickx@probeton.be',
    username: 'mmammerickx',
    person: {
      fullName: 'Mireille Mammerickx'
    },
    role: 'Admin',
    auth: ['probeton'],
    avatarUrl: '/images/probeton-mireille-mammerickx.png'
  },
  alana: {
    _id: 'alana',
    email: 'alana.vaneijkelenburg@probeton.be',
    username: 'Alana',
    person: {
      fullName: 'Alana van Eijkelenburg'
    },
    role: 'Admin',
    auth: ['probeton'],
    avatarUrl: 'http://www.probeton.be/uploads/images/contentbeelden/wieiswie/probeton-alana-vaneijkelenburg.png'
  },
  inspector: {
    _id: 'inspector',
    email: 'inspector@probeton.be',
    username: 'inspector',
    person: {
      fullName: 'Ine Spector'
    },
    role: 'Inspector',
    auth: ['inspector'],
    inspector: 'Inspector organisatie nv',
    avatarUrl: 'https://s3.amazonaws.com/uifaces/faces/twitter/weavermedia/128.jpg'
  },
  rep: {
    _id: 'rep',
    email: 'rep@probeton.be',
    username: 'representative',
    person: {
      fullName: 'Rep'
    },
    role: 'Representative',
    auth: ['representative'],
    avatarUrl: 'https://s3.amazonaws.com/uifaces/faces/twitter/bobbytwoshoes/128.jpg'
  }
};
exports.users = users;

var _default = Object.values(users);

exports.default = _default;