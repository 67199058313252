import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';

import { FileListItem, AlignRight } from '@solar/solar-react';

import { List, Button } from '@material-ui/core';

import ACLHeader from './ACLHeader';

const useStyles = makeStyles((theme) => {
  return {
    root: {},
    avatar: {
      backgroundColor: theme.palette.primary.main,
    },
    media: {
      paddingTop: '50%',
    },
    field: {
      margin: 12,
      marginLeft: 24,
      marginRight: 24,
    },
    btn: {
      margin: 12,
    },
  };
});

const CertificateDocuments = ({ certification, acl, onChangeACL, setImageOpen, setPdfOpen }) => {
  const classes = useStyles();

  const { certificate: c, technicalSheet } = certification;
  const certificate = c();
  return (
    <Fragment>
      <ACLHeader acl={acl.images} onChange={onChangeACL('images')}>
        Afbeeldingen
      </ACLHeader>
      <List>
        <FileListItem
          interactive
          label="Omslag"
          fileUrl={certification.coverSrc}
          isImage
          onClickOpen={() => setImageOpen(certification.coverSrc)}
        />
        {(certification.images || []).map(({ src, label }) => (
          <FileListItem
            interactive
            label={label}
            fileUrl={src}
            isImage
            onClickOpen={() => setImageOpen(src)}
          />
        ))}
      </List>
      <AlignRight>
        <Button variant="outlined" color="primary" className={classes.btn}>
          Beheer afbeeldingen
        </Button>
      </AlignRight>
      <ACLHeader acl={acl.documents} onChange={onChangeACL('documents')}>
        Documenten
      </ACLHeader>
      <List>
        <FileListItem
          interactive
          label="Certificaat"
          fileUrl="http://www.pdf995.com/samples/dpa.pdf"
        />
      </List>
      {(certification.documents || []).map(({ src, label }) => (
        <FileListItem interactive label={label} fileUrl={src} />
      ))}
      <AlignRight>
        <Button variant="outlined" color="primary" className={classes.btn}>
          Beheer documenten
        </Button>
      </AlignRight>
    </Fragment>
  );
};

CertificateDocuments.propTypes = {
  className: PropTypes.string,
  certificate: PropTypes.object.isRequired,
};

CertificateDocuments.defaultProps = {
  className: '',
};

export default CertificateDocuments;
