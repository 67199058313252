"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "FormEditor", {
  enumerable: true,
  get: function get() {
    return _FormEditor["default"];
  }
});

var _FormEditor = _interopRequireDefault(require("./FormEditor"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }