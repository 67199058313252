import React from 'react';
import PropTypes from 'prop-types';

import { NavigationDrawer } from '@probeton/ui';

import DarkMuiThemeProvider from './DarkMuiThemeProvider';

import { navGroups as commonNavGroups } from './CommonNav';

const navGroups = () => [{ links: [...commonNavGroups] }];

const AppNavigationDrawer = ({ ...props }) => (
  <NavigationDrawer
    {...props}
    navGroups={navGroups()}
    WrapperComponent={DarkMuiThemeProvider}
    app="Customer"
  />
);

AppNavigationDrawer.propTypes = {
  onClickLogout: PropTypes.func.isRequired,
  onDrawerRequestClose: PropTypes.func.isRequired,
  onDrawerRequestOpen: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  profile: PropTypes.object,
};

AppNavigationDrawer.defaultProps = {
  profile: null,
};

export default AppNavigationDrawer;
