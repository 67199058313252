import React from 'react';

import { Route, Redirect, Switch } from 'react-router-dom';

import sheets from '../../sheets';
import dialogs from '../../dialogs';

import { App } from '@probeton/ui';

import ProfileInfo from './ProfileInfo';
import Search from './Search/Search';

const PublicApp = ({ history, user, ...other }) => (
  <App
    history={history}
    user={user}
    app="PUBLIC"
    sheets={sheets}
    dialogs={dialogs}
    profileInfo={ProfileInfo}
    labels={{
      imageUpload: {
        actions: {},
        photoUpload: {},
      },
    }}
    {...other}
  >
    {({ handleDetailViewToggle, handleDialog }) => (
      <Switch>
        <Route path="/" exact render={() => <Redirect to="/search" />} />
        <Route
          path="/search"
          exact
          render={(props) => (
            <Search
              history={history}
              openProduct={(id) => handleDetailViewToggle('fabricate', id)}
              user={user}
              {...props}
            />
          )}
        />
      </Switch>
    )}
  </App>
);

// prettier-ignore
export default PublicApp;
