"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.tester = exports["default"] = void 0;

var _react = require("@jsonforms/react");

var _core = require("@jsonforms/core");

var tester = (0, _core.rankWith)(3, (0, _core.or)(_core.isNumberControl, _core.isIntegerControl, _core.isBooleanControl, _core.isEnumControl, _core.isOneOfEnumControl, _core.isPlainLabel, _core.isDateControl, _core.isDateTimeControl, _core.isTimeControl, (0, _core.scopeEndsWith)('code')));
exports.tester = tester;

var _default = (0, _react.withJsonFormsControlProps)(function () {
  return '';
});

exports["default"] = _default;