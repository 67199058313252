import React from 'react';
import { format } from 'date-fns';

import { List, Avatar } from '@material-ui/core';
import NewIcon from '@material-ui/icons/FiberNew';
import { users } from '@probeton/mockdata';
import { CertificateListItem, ProfileItem } from '@probeton/ui';
import { CoverTabView } from '@solar/poc-ui';

import SubscribeData from '../../../SubscribeData';
import { personMapper, productMapper, aclRequestMapper } from '../Manufacturer/helpers';

const ContactSheet = ({ id, history, handleDetailViewToggle }) => {
  return (
    <SubscribeData>
      {({ products, persons, favourites, aclRequests, helpers: { all, get } }) => {
        const person = personMapper(get(persons, id) || users.find(({ _id }) => _id === id));
        const favouriteProductIds = get(favourites, person.username) || [];
        const favouriteProducts = favouriteProductIds.map((productId) =>
          productMapper(get(products, productId)),
        );
        const userAclRequests = all(aclRequests)
          .map(aclRequestMapper)
          .filter(({ userId }) => userId === person._id);

        return (
          <CoverTabView
            title={person.fullName}
            cover={person.avatar.src}
            tabs={{
              favoriteRequests: {
                label: 'Favorieten',
                component: (
                  <List>
                    {favouriteProducts.map(({ _id, certificate, manufacturer }) => (
                      <CertificateListItem
                        key={_id}
                        certification={certificate}
                        manufacturer={manufacturer}
                        openCertificate={(certificateId) =>
                          history.push(`/certificates/${certificateId}`)
                        }
                        hideOwner
                      />
                    ))}
                  </List>
                ),
              },
              certificateRequests: {
                label: 'Aanvragen',
                component: (
                  <List>
                    {userAclRequests.map(({ _id, grants, granter, createdAt, certificate }) => {
                      const { product } = certificate;
                      return (
                        <ProfileItem
                          onClick={(id) => handleDetailViewToggle('aclRequest', _id)}
                          textLabel={`${product.title} - ${product.renderCodes()}`}
                          {...(grants
                            ? {
                                icon: <Avatar src={granter.avatarUrl} />,
                                text: `${grants.granted ? 'goedgekeurd' : 'afgekeurd'} op ${format(
                                  new Date(grants.grantedAt),
                                  'yyyy.MM.dd',
                                )}`,
                              }
                            : {
                                icon: <NewIcon />,
                                text: `aangevraagd op ${format(new Date(createdAt), 'yyyy.MM.dd')}`,
                              })}
                        />
                      );
                    })}
                  </List>
                ),
              },
            }}
          />
        );
      }}
    </SubscribeData>
  );
};

export default ContactSheet;
