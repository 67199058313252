import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import { memoizeWith, identity } from 'ramda';
import Fuse from 'fuse.js';

import Typography from '@material-ui/core/Typography';

import { Flex, ScrollableContent, EmptyState } from '@solar/solar-react';

import CustomerIcon from '@material-ui/icons/Face';

import { ResponsiveSearchField } from '@probeton/solar-react';

import SubscribeData from '../../../SubscribeData';
import { users } from '@probeton/mockdata';

import FabView from '../FabView';

import { ContactList } from '@probeton/ui';

const CustomerSearch = ({
  customers,
  onCreateContact,
  openCustomer,
  title = 'Klanten',
  ...other
}) => {
  const fuseSearchMemoized = useMemo(() => {
    const options = {
      keys: ['fullName', 'email', 'company'],
      shouldSort: true,
      threshold: 0.3,
    };
    const fuse = new Fuse(customers, options);
    return memoizeWith(identity, (search) => fuse.search(search));
  }, [customers.length]);

  const [search, setSearch] = useState('');
  const filteredCustomers = search === '' ? customers : fuseSearchMemoized(search);

  return (
    <FabView
      title={
        <Flex alignItems="center" justifyContent="space-between" flexGrow={1}>
          <Typography variant="headline" color="inherit" noWrap>
            {title}
          </Typography>
          <ResponsiveSearchField
            InputProps={{
              id: 'searchCustomers',
            }}
            placeholder="Zoeken"
            value={search}
            onChange={(value) => setSearch(value)}
          />
        </Flex>
      }
      titleOnTop
      disableShadow
    >
      {customers.length === 0 ? (
        <EmptyState Icon={CustomerIcon} label="Er hebben zich nog geen klanten aangemeld." />
      ) : (
        <ScrollableContent>
          <ContactList
            contacts={filteredCustomers.map(({ company, ...other }) => ({
              subheader: company,
              ...other,
            }))}
            handleContactClick={openCustomer}
            {...other}
          />
        </ScrollableContent>
      )}
    </FabView>
  );
};

const CustomerView = ({ openCustomer, ...props }) => {
  return (
    <SubscribeData>
      {({ shares, helpers: { all } }) => {
        // TODO: filter by reps units
        const customerIds = all(shares).map(({ userId, unitId }) => userId);
        const customers = users.filter(
          ({ _id, role }) => role === 'Gebruiker' && customerIds.includes(_id),
        );

        return <CustomerSearch customers={customers} openCustomer={openCustomer} {...props} />;
      }}
    </SubscribeData>
  );
};

CustomerView.propTypes = {
  openCustomer: PropTypes.func,
};

export default CustomerView;
