import React, { useState, Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { IconButton } from '@material-ui/core';

import AddIcon from '@material-ui/icons/AddCircle';
import EmojiIcon from '@material-ui/icons/SentimentVerySatisfied';
import SendIcon from '@material-ui/icons/Send';

import { Flex, ChatToolbar, ChatInput } from '@solar/solar-react';

import { Conversation, ChatOutput } from '@probeton/solar-react';

import TicketActions from './TicketActions';

const useStyles = makeStyles((theme) => {
  return {
    padding: {
      padding: 24,
    },
    output: {
      padding: '12px 12px 0 12px',
    },
  };
});

const TicketChat = ({ ticket, componentMap, actionProps, user, onAddMessage, ...props }) => {
  const classes = useStyles();
  const [msg, setMessage] = useState();
  const c = ticket.conversation();
  return (
    <Flex fullColumn classes={{ root: classes.root }} {...props}>
      <ChatOutput classes={{ output: classes.output }}>
        <Conversation messages={c.messages} user={user._id} componentMap={componentMap} />
      </ChatOutput>
      <TicketActions user={user} ticket={ticket} {...actionProps} />
      <ChatToolbar>
        <IconButton>
          <AddIcon color="primary" />
        </IconButton>
        <ChatInput
          placeholder="Type een bericht..."
          adornment={
            <IconButton>
              <EmojiIcon color="primary" />
            </IconButton>
          }
          onChange={(event, v) => {
            setMessage(event.target.value);
          }}
        />
        <IconButton>
          <SendIcon
            color="primary"
            onClick={() => {
              onAddMessage(msg);
              setMessage('');
            }}
          />
        </IconButton>
      </ChatToolbar>
    </Flex>
  );
};

export default TicketChat;
