"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _googleGeocoder = _interopRequireDefault(require("google-geocoder"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const KEY = 'AIzaSyD22tu03W7ov8a3X9L8NBPaeY2lDZ26cfQ';
const options = {
  key: KEY // for Mapquest, OpenCage, Google Premier

};
const geo = (0, _googleGeocoder.default)(options);

const promiseFn = location => new Promise((resolve, reject) => {
  geo.find(location, (err, data) => {
    if (err) {
      reject(err);
    }

    resolve(data);
  });
});

var _default = {
  geocode: promiseFn
};
exports.default = _default;