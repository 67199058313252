import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { Header, Typo } from '@solar/solar-react';

const useStyles = makeStyles((theme) => {
  return {
    padding: {
      padding: 24,
    },
  };
});

const EmployeeInfo = ({ employee: { title, category, description, tickets: t } }) => {
  const classes = useStyles();
  const tickets = t();
  return (
    <Fragment>
      <Typo variant="title" className={classes.padding}>
        {category().title}
      </Typo>
      <Header>Stats</Header>
    </Fragment>
  );
};

export default EmployeeInfo;
