"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _faker = _interopRequireDefault(require("faker"));

var _store = _interopRequireDefault(require("store2"));

var _users = _interopRequireDefault(require("./users"));

var _helpers = require("@probeton/helpers");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const createACLRequest = (certification, createdBy) => {
  const createdAt = new Date();
  return {
    aclRequest: {
      _id: (0, _helpers.uuid)(),
      certificationId: certification._id,
      userId: createdBy._id,
      createdAt
    }
  };
};

const grantRequest = (aclRequest, access, grantedBy) => {
  const editedAt = new Date(); // console.log('edit product', { product, edits, editedBy });

  return {
    aclRequest: { ...aclRequest,
      grants: {
        granted: access,
        grantedBy: grantedBy._id,
        grantedAt: editedAt
      }
    }
  };
};

const createDownload = (certification, document, createdBy) => {
  const createdAt = new Date();
  return {
    download: {
      _id: (0, _helpers.uuid)(),
      certificationId: certification._id,
      userId: createdBy._id,
      document,
      createdAt,
      lastDownloadedAt: createdAt
    }
  };
};

const updateDownload = download => {
  const editedAt = new Date();
  return {
    download: { ...download,
      lastDownloadedAt: editedAt
    }
  };
};

const createShare = (certification, createdBy) => {
  const createdAt = new Date();
  return {
    share: {
      _id: (0, _helpers.uuid)(),
      unitId: certification.unitId,
      userId: createdBy._id,
      createdAt
    }
  };
};

var _default = {
  generators: {
    favourite: async () => [],
    aclRequest: async () => [],
    download: async () => [],
    share: async () => []
  },
  mutations: {
    favourite: stores => ({
      set: (productId, favourite, forUser) => {
        const collection = (0, _store.default)(`probeton.favourite`);
        const current = collection[forUser] || [];
        const newFavourites = favourite ? [...current, productId] : current.filter(f => f !== productId);
        (0, _store.default)(`probeton.favourite`, { ...collection,
          [forUser]: newFavourites
        });
        return { ...stores[`favourites`],
          [forUser]: newFavourites
        };
      }
    }),
    aclRequest: stores => ({
      create: (certification, createdBy) => {
        const {
          aclRequest: newlyCreatedAclRequest
        } = createACLRequest(certification, createdBy);
        stores().crud.create('aclRequest')(newlyCreatedAclRequest);
        return newlyCreatedAclRequest;
      },
      grant: (aclRequest, access, grantedBy) => {
        const {
          aclRequest: updatedAclRequest
        } = grantRequest(aclRequest, access, grantedBy);
        stores().crud.update('aclRequest')(updatedAclRequest);
        return updatedAclRequest;
      }
    }),
    download: stores => ({
      create: (certification, document, createdBy) => {
        const {
          download: newlyCreatedDownload
        } = createDownload(certification, document, createdBy);
        stores().crud.create('download')(newlyCreatedDownload);
        return newlyCreatedDownload;
      },
      update: download => {
        const {
          download: updatedDownload
        } = updateDownload(download);
        stores().crud.update('download')(updatedDownload);
        return updatedDownload;
      }
    }),
    share: stores => ({
      create: (certification, createdBy) => {
        const {
          share: newlyCreatedShare
        } = createShare(certification, createdBy);
        stores().crud.create('share')(newlyCreatedShare);
        return newlyCreatedShare;
      }
    })
  },
  helpers: {
    aclRequest: (aclRequest, stores) => ({
      author: () => _users.default.find(({
        _id
      }) => _id === aclRequest.userId),
      granter: () => aclRequest.grants && _users.default.find(({
        _id
      }) => _id === aclRequest.grants.grantedBy),
      certification: () => stores().certifications[aclRequest.certificationId]
    }),
    download: (download, stores) => ({
      author: () => _users.default.find(({
        _id
      }) => _id === download.userId),
      certification: () => stores().certifications[download.certificationId]
    }),
    share: (share, stores) => ({
      author: () => _users.default.find(({
        _id
      }) => _id === share.userId),
      unit: () => stores().units[share.certificationId]
    })
  }
};
exports.default = _default;