import React from 'react';
import PropTypes from 'prop-types';

import { ScrollableContent, ResponsiveSheet } from '@solar/solar-react';

import FabView from './FabView';

import { ProfileItem } from '@probeton/ui';
import { List } from '@material-ui/core';

const ProfileView = ({ user, ...other }) => (
  <FabView title={'Mijn profiel'} titleOnTop disableShadow>
    <ScrollableContent>
      <ResponsiveSheet>
        <List>
          <ProfileItem textLabel={`voornaam`} text={user.firstName} />
          <ProfileItem textLabel={`achternaam`} text={user.lastName} />
          <ProfileItem textLabel={`bedrijf`} text={user.company} />
          <ProfileItem textLabel={`e-mail`} text={user.email} />
          <ProfileItem textLabel={`rol`} text={user.role} />
        </List>
      </ResponsiveSheet>
    </ScrollableContent>
  </FabView>
);

ProfileView.propTypes = {
  handleContactClick: PropTypes.func,
};

export default ProfileView;
