import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { format } from 'date-fns';
import { List, Avatar } from '@material-ui/core';
import { ScrollableContent, CardList, EmptyState } from '@solar/solar-react';

import NewIcon from '@material-ui/icons/FiberNew';
import RequestIcon from '@material-ui/icons/LockOpen';

import SubscribeData from '../../../SubscribeData';
import FabView from '../FabView';

import { ProfileItem, CertificateCard } from '@probeton/ui';

const ACLRequestView = ({ user, openProduct, ...other }) => {
  return (
    <SubscribeData>
      {({ aclRequests, helpers: { all } }) => {
        const myACLRequests = all(aclRequests).filter(({ userId }) => userId === user._id);
        return (
          <FabView title={'Mijn aanvragen'} titleOnTop disableShadow>
            {myACLRequests.length > 0 ? (
              <ScrollableContent>
                <CardList items={R.sortBy(R.prop('number'))(myACLRequests)} {...other}>
                  {({ _id, createdAt, certificationId, certification, grants, granter }) => {
                    const c = certification();
                    return (
                      <CertificateCard
                        certification={c}
                        onClick={() => openProduct(c.products()[0]._id)}
                        noCertificate
                      >
                        <List>
                          <ProfileItem
                            {...(grants
                              ? {
                                  icon: <Avatar src={granter().avatarUrl} />,
                                  text: `${
                                    grants.granted ? 'goedgekeurd' : 'afgekeurd'
                                  } op ${format(new Date(grants.grantedAt), 'yyyy.MM.dd')}`,
                                }
                              : {
                                  icon: <NewIcon />,
                                  text: `aangevraagd op ${format(
                                    new Date(createdAt),
                                    'yyyy.MM.dd',
                                  )}`,
                                })}
                          />
                        </List>
                      </CertificateCard>
                    );
                  }}
                </CardList>
              </ScrollableContent>
            ) : (
              <EmptyState Icon={RequestIcon} label="Je hebt nog geen bestanden gedownload." />
            )}
          </FabView>
        );
      }}
    </SubscribeData>
  );
};

ACLRequestView.propTypes = {
  openACLRequest: PropTypes.func,
};

export default ACLRequestView;
