"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.domains = exports.categories = void 0;
const domains = [{
  _id: 'infra',
  title: 'infrastructuurwerken'
}, {
  _id: 'gebouwen',
  title: 'kleine producten voor gebouwen'
}, {
  _id: 'bouwelementen',
  title: 'Bouwelementen'
}];
exports.domains = domains;
const categories = [{
  _id: 'leidingen',
  title: 'Leidingen',
  domainId: 'infra'
}, {
  _id: 'wegenis',
  title: 'Wegenis',
  domainId: 'infra'
}, {
  _id: 'waterzuivering',
  title: 'Producten voor waterzuivering en–opvang',
  domainId: 'infra'
}, {
  _id: 'weguitrustingen',
  title: 'Producten voor weguitrustingen',
  domainId: 'infra'
}, {
  _id: 'diversen-infra',
  title: 'Diversen',
  domainId: 'infra'
}, {
  _id: 'dakpannen',
  title: 'Dakpannen',
  domainId: 'gebouwen'
}, {
  _id: 'metselstenen',
  title: 'Metselstenen',
  domainId: 'gebouwen'
}, {
  _id: 'structuurelementen',
  title: 'Structuurelementen',
  domainId: 'bouwelementen'
}, {
  _id: 'diversen-bouwelementen',
  title: 'Diversen',
  domainId: 'bouwelementen'
}];
exports.categories = categories;