import React from 'react';
import PropTypes from 'prop-types';

import SubscribeData from '../../../SubscribeData';

import FabricateInfo from './FabricateInfo';

const FabricateSheet = ({
  currentUser,
  id,
  handleDetailViewToggle,
  openDialog,
  setPdfOpen,
  setImageOpen,
}) => {
  return (
    <SubscribeData>
      {({ products, favourites, setFavourite, helpers: { get } }) => {
        const fabricate = get(products, id);

        if (!fabricate) {
          return '404';
        }

        return (
          <FabricateInfo
            user={currentUser}
            fabricate={{
              ...fabricate,
              ...fabricate.data,
              data: { name: fabricate.name, code: fabricate.code, ...fabricate.data },
            }}
            isFavourite={
              currentUser &&
              favourites &&
              favourites[currentUser._id] &&
              favourites[currentUser._id].includes(id)
            }
            isEditable={
              currentUser &&
              currentUser.auth &&
              currentUser.auth.length > 0 &&
              currentUser.auth.includes('probeton')
            }
            isFavourable
            onClickFavorite={(fav) => setFavourite(id, fav, currentUser._id)}
            openFabricate={(_id) => handleDetailViewToggle('fabricate', _id)}
            openFabricateTechSheet={(_id) =>
              openDialog('fabricateTechSheet', null, { propsForUrl: { fabricateId: _id } })
            }
            editFabricate={(_id) =>
              openDialog('editFabricate', null, { propsForUrl: { fabricateId: _id } })
            }
            setPdfOpen={setPdfOpen}
            setImageOpen={setImageOpen}
          />
        );
      }}
    </SubscribeData>
  );
};

FabricateSheet.propTypes = {
  history: PropTypes.object,
  user: PropTypes.object,
  id: PropTypes.string,
};

export default FabricateSheet;
