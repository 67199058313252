import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';

import PublicIcon from '@material-ui/icons/LockOpen';
import ProtectedIcon from '@material-ui/icons/Lock';
import OnRequestIcon from '@material-ui/icons/EnhancedEncryption';
import PrivateIcon from '@material-ui/icons/Block';

import { Typography, IconButton, Menu, MenuItem } from '@material-ui/core';

const options = {
  PUBLIC: { label: 'publiek' },
  REGISTERED: { label: 'enkel wanneer geregistreerd' },
  ON_REQUEST: { label: 'enkel op aanvraag' },
  PRIVATE: { label: 'enkel voor medewerkers' },
};

const useStyles = makeStyles((theme) => {
  return {
    root: {
      borderTop: `solid 1px ${theme.palette.divider}`,
      marginTop: 0,
      padding: 4,
      paddingLeft: 24,
      paddingRight: 4,
      backgroundColor: grey[200],
      display: 'flex',
    },
  };
});

const ACLHeader = ({ acl, onChange, children, ...props }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(false);
  const menuOpen = Boolean(anchorEl);
  const Icon =
    acl === 'PUBLIC'
      ? PublicIcon
      : acl === 'ON_REQUEST'
      ? OnRequestIcon
      : acl === 'PRIVATE'
      ? PrivateIcon
      : ProtectedIcon;
  return (
    <div className={classes.root} {...props}>
      <Typography variant="h6" color="inherit">
        {children}
      </Typography>
      <div style={{ flexGrow: 2 }} />
      <IconButton
        aria-label="acl"
        aria-controls="acl-menu"
        aria-haspopup="true"
        onClick={(event) => setAnchorEl(event.currentTarget)}
      >
        <Icon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={menuOpen}
        onClose={() => setAnchorEl(null)}
      >
        {Object.keys(options).map((option) => (
          <MenuItem
            key={option}
            selected={option === acl}
            onClick={() => {
              setAnchorEl(null);
              onChange(option);
            }}
          >
            {options[option].label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default ACLHeader;
