"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _geocoder = _interopRequireDefault(require("./geocoder"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var _default = store => async location => {
  let locations = await store('google-geo');

  if (!locations) {
    locations = {};
  }

  if (!locations[location]) {
    const geocoded = await _geocoder.default.geocode(location);

    if (geocoded && geocoded[0]) {
      locations = { ...locations,
        [location]: geocoded[0]
      };
      await store('google-geo', locations);
    }
  }

  return locations[location];
};

exports.default = _default;