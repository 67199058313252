"use strict";

/*
  eslint no-extend-native: ["error", { "exceptions": ["String"] }]
*/
var additionalStringProps = {
  capitalizeFirstLetter: {
    value: function capitalizeFirstLetter() {
      return this.charAt(0).toUpperCase() + this.slice(1).toLowerCase();
    }
  },
  toTitleCase: {
    value: function toTitleCase() {
      var excluded = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      return this.split(' ').map(
      // eslint-disable-next-line no-confusing-arrow
      function (word) {
        return excluded.includes(word) ? word : word.toLowerCase().charAt(0).toUpperCase() + word.toLowerCase().slice(1);
      }).join(' ');
    }
  },
  trunc: {
    value: function trunc() {
      var n = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 50;
      var delimiter = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '&hellip;';
      var useWordBoundary = arguments.length > 2 ? arguments[2] : undefined;
      var isTooLong = this.length > n;
      var s_ = isTooLong ? this.substr(0, n - 1) : this;
      s_ = useWordBoundary && isTooLong ? s_.substr(0, s_.lastIndexOf(' ')) : s_;
      return isTooLong ? s_ + delimiter : s_;
    }
  },
  firstCharacter: {
    value: function firstCharacter() {
      return this.trim().charAt(0);
    }
  }
};
Object.defineProperties(String.prototype, additionalStringProps);