import React from 'react';
import PropTypes from 'prop-types';

import SubscribeData from '../../../SubscribeData';

import { ManufacturerSheet as CommonManufacturerSheet } from '@probeton/ui';

import { mapper } from './helpers';

const ManufacturerSheet = ({ id, handleDetailViewToggle, ...other }) => {
  return (
    <SubscribeData>
      {({ manufacturers, helpers: { get } }) => {
        return (
          <CommonManufacturerSheet
            manufacturer={mapper(get(manufacturers, id))}
            handleContactClick={(contactId) => handleDetailViewToggle('contact', contactId)}
            handleUnitClick={(unitId) => handleDetailViewToggle('unit', unitId)}
            handleDetailViewToggle={handleDetailViewToggle}
            {...other}
          />
        );
      }}
    </SubscribeData>
  );
};

ManufacturerSheet.propTypes = {
  history: PropTypes.object,
  user: PropTypes.object,
  id: PropTypes.string,
};

export default ManufacturerSheet;
