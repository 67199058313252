"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getResizedImage = getResizedImage;
exports.isOfTypeImage = isOfTypeImage;

function isOfTypeImage(type) {
  if (!type) {
    return false;
  }

  return type === 'image/jpeg' || type === 'image/png' || type.indexOf('.jpg') > -1 || type.indexOf('.jpg') > -1 || type.indexOf('.jpeg') > -1 || type.indexOf('.png') > -1 || type.indexOf('.gif') > -1;
}

function getResizedImage(url, query) {
  var newUrl = url.replace(/https?:\/\//i, '');
  return "//images.weserv.nl/?url=".concat(newUrl, "&").concat(query);
}