import React from 'react';
import PropTypes from 'prop-types';

import { ScrollableContent, EmptyState } from '@solar/solar-react';
import { ResponsiveSheet } from '@probeton/solar-react';

import DownloadIcon from '@material-ui/icons/GetApp';

import SubscribeData from '../../../SubscribeData';
import FabView from '../FabView';

import DownloadList from './DownloadList';

const DownloadView = ({ user, ...other }) => {
  return (
    <SubscribeData>
      {({ downloads, helpers: { all } }) => {
        const myDownloads = all(downloads).filter(({ userId }) => userId === user._id);
        return (
          <FabView title={'Mijn downloads'} titleOnTop disableShadow>
            {myDownloads.length > 0 ? (
              <ScrollableContent>
                <ResponsiveSheet noPadding>
                  <DownloadList downloads={myDownloads} {...other} />
                </ResponsiveSheet>
              </ScrollableContent>
            ) : (
              <EmptyState Icon={DownloadIcon} label="Je hebt nog geen bestanden gedownload." />
            )}
          </FabView>
        );
      }}
    </SubscribeData>
  );
};

DownloadView.propTypes = {
  openContact: PropTypes.func,
};

export default DownloadView;
