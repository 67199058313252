"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.certificates = void 0;
const certificates = [{
  _id: '101',
  categoryId: 'leidingen',
  title: 'toegangs- en verbindingsputten',
  description: 'Toegangs- en verbindingsputten van ongewapend beton, van staalvezelbeton en van gewapend beton',
  codes: ['NBN B 21-101', 'PTV 21-101', 'NBN EN 1917'],
  labels: [{
    key: 'F',
    title: 'staalvezelbeton'
  }, {
    key: 'R',
    title: 'gewapend beton'
  }, {
    key: 'U',
    title: 'ongewapend beton'
  }]
}, {
  categoryId: 'leidingen',
  title: 'geperforeerde en poreuze buizen',
  description: 'Cirkelvormige geperforeerde en poreuze buizen van ongewapend beton voor draineer- en infiltratieleidingen',
  codes: ['PTV 104'],
  _id: '104',
  labels: [{
    key: 'A',
    title: 'geperforeerde buizen'
  }, {
    key: 'P',
    title: 'poreuze buizen'
  }]
}, {
  categoryId: 'leidingen',
  title: 'bakken voor waterafvoer',
  description: 'Betonnen bakken voor waterafvoer',
  codes: ['PTV 105'],
  _id: '105'
}, {
  categoryId: 'leidingen',
  title: 'buizen en hulpstukken',
  description: 'Buizen en hulpstukken van ongewapend beton, van staalvezelbeton en van gewapend beton',
  codes: ['NBN B 21-106', 'PTV 21-106', 'NBN EN 1916'],
  _id: '106',
  labels: [{
    key: 'F',
    title: 'buizen van staalvezelbeton'
  }, {
    key: 'J',
    title: 'doorpersbuizen van gewapend beton'
  }, {
    key: 'R',
    title: 'buizen van gewapend beton'
  }, {
    key: 'U',
    title: 'buizen van ongewapend beton'
  }]
}, {
  categoryId: 'wegenis',
  title: 'tegels',
  description: 'Betontegels',
  codes: ['NBN B 21-211', 'NBN EN 1339'],
  _id: '211'
}, {
  categoryId: 'wegenis',
  title: 'straatstenen',
  description: 'Betonstraatstenen',
  codes: ['NBN B 21-311', 'NBN EN 1338'],
  _id: '311'
}, {
  categoryId: 'wegenis',
  title: 'boordstenen',
  _id: '411'
}, {
  categoryId: 'wegenis',
  title: 'draineer- en doorgroeiplaten',
  _id: '123'
}, {
  categoryId: 'wegenis',
  title: 'straatstenen met gekliefd zichtvlak',
  _id: '125'
}, {
  categoryId: 'wegenis',
  title: 'producten voor waterdoorlatende bestratingen',
  _id: '126',
  labels: [{
    key: 'P',
    title: 'poreuze producten'
  }, {
    key: 'W',
    title: 'niet-poreuze producten (die geen grasbetonproducten zijn'
  }, {
    key: 'W+G',
    title: 'grasbetonproducten'
  }]
}, {
  categoryId: 'waterzuivering',
  title: 'bekuipingen',
  _id: '114',
  labels: [{
    key: 'R',
    title: 'regenwaterput'
  }, {
    key: 'S',
    title: 'septische tank'
  }, {
    key: 'Z',
    title: 'zuiveringsinstallatie van huishoudelijk afvalwater'
  }]
}, {
  categoryId: 'weguitrustingen',
  title: 'elementen voor afschermende constructies',
  _id: '124',
  labels: [{
    key: 'P',
    title: 'elementen bestemd voor permanente constructies'
  }, {
    key: 'T',
    title: 'elementen bestemd voor niet-permanente constructies'
  }]
}, {
  categoryId: 'diversen-infra',
  title: 'diverse producten voor infrastructuurwerken',
  _id: '100',
  labels: [{
    key: 'A',
    title: 'geprefabriceerde dek- en funderingsplaten voor andere dan betonnen putten'
  }, {
    key: 'B',
    title: 'bijzondere putten'
  }, {
    key: 'CD',
    title: 'kopmuurelementen en in- en uitstroomelementen'
  }, {
    key: 'F',
    title: 'funderingselementen voor infrastructuurwerken'
  }, {
    key: 'H',
    title: 'damplanken'
  }, {
    key: 'I',
    title: 'elementen voor oeverbescherming en -versterking'
  }, {
    key: 'K',
    title: 'bijzondere bakken'
  }, {
    key: 'L',
    title: 'betonranden voor deksels'
  }, {
    key: 'N',
    title: 'elementen voor verkeersinrichtingen'
  }, {
    key: 'O',
    title: 'producten voor ondergrondse buffering en/of infiltratie'
  }, {
    key: 'P',
    title: 'geprefabriceerde betonnen afwateringsgoten voor verkeersgebieden voor voertuigenverkeer'
  }, {
    key: 'Q',
    title: 'bijzondere kokerelementen'
  }]
}, {
  categoryId: 'diversen-infra',
  title: 'kabelbeschermers, kabelsleuven en deksels',
  _id: '107',
  labels: [{
    key: 'B',
    title: 'kabelbeschermers'
  }, {
    key: 'D',
    title: 'deksels'
  }, {
    key: 'S',
    title: 'kabelsleuven'
  }]
}, {
  categoryId: 'diversen-infra',
  title: 'grachtelementen en taludgoten',
  _id: '108',
  labels: [{
    key: 'G',
    title: 'grachtelementen'
  }, {
    key: 'T',
    title: 'taludgoten'
  }]
}, {
  categoryId: 'dakpannen',
  title: 'dakpannen en hulpstukken',
  description: 'Betondakpannen en hulpstukken',
  codes: ['NBN EN 490'],
  _id: '490'
}, {
  categoryId: 'metselstenen',
  title: 'betonmetselstenen',
  description: 'Betonmetselstenen',
  codes: ['PTV 21-001', 'NBN EN 771-3'],
  _id: '001'
}, {
  categoryId: 'metselstenen',
  title: 'cellenbetonmetselstenen',
  description: 'Geautoclaveerde cellenbetonmetselstenen',
  codes: ['PTV 21-002', 'NBN EN 771-4'],
  _id: '002'
}, {
  categoryId: 'metselstenen',
  title: 'metselstenen van kalkzandsteen',
  description: 'Metselstenen van kalkzandsteen',
  codes: ['PTV 21-003', 'NBN EN 771-2'],
  _id: '003'
}, {
  categoryId: 'structuurelementen',
  title: 'potten',
  _id: '006'
}, {
  categoryId: 'structuurelementen',
  title: 'kokerelementen',
  description: 'Rechthoekige kokerelementen',
  codes: ['NBN B 21-102', 'NBN EN 14844'],
  _id: '102'
}, {
  categoryId: 'structuurelementen',
  title: 'straat- en tuinmeubilair',
  _id: '131'
}, {
  categoryId: 'structuurelementen',
  title: 'keermuurelementen',
  description: 'Keermuurelementen',
  codes: ['NBN B 21-132', 'NBN EN 15258'],
  _id: '132',
  labels: [{
    key: 'GL_GT',
    title: 'gewapende keermuurelementen in L-vorm en in omgekeerde T-vorm'
  }]
}, {
  categoryId: 'structuurelementen',
  title: 'betonproducten',
  codes: ['diverse structuurelementen'],
  description: 'Geprefabriceerde betonproducten - Diverse structuurelementen',
  codes: ['NBN B 21-600', 'NBN EN 13369'],
  _id: '600',
  labels: [{
    key: 'G',
    title: 'gewapende structuurelementen'
  }, {
    key: 'V',
    title: 'voorgespannen structuurelementen'
  }]
}, {
  categoryId: 'structuurelementen',
  title: 'elementen van architectonisch beton',
  description: 'Geprefabriceerde elementen van architectonisch beton',
  codes: ['PTV 21-601'],
  _id: '601'
}, {
  categoryId: 'structuurelementen',
  title: 'geribde vloerelementen',
  description: 'Geribde vloerelementen',
  codes: ['NBN B 21-603', 'NBN EN 13224'],
  _id: '603',
  labels: [{
    key: 'G',
    title: 'gewapende geribde vloerelementen'
  }, {
    key: 'V',
    title: 'voorgespannen geribde vloerelementen'
  }]
}, {
  categoryId: 'structuurelementen',
  title: 'lijnvormige dragende elementen',
  description: 'Lijnvormige dragende elementen',
  codes: ['NBN B 21-604', 'NBN EN 13225'],
  _id: '604',
  labels: [{
    key: 'G',
    title: 'gewapende lijnvormige dragende elementen'
  }, {
    key: 'V',
    title: 'voorgespannen lijnvormige dragende elementen'
  }]
}, {
  categoryId: 'structuurelementen',
  title: 'holle vloerelementen',
  description: 'Holle vloerelementen',
  codes: ['NBN B 21-605', 'NBN EN 1168'],
  _id: '605',
  labels: [{
    key: 'G',
    title: 'gewapende holle vloerelementen'
  }, {
    key: 'V',
    title: 'voorgespannen holle vloerelementen'
  }]
}, {
  categoryId: 'structuurelementen',
  title: 'breedplaten voor vloersystemen',
  description: 'Breedplaten voor vloersystemen',
  codes: ['NBN B 21-606', 'NBN EN 13747'],
  _id: '606',
  labels: [{
    key: 'G',
    title: 'gewapende breedplaten'
  }, {
    key: 'V',
    title: 'voorgespannen breedplaten'
  }]
}, {
  categoryId: 'structuurelementen',
  title: 'funderingselementen',
  _id: '609'
}, {
  categoryId: 'structuurelementen',
  title: 'trappen',
  description: 'Trappen',
  codes: ['NBN B 21-611', 'NBN EN 14843'],
  _id: '611',
  labels: [{
    key: 'G',
    title: 'gewapende trappen'
  }, {
    key: 'V',
    title: 'voorgespannen trappen'
  }]
}, {
  categoryId: 'structuurelementen',
  title: 'wandelementen',
  description: 'Wandelementen',
  codes: ['NBN B 21-612', 'NBN EN 14992'],
  _id: '612',
  labels: [{
    key: 'A',
    title: 'volle wandelementen'
  }, {
    key: 'G',
    title: 'gewapende wandelementen'
  }, {
    key: 'B',
    title: 'sandwichelementen'
  }, {
    key: 'V',
    title: 'voorgespannen wandelementen'
  }, {
    key: 'C',
    title: 'gewapende wandelementen'
  }, {
    key: 'D',
    title: 'composiete wandelementen'
  }, {
    key: 'E',
    title: 'bekledingspanelen'
  }]
}, {
  categoryId: 'structuurelementen',
  title: 'funderingspalen',
  _id: '613'
}, {
  categoryId: 'structuurelementen',
  title: 'balken voor combinatievloeren',
  description: 'Balken voor combinatievloeren',
  codes: ['NBN B 21-616', 'NBN EN 15037-1'],
  _id: '616',
  labels: [{
    key: 'G',
    title: 'gewapende balken'
  }, {
    key: 'V',
    title: 'voorgespannen balken'
  }]
}, {
  categoryId: 'structuurelementen',
  title: 'agrarische producten',
  description: 'Agrarische betonproducten',
  codes: ['PTV 21-620'],
  _id: '620',
  labels: [{
    key: 'A',
    title: 'roostervloerelementen voor stalgebouwen'
  }, {
    key: 'N',
    title: 'beloopbare en niet berijdbare elementen'
  }, {
    key: 'R',
    title: 'berijdbare elementen'
  }, {
    key: 'HS',
    title: 'wand- en/of keermuurelementen voor silo- en kelderwanden'
  }]
}, {
  categoryId: 'diversen-bouwelementen',
  title: 'gewapende elementen van geautoclaveerd cellenbeton',
  description: 'Gewapende elementen van geautoclaveerd cellenbeton',
  codes: ['NBN B 21-004'],
  _id: '004'
}];
exports.certificates = certificates;