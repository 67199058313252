"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "light", {
  enumerable: true,
  get: function get() {
    return _lightTheme["default"];
  }
});
Object.defineProperty(exports, "dark", {
  enumerable: true,
  get: function get() {
    return _darkTheme["default"];
  }
});
exports["default"] = void 0;
var _lightTheme = _interopRequireDefault(require("./lightTheme"));
var _darkTheme = _interopRequireDefault(require("./darkTheme"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
var themes = {
  light: _lightTheme["default"],
  dark: _darkTheme["default"]
};
var _default = themes;
exports["default"] = _default;