import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { format } from 'date-fns';

import EditIcon from '@material-ui/icons/Edit';

import {
  List,
  Avatar,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';

import { EmptyState } from '@solar/solar-react';

import { ProfileItem } from '@probeton/ui';

const useStyles = makeStyles((theme) => {
  return {
    root: {},
  };
});

const EditList = ({ edits, openFabricate, openFabricateEdits }) => {
  const [detailOpen, setDetailOpen] = useState();
  const classes = useStyles();
  if (!edits || edits.length === 0) {
    return <EmptyState Icon={EditIcon} label="Geen wijzigingen" />;
  }

  const renderChange = ({ state, type }) => {
    if (type === 'productAdd') {
      return 'nieuw fabricaat aangemaakt';
    }
    const changes = Object.keys(state.data).length;
    if (type === 'productEdit') {
      return `fabricaat aangepast (${changes} veld${changes !== 1 ? 'en' : ''})`;
    }
    if (type === 'certificationEdit') {
      return `certificaat aangepast (${changes} veld${changes !== 1 ? 'en' : ''})`;
    }
  };
  const onClick = ({ state, type }) => {
    if (type === 'productAdd') {
      // console.log(state);
      return openFabricate(state.fabricateId);
    } else if (type === 'productEdit') {
      return openFabricateEdits(state.fabricateId);
    }
    setDetailOpen(state);
  };
  return (
    <Fragment>
      <List>
        {edits.map(({ editedBy, editedAt, state, type }) => (
          <ProfileItem
            icon={<Avatar src={editedBy?.avatarUrl} />}
            text={`op ${format(new Date(editedAt), 'yyyy.MM.dd')} door ${
              editedBy?.person?.fullName
            }`}
            textLabel={renderChange({ state, type })}
            onClick={() => onClick({ state, type })}
          />
        ))}
      </List>
      <Dialog open={!!detailOpen}>
        <DialogTitle>Aanpassingen</DialogTitle>
        {!!detailOpen ? (
          <DialogContent>
            <List>
              {Object.keys(detailOpen).map((key) => (
                <ProfileItem text={key} textLabel={detailOpen[key]} />
              ))}
            </List>
          </DialogContent>
        ) : (
          ''
        )}
        <DialogActions>
          <Button onClick={() => setDetailOpen(null)}>Ok</Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

EditList.propTypes = {
  className: PropTypes.string,
  edits: PropTypes.array,
};

EditList.defaultProps = {
  className: '',
  edits: [],
};

export default EditList;
