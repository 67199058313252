"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.products = void 0;
const products = [{
  code: 'WAR GEO',
  name: 'A/AD115',
  description: 'Verticaal element A3 : type groot basiselement met monoliete reductieplaat rond gewapend basiselement -standaardtoegansput type A/AD115 type A115 AD115',
  technicalSheet: '3a_101_voorbeeld',
  number: '101/110'
}, {
  code: '106 U 106 F',
  name: 'Pendelbuis van staalvezelbeton',
  description: 'Pendelbuis van staalvezelbeton spie-spie van staalvezelbeton buizen met rolring buizen met ingestorte glipring in de mof BK 62 (rolring) DN 700 - DN 1000 DN 300 - DN 600 DN 700 - DN 1000',
  technicalSheet: '3b_106_voorbeeld1',
  number: '106/117'
}, {
  code: '106 U 106 F',
  name: 'Pendelbuis van staalvezelbeton',
  description: 'Pendelbuis van staalvezelbeton spie-spie van staalvezelbeton buizen met rolring buizen met ingestorte glipring in de mof BK 62 (rolring) DN 700 - DN 1000 DN 300 - DN 600 DN 700 - DN 1000',
  technicalSheet: '3c_106_voorbeeld2',
  number: '106/104'
}];
exports.products = products;