import React from 'react';

import { Route } from 'react-router-dom';

import { Redirect } from 'react-router-dom';

import sheets from '../../sheets';
import dialogs from '../../dialogs';

import { App } from '@probeton/ui';

import NavigationDrawerRepresentative from './NavigationDrawerRepresentative';

import ContactView from './Contact/ContactView';
import { CertificateViewUnit } from './Certificate/CertificateView';
import CertificateSheet from './Certificate/CertificateSheet';
import ACLRequestViewRep from './Certificate/ACLRequestViewRep';
import CustomerView from './Customer/CustomerView';

import { CommonRoutes } from './CommonNav';

const RepresentativeApp = ({ history, user, unit, ...other }) => (
  <App
    history={history}
    user={user}
    app="REP"
    sheets={sheets}
    dialogs={dialogs}
    navigationDrawer={NavigationDrawerRepresentative}
    drawerProps={{ unit }}
    {...other}
  >
    {({
      handleDetailViewToggle,
      handleDialog,
      getActionBarContainer,
      setImageOpen,
      setPdfOpen,
    }) => (
      <CommonRoutes
        handleDetailViewToggle={handleDetailViewToggle}
        handleDialog={handleDialog}
        history={history}
        user={user}
        setImageOpen={setImageOpen}
        setPdfOpen={setPdfOpen}
      >
        <Route path="/" exact render={() => <Redirect to="/certificates" />} />
        <Route
          path="/users"
          exact
          render={(props) => (
            <ContactView
              history={history}
              openContact={(id) => handleDetailViewToggle('contact', id)}
              onCreateEmployee={() => handleDialog('createEmployee')}
              title="Gebruikers"
              {...props}
            />
          )}
        />
        <Route
          path="/customers"
          exact
          render={(props) => (
            <CustomerView
              history={history}
              openCustomer={(id) => handleDetailViewToggle('customer', id)}
              title="Klanten"
              {...props}
            />
          )}
        />
        <Route
          path="/certificates"
          exact
          render={(props) => (
            <CertificateViewUnit
              unit={unit}
              history={history}
              openCertificate={(id) => history.push(`/certificates/${id}`)}
              {...props}
            />
          )}
        />
        <Route
          path="/certificates/:id"
          exact
          render={({ match }) => {
            if (!getActionBarContainer()) {
              return 'hold it';
            }
            return (
              <CertificateSheet
                id={match.params.id}
                history={history}
                currentUser={user}
                openCertificate={(id) => handleDetailViewToggle('certificate', id)}
                handleDetailViewToggle={handleDetailViewToggle}
                openDialog={handleDialog}
                getActionBarContainer={getActionBarContainer}
                setImageOpen={setImageOpen}
                setPdfOpen={setPdfOpen}
              />
            );
          }}
        />
        <Route path="/changes" exact render={(props) => 'TODO'} />
        <Route
          path="/aclRequest"
          exact
          render={(props) => (
            <ACLRequestViewRep
              user={user}
              openACLRequest={(id) => handleDetailViewToggle('aclRequest', id)}
            />
          )}
        />
      </CommonRoutes>
    )}
  </App>
);

// prettier-ignore
export default RepresentativeApp;

// <Route
//   path="/institutions"
//   exact
//   render={props => (
//     <CertifierList
//       history={history}
//       openCertifier={id => handleDetailViewToggle('certifier', id)}
//       onCreateCertifier={() => handleDialog('createCertifier')}
//       {...props}
//     />
//   )}
// />
// <Route
//   path="/manufacturers"
//   exact
//   render={props => (
//     <ManufacturerList
//       history={history}
//       openManufacturer={id => handleDetailViewToggle('manufacturer', id)}
//       onCreateManufacturer={() => handleDialog('createManufacturer')}
//       {...props}
//     />
//   )}
// />
