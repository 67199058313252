// @flow

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { makeStyles } from '@material-ui/core/styles';

import { List } from '@material-ui/core';
import { Rounded, FileListItem } from '@solar/solar-react';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      display: 'flex',
      alignItems: 'center',
      margin: '2px 0px 4px 0px',
      padding: 0,
      // boxShadow: theme.shadows[1],
      backgroundColor: '#eee',
      '&:hover': {
        cursor: 'pointer',
      },
      maxWidth: 72 * 12,
    },
    icons: {
      display: 'flex',
      flexDirection: 'column',
      marginRight: 16,
    },
    active: {
      color: theme.palette.secondary.dark,
      fontSize: 36,
    },
    content: {},
    avatar: {
      width: 32,
      height: 32,
      marginRight: 8,
    },
    family: {
      paddingTop: 8,
    },
    date: {
      color: theme.palette.secondary.dark,
      marginBottom: 4,
    },
  };
});

const ChatDocument = ({ align, className, files, setPdfOpen, ...other }) => {
  const classes = useStyles();
  return (
    <Rounded align={align} className={classNames(classes.root, className)} {...other}>
      <List>
        {files.map(({ fileUrl, label }) => (
          <FileListItem
            interactive
            label={label}
            fileUrl={fileUrl}
            onClickOpen={() => setPdfOpen(fileUrl)}
            onClickDownloadFile={() => window.open(fileUrl, '__blank')}
          />
        ))}
      </List>
    </Rounded>
  );
};

ChatDocument.propTypes = {
  align: PropTypes.string,
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
};

ChatDocument.defaultProps = {
  className: '',
};

// prettier-ignore
export default ChatDocument;
