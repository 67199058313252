import React, { Fragment, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { pdfjs } from 'react-pdf';

import { makeStyles } from '@material-ui/core/styles';

import {
  AuthenticatedRoute,
  UnauthenticatedRoute,
  GenericRoute,
  LoginPage,
  SignupPage,
  BallerLoginPage,
  OnboardingPage,
} from '@solar/poc-ui';

import { NotFoundPage } from '@probeton/ui';
import { ui as accountsUI } from '@probeton/accounts';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import GlobalStyles from './style/global';
import App from './AppContainer';
import SubscribeData from '../../SubscribeData';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const AuthLayout = accountsUI.AuthLayout;

const useStyles = makeStyles(GlobalStyles);

const tMap = {
  'loginPage.signupCTALink': 'Account aanmaken',
  'loginPage.resetCTALink': 'Wachtwoord vergeten',
  'loginPage.autoCTAText': ' ',
  'loginPage.autoCTALink': 'Overslaan voor PoC',
  'signupPage.loginCTAText': ' ',
  'signupPage.loginCTALink': 'terug',
  'ballerLogin.CTALink': 'terug',
};

const Root = ({ onToggleTheme, accounts, onLogout, history, onReset }) => {
  const classes = useStyles();
  const [resetShown, showReset] = useState(false);
  const t = (key) => {
    return tMap[key] || key;
  };
  const generalProps = {
    layout: AuthLayout,
    accounts,
    componentProps: { t, accounts },
    layoutProps: {
      onReset: () => {
        showReset(true);
      },
    },
  };
  return (
    <div className={classes.root}>
      <Switch>
        <UnauthenticatedRoute exact path="/login" component={LoginPage} {...generalProps} />
        <UnauthenticatedRoute exact path="/signup" component={SignupPage} {...generalProps} />
        <UnauthenticatedRoute
          exact
          path="/onboarding"
          component={OnboardingPage}
          {...generalProps}
        />
        <UnauthenticatedRoute exact path="/auto" component={BallerLoginPage} {...generalProps} />
        <GenericRoute
          path="/"
          component={App}
          accounts={accounts}
          componentProps={{
            onToggleTheme,
            onLogout,
            accounts,
          }}
        />
        <Route component={NotFoundPage} />
      </Switch>
      <Dialog open={resetShown}>
        <SubscribeData>
          {({ reset }) => (
            <Fragment>
              <DialogTitle className={classes.title}>Alles resetten</DialogTitle>
              <DialogContent className={classes.content}>
                <Typography>Wil je al je data resetten? Dit is onomkeerbaar.</Typography>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    showReset(false);
                  }}
                >
                  annuleren
                </Button>
                <Button
                  onClick={() => {
                    // onReset();
                    reset();
                    showReset(false);
                    window.location.reload();
                  }}
                  color="secondary"
                >
                  Ja
                </Button>
              </DialogActions>
            </Fragment>
          )}
        </SubscribeData>
      </Dialog>
    </div>
  );
};

export default Root;
