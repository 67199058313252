import React from 'react';
import PropTypes from 'prop-types';

import SubscribeData from '../../../SubscribeData';
import { ProductSearch } from '@probeton/ui';

import { certificateMapper } from '../Manufacturer/helpers';

const ProductView = (props) => {
  return (
    <SubscribeData>
      {({ certificates, helpers: { all } }) => {
        return (
          <ProductSearch
            products={all(certificates).map(certificateMapper)}
            labels={{ search: 'zoeken' }}
            {...props}
          />
        );
      }}
    </SubscribeData>
  );
};

ProductView.propTypes = {
  openCertificate: PropTypes.func,
};

export default ProductView;
