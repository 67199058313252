import React from 'react';

import SubscribeData from '../../SubscribeData';

// import { default as Public } from './AppPublic';
import { default as Admin } from './AppAdmin';
import { default as Public } from './AppPublic';
import { default as Representative } from './AppRepresentative';
import { default as Inspector } from './AppInspector';
import { default as Customer } from './AppCustomer';

const AppContainer = ({ user, ...props }) => (
  <SubscribeData>
    {({
      aclRequests,
      addACLRequest,
      downloads,
      addDownload,
      updateDownload,
      shares,
      shareProfile,
    }) => {
      const allProps = {
        ...props,
        aclProps: {
          aclRequests,
          addACLRequest,
          downloads,
          addDownload,
          updateDownload,
          shares,
          shareProfile,
        },
      };

      if (!user || user.auth.length === 0) {
        return <Public user={user} {...allProps} />;
      }
      if (user.auth.includes('probeton')) {
        return <Admin user={user} {...allProps} />;
      }
      if (user.auth.includes('representative')) {
        // find the representaiveś unit
        return (
          <SubscribeData>
            {({ units, helpers: { get, all } }) => {
              const unit = all(units).find(({ representatives }) =>
                representatives.includes(user._id),
              );
              if (!unit) {
                return 'no unit found for this rep';
              }
              return <Representative user={user} unit={unit} {...allProps} />;
            }}
          </SubscribeData>
        );
      }
      if (user.auth.includes('inspector')) {
        return <Inspector user={user} {...allProps} />;
      }
      if (user.auth.includes('customer')) {
        return <Customer user={user} {...allProps} />;
      }
      return 'no auth';
      // return <Admin user={user} {...allProps} />;
    }}
  </SubscribeData>
);

// prettier-ignore
export default AppContainer;
