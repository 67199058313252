"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _faker = _interopRequireDefault(require("faker"));

var _ramda = require("ramda");

var _oneLinerJoke = require("one-liner-joke");

var _helpers = require("@probeton/helpers");

var _dateFns = require("date-fns");

var _users = require("./users");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const r = _helpers.rndBetween;

const oneLiner = () => (0, _oneLinerJoke.getRandomJoke)({
  exclude_tags: ['dirty', 'racist', 'sex']
}).body;

const createMessage = m => ({
  _id: (0, _helpers.uuid)(),
  ...m,
  createdAt: m.createdAt ? m.createdAt.getTime() : new Date().getTime(),
  ...(m.system ? {
    user: {
      _id: 'system'
    }
  } : {})
});

const randomMessages = props => [...Array(...Array(r(0, 3))).map(i => createMessage({ ...props,
  text: oneLiner()
}))];

const createTicket = (ticket, createdBy) => {
  ticket.createdBy = createdBy._id;

  const _id = ticket._id || (0, _helpers.uuid)();

  const createdAt = ticket.createdAt || new Date();
  return {
    ticket: {
      _id,
      ...ticket,
      createdAt,
      status: 'created'
    },
    messages: [createMessage({
      ticketId: _id,
      status: 'created',
      msg: `Ticket werd aangemaakt op ${(0, _dateFns.format)(createdAt, 'd/M HH:mm')}.`,
      userId: ticket.createdBy,
      createdAt
    }), createMessage({
      ticketId: _id,
      text: ticket.description,
      userId: ticket.createdBy,
      createdAt
    }), createMessage({
      ticketId: _id,
      imgSrc: `https://placeimg.com/800/400/tech?t=${r(0, 500)}`,
      createdAt: (0, _dateFns.addSeconds)(createdAt, r(24, 72)),
      userId: ticket.createdBy
    })]
  };
};

const acceptTicket = (ticket, byUser) => {
  const acceptedAt = ticket.acceptedAt || new Date();
  return {
    ticket: { ...ticket,
      acceptedAt,
      acceptedBy: byUser._id,
      status: 'accepted'
    },
    messages: [createMessage({
      ticketId: ticket._id,
      prevStatus: ticket.status,
      status: 'accepted',
      msg: `Ticket in behandeling genomen sinds ${(0, _dateFns.format)(acceptedAt, 'd/M HH:mm')}.`,
      userId: byUser._id,
      createdAt: acceptedAt
    }), createMessage({
      ticketId: ticket._id,
      text: oneLiner(),
      userId: byUser._id,
      createdAt: (0, _dateFns.addSeconds)(acceptedAt, r(24, 72))
    }), createMessage({
      ticketId: ticket._id,
      files: [{
        fileUrl: '/technical_sheets/3a_101_voorbeeld.pdf',
        label: 'nieuwe tech sheet'
      }, {
        fileUrl: '/technical_sheets/3b_106_voorbeeld1.pdf',
        label: 'Voorbeeld'
      }],
      userId: byUser._id,
      createdAt: (0, _dateFns.addSeconds)(acceptedAt, r(72, 180))
    })]
  };
};

const rejectTicket = (ticket, byUser) => {
  const rejectedAt = ticket.rejectedAt || new Date();
  const {
    reviewedAt,
    reviewedBy,
    approvedAt,
    approvedBy,
    ...other
  } = ticket;
  return {
    ticket: { ...other,
      rejectedAt,
      rejectedBy: byUser._id,
      status: 'rejected'
    },
    messages: [createMessage({
      ticketId: ticket._id,
      prevStatus: ticket.status,
      status: 'rejected',
      msg: `Ticket werd afgekeurd op ${(0, _dateFns.format)(rejectedAt, 'd/M HH:mm')}.`,
      userId: byUser._id,
      createdAt: rejectedAt
    }), createMessage({
      ticketId: ticket._id,
      text: oneLiner(),
      userId: byUser._id,
      createdAt: (0, _dateFns.addSeconds)(rejectedAt, r(72, 180))
    })]
  };
};

const approveTicket = (ticket, byUser) => {
  const approvedAt = ticket.approvedAt || new Date();
  const {
    rejectedAt,
    rejectedBy,
    ...other
  } = ticket;
  return {
    ticket: { ...other,
      approvedAt,
      approvedBy: byUser._id,
      status: 'approved'
    },
    messages: [createMessage({
      ticketId: ticket._id,
      prevStatus: ticket.status,
      status: 'approved',
      msg: `Ticket werd goedgekeurd op ${(0, _dateFns.format)(approvedAt, 'd/M HH:mm')}.`,
      userId: byUser._id,
      createdAt: approvedAt
    }), createMessage({
      ticketId: ticket._id,
      text: oneLiner(),
      userId: byUser._id,
      createdAt: (0, _dateFns.addSeconds)(approvedAt, r(72, 180))
    })]
  };
};

const reviewTicket = (ticket, byUser) => {
  const reviewedAt = ticket.reviewedAt || new Date();
  return {
    ticket: { ...ticket,
      reviewedAt,
      reviewedBy: byUser._id,
      status: 'reviewed'
    },
    messages: [createMessage({
      ticketId: ticket._id,
      prevStatus: ticket.status,
      status: 'reviewed',
      msg: `Ticket ter controle in behandeling genomen sinds ${(0, _dateFns.format)(reviewedAt, 'd/M HH:mm')}.`,
      userId: byUser._id,
      createdAt: reviewedAt
    }), createMessage({
      ticketId: ticket._id,
      text: oneLiner(),
      userId: byUser._id,
      createdAt: (0, _dateFns.addSeconds)(reviewedAt, r(72, 180))
    })]
  };
};

const publishProvisionalTicket = (ticket, byUser) => {
  const publishedAt = ticket.publishedAt || new Date();
  return {
    ticket: { ...ticket,
      publishedAt,
      publishedBy: byUser._id,
      status: 'published'
    },
    messages: [createMessage({
      ticketId: ticket._id,
      prevStatus: ticket.status,
      status: 'published_provisional',
      msg: `Ticket werd gepubliceerd (onder voorbehoud) op ${(0, _dateFns.format)(publishedAt, 'd/M HH:mm')}.`,
      userId: byUser._id,
      createdAt: publishedAt
    }), createMessage({
      ticketId: ticket._id,
      text: oneLiner(),
      userId: byUser._id,
      createdAt: (0, _dateFns.addSeconds)(publishedAt, r(72, 180))
    })]
  };
};

const publishTicket = (ticket, byUser) => {
  const publishedAt = ticket.publishedAt || new Date();
  return {
    ticket: { ...ticket,
      publishedAt,
      publishedBy: byUser._id,
      status: 'published'
    },
    messages: [createMessage({
      ticketId: ticket._id,
      prevStatus: ticket.status,
      status: 'published',
      msg: `Ticket werd gepubliceerd op ${(0, _dateFns.format)(publishedAt, 'd/M HH:mm')}.`,
      userId: byUser._id,
      createdAt: publishedAt
    }), createMessage({
      ticketId: ticket._id,
      text: oneLiner(),
      userId: byUser._id,
      createdAt: (0, _dateFns.addSeconds)(publishedAt, r(72, 180))
    })]
  };
};

const randomTicket = certification => {
  const createdAt = (0, _dateFns.addDays)(_faker.default.date.recent(), -10);
  const extra = {};
  return {
    title: _faker.default.random.words(r(2, 5)),
    description: oneLiner(),
    certificationId: certification._id,
    edits: certification.unrequestedChanges ? certification.unrequestedChanges() : certification.edits || [],
    createdAt,
    ...extra
  };
};

const generateTickets = (certifications, units, allInspectors, allAdmins, persons) => {
  const randomAcceptance = ticket => {
    const acceptedAt = (0, _dateFns.addHours)(ticket.createdAt, r(24, 72));
    return { ...ticket,
      acceptedAt
    };
  };

  const randomApproval = ticket => {
    const approvedAt = (0, _dateFns.addHours)(ticket.acceptedAt, r(24, 72));
    return { ...ticket,
      approvedAt
    };
  };

  const randomRejection = ticket => {
    const rejectedAt = (0, _dateFns.addHours)(ticket.acceptedAt, r(24, 72));
    return { ...ticket,
      rejectedAt
    };
  };

  const randomReview = ticket => {
    const reviewedAt = (0, _dateFns.addHours)(ticket.approvedAt, r(24, 72));
    return { ...ticket,
      reviewedAt
    };
  };

  const randomPublication = ticket => {
    let publishedAt = (0, _dateFns.addHours)(ticket.reviewedAt, r(24, 72));

    if (ticket.publishetAt) {
      publishedAt = (0, _dateFns.addHours)(ticket.publishedAt, r(24, 72));
    }

    return { ...ticket,
      publishedAt
    };
  };

  const rnd = chance => Math.random() > 1 - chance; // const p = chance => clb => (rnd(chance) ? clb : t => t);
  // technicianIds: [_id, ...getRandomElements(allTechnicians, r(0, 2))],


  const unitIds = units.filter(({
    representatives
  }) => representatives.length > 0).map(({
    _id
  }) => _id);
  const selectedCertifications = [...(0, _helpers.getRandomElements)(certifications.filter(({
    unitId
  }) => !unitIds.includes(unitId)), 16), ...certifications.filter(({
    unitId
  }) => unitIds.includes(unitId))];
  return selectedCertifications.reduce(({
    tickets,
    messages: allMessages
  }, certification) => {
    const generateTicket = i => {
      let ticket;
      const unit = units.find(({
        _id
      }) => _id === certification.unitId);
      const {
        ticket: createdTicket,
        messages
      } = createTicket(randomTicket(certification), unit.representatives.length > 0 ? _users.users[unit.representatives[0]] : persons.find(({
        _id
      }) => _id === unit.contact));
      ticket = createdTicket;
      allMessages = [...allMessages, ...messages];

      if (rnd(0.7)) {
        const inspector = (0, _helpers.getRandomElement)(allInspectors);
        const accept = acceptTicket(randomAcceptance(ticket), inspector);
        ticket = accept.ticket;
        allMessages = [...allMessages, ...accept.messages];

        if (rnd(0.7)) {
          if (rnd(0.5)) {
            const approve = approveTicket(randomApproval(ticket), inspector);
            ticket = approve.ticket;
            allMessages = [...allMessages, ...approve.messages];

            if (rnd(0.7)) {
              const admin = (0, _helpers.getRandomElement)(allAdmins);
              const review = reviewTicket(randomReview(ticket), admin);
              ticket = review.ticket;
              allMessages = [...allMessages, ...review.messages];

              if (rnd(0.7)) {
                if (rnd(0.7)) {
                  if (rnd(0.5)) {
                    const publish = publishTicket(randomPublication(ticket), admin);
                    ticket = publish.ticket;
                    allMessages = [...allMessages, ...publish.messages];
                  } else {
                    const publish = publishProvisionalTicket(randomPublication(ticket), admin);
                    ticket = publish.ticket;
                    allMessages = [...allMessages, ...publish.messages];

                    if (rnd(0.5)) {
                      const publish = publishTicket(randomPublication(ticket), admin);
                      ticket = publish.ticket;
                      allMessages = [...allMessages, ...publish.messages];
                    }
                  }
                } else {
                  const reject = rejectTicket(randomRejection(ticket), admin);
                  ticket = reject.ticket;
                  allMessages = [...allMessages, ...reject.messages];
                }
              }
            }
          } else {
            const reject = rejectTicket(randomRejection(ticket), inspector);
            ticket = reject.ticket;
            allMessages = [...allMessages, ...reject.messages];
          }
        }
      }

      return ticket;
    };

    return {
      tickets: [...tickets, ...Array(...Array(r(1, 4))).map(generateTicket)],
      messages: allMessages
    };
  }, {
    tickets: [],
    messages: []
  });
};

var _default = {
  generators: {
    ticket: generateTickets,
    message: () => {}
  },
  mutations: {
    ticket: stores => ({
      create: (certification, createdBy) => {
        const {
          ticket: newlyCreatedTicket,
          messages
        } = createTicket(randomTicket(certification), createdBy);
        stores().crud.create('ticket')(newlyCreatedTicket);
        messages.forEach(message => stores().crud.create('message')(message));
        return newlyCreatedTicket;
      },
      accept: (ticket, byUser) => {
        const {
          ticket: updatedTicket,
          messages
        } = acceptTicket(ticket, byUser);
        stores().crud.create('ticket')(updatedTicket);
        messages.forEach(message => stores().crud.create('message')(message));
        return updatedTicket;
      },
      reject: (ticket, byUser) => {
        const {
          ticket: updatedTicket,
          messages
        } = rejectTicket(ticket, byUser);
        stores().crud.create('ticket')(updatedTicket);
        messages.forEach(message => stores().crud.create('message')(message));
        return updatedTicket;
      },
      approve: (ticket, byUser) => {
        const {
          ticket: updatedTicket,
          messages
        } = approveTicket(ticket, byUser);
        stores().crud.create('ticket')(updatedTicket);
        messages.forEach(message => stores().crud.create('message')(message));
        return updatedTicket;
      },
      review: (ticket, byUser) => {
        const {
          ticket: updatedTicket,
          messages
        } = reviewTicket(ticket, byUser);
        stores().crud.update('ticket')(updatedTicket);
        messages.forEach(message => stores().crud.create('message')(message));
        return updatedTicket;
      },
      publishProvisional: (ticket, byUser) => {
        const {
          ticket: updatedTicket,
          messages
        } = publishProvisionalTicket(ticket, byUser);
        stores().crud.update('ticket')(updatedTicket);
        messages.forEach(message => stores().crud.create('message')(message));
        return updatedTicket;
      },
      publish: (ticket, byUser) => {
        const {
          ticket: updatedTicket,
          messages
        } = publishTicket(ticket, byUser);
        stores().crud.update('ticket')(updatedTicket);
        messages.forEach(message => stores().crud.create('message')(message));
        return updatedTicket;
      },
      addMessage: (ticket, message, byUser) => {
        const m = createMessage({
          ticketId: ticket._id,
          text: message,
          userId: byUser._id
        });
        stores().crud.create('message')(m);
        return m;
      }
    })
  },
  helpers: {
    ticket: ({
      createdAt,
      createdBy,
      acceptedAt,
      acceptedBy,
      rejectedAt,
      rejectedBy,
      approvedAt,
      approvedBy,
      reviewedAt,
      reviewedBy,
      publishedAt,
      publishedBy,
      status,
      _id,
      title,
      certificationId
    }, stores) => {
      const labels = {
        created: () => `Aangevraagd op ${(0, _dateFns.format)(new Date(createdAt), 'dd/MM')}`,
        accepted: () => `In behandeling sinds ${(0, _dateFns.format)(new Date(acceptedAt), 'dd/MM')}`,
        rejected: () => `Afgekeurd op ${(0, _dateFns.format)(new Date(rejectedAt), 'dd/MM')}`,
        approved: () => `Goedgekeurd op ${(0, _dateFns.format)(new Date(approvedAt), 'dd/MM')}`,
        reviewed: () => `In behandeling ter controle sinds ${(0, _dateFns.format)(new Date(reviewedAt), 'dd/MM')}`,
        published: () => `Gepubliceerd op ${(0, _dateFns.format)(new Date(publishedAt), 'dd/MM')}`
      };
      const actors = {
        created: () => _users.users[createdBy] || stores().persons[createdBy],
        accepted: () => acceptedBy && (_users.users[acceptedBy] || stores().persons[acceptedBy]),
        rejected: () => rejectedBy && (_users.users[rejectedBy] || stores().persons[rejectedBy]),
        approved: () => approvedBy && (_users.users[approvedBy] || stores().persons[approvedBy]),
        reviewed: () => reviewedBy && (_users.users[reviewedBy] || stores().persons[reviewedBy]),
        published: () => publishedBy && (_users.users[publishedBy] || stores().persons[publishedBy])
      };
      const lifecycle = {
        created: createdAt,
        accepted: acceptedAt,
        rejected: rejectedAt,
        approved: approvedAt,
        reviewed: reviewedAt,
        published: publishedAt
      };
      return {
        author: () => actors.created(),
        acceptor: () => actors.accepted(),
        rejector: () => actors.rejected(),
        approver: () => actors.approved(),
        reviewer: () => actors.reviewed(),
        publisher: () => actors.published(),
        color: () => {
          if (status === 'approved' || status === 'reviewed') {
            return 'lightGreen';
          }

          if (status === 'rejected') {
            return 'orange';
          }

          if (status === 'published') {
            return 'green';
          }

          return 'blue';
        },
        labels,
        actors,
        statusLabel: () => labels[status](),
        actor: () => actors[status](),
        lifecycleChanges: () => Object.keys(lifecycle).reduce((memo, lc) => !lifecycle[lc] ? memo : [...memo, {
          actor: actors[lc](),
          label: labels[lc](),
          ts: lifecycle[lc]
        }], []),
        certification: () => {
          return stores().certifications[certificationId];
        },
        conversation: () => ({
          _id,
          title,
          messages: stores().helpers.all(stores().messages).filter(m => m.ticketId === _id).sort((a, b) => a.createdAt - b.createdAt).map(m => ({ ...m,
            ...(m.userId ? {
              user: _users.users[m.userId] || stores().persons[m.userId],
              time: (0, _dateFns.format)(m.createdAt, 'd/M HH:mm')
            } : {})
          }))
        })
      };
    },
    message: (message, stores) => ({})
  }
};
exports.default = _default;