import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { format } from 'date-fns';

import RequestIcon from '@material-ui/icons/LockOpen';
import NewIcon from '@material-ui/icons/FiberNew';
import CertificateIcon from '@material-ui/icons/AssignmentTurnedIn';

import { List, Avatar } from '@material-ui/core';

import { CardList, EmptyState } from '@solar/solar-react';

import { ContactCard, ProfileItem } from '@probeton/ui';

const ACLRequestCardList = ({ aclRequests, openACLRequest, emptyLabel = 'Geen aanvragen.' }) => {
  if (!aclRequests || aclRequests.length === 0) {
    return <EmptyState Icon={RequestIcon} label={emptyLabel} />;
  }
  return (
    <CardList items={R.sortBy(R.prop('createdAt'))(aclRequests)}>
      {({ _id, author, createdAt, grants, granter, certification }) => {
        const c = certification().certificate();
        const a = author();
        return (
          <ContactCard
            key={_id}
            avatarUrl={a.avatarUrl}
            fullName={a.fullName}
            email={a.email}
            subheader={a.company}
            onClick={() => openACLRequest(_id)}
          >
            <List>
              <ProfileItem
                icon={<CertificateIcon />}
                text={c.renderTitle()}
                textLabel={c.renderCodes()}
              />
              <ProfileItem
                {...(grants
                  ? {
                      icon: <Avatar src={granter().avatarUrl} />,
                      text: `${grants.granted ? 'goedgekeurd' : 'afgekeurd'} op ${format(
                        new Date(grants.grantedAt),
                        'yyyy.MM.dd',
                      )}`,
                    }
                  : {
                      icon: <NewIcon />,
                      text: `aangevraagd op ${format(new Date(createdAt), 'yyyy.MM.dd')}`,
                    })}
              />
            </List>
          </ContactCard>
        );
      }}
    </CardList>
  );
};

ACLRequestCardList.propTypes = {
  className: PropTypes.string,
  aclRequests: PropTypes.array,
};

ACLRequestCardList.defaultProps = {
  className: '',
  aclRequests: [],
};

export default ACLRequestCardList;
