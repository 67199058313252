import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Flex } from '@solar/solar-react';

import Button from '@material-ui/core/Button';

const TicketActions = ({
  onAccept,
  onReject,
  onApprove,
  onReview,
  onPublishProvisional,
  onPublish,
  ticket,
  user,
}) => {
  const { status } = ticket;
  const { _id: userId, auth } = user;
  const isAdmin = auth.includes('probeton');
  const isInspector = auth.includes('inspector');
  const isRep = auth.includes('representative');

  const canAccept = status === 'created' && isInspector;
  const canReject = (status === 'accepted' && isInspector) || (status === 'reviewed' && isAdmin);
  const canApprove = (status === 'accepted' || status === 'rejected') && isInspector;
  const canReview = status === 'approved' && isAdmin;
  const canPublishProvisional = status === 'reviewed' && isAdmin;
  const canPublish = (status === 'reviewed' || status === 'published_provisional') && isAdmin;

  return (
    <Flex justifyContent="center">
      {canAccept ? (
        <center>
          <Button
            variant="outlined"
            color="primary"
            style={{ margin: 16 }}
            onClick={() => onAccept()}
          >
            Accepteren
          </Button>
        </center>
      ) : (
        ''
      )}
      {canReject ? (
        <center>
          <Button
            variant="outlined"
            color="primary"
            style={{ margin: 16 }}
            onClick={() => onReject()}
          >
            Afwijzen
          </Button>
        </center>
      ) : (
        ''
      )}
      {canApprove ? (
        <center>
          <Button
            variant="outlined"
            color="primary"
            style={{ margin: 16 }}
            onClick={() => onApprove()}
          >
            Goedkeuren
          </Button>
        </center>
      ) : (
        ''
      )}
      {canReview ? (
        <center>
          <Button
            variant="outlined"
            color="primary"
            style={{ margin: 16 }}
            onClick={() => onReview()}
          >
            Opnemen ter controle
          </Button>
        </center>
      ) : (
        ''
      )}
      {canPublishProvisional ? (
        <center>
          <Button
            variant="outlined"
            color="primary"
            style={{ margin: 16 }}
            onClick={() => onPublishProvisional()}
          >
            Publiceren onder voorbehoud
          </Button>
        </center>
      ) : (
        ''
      )}
      {canPublish ? (
        <center>
          <Button
            variant="outlined"
            color="primary"
            style={{ margin: 16 }}
            onClick={() => onPublish()}
          >
            Publiceren
          </Button>
        </center>
      ) : (
        ''
      )}
    </Flex>
  );
};

TicketActions.propTypes = {
  onAccept: PropTypes.func,
  onReject: PropTypes.func,
  onApprove: PropTypes.func,
  onReview: PropTypes.func,
  onPublish: PropTypes.func,
  ticket: PropTypes.object,
  user: PropTypes.object,
};

export default TicketActions;
