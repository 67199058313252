import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core';

import darkTheme from './style/darkTheme';

// Wraps children in the dark theme (extended from Material UI base dark theme)

function DarkMuiThemeProvider({ children, ...other }) {
  return (
    <MuiThemeProvider theme={darkTheme} {...other}>
      {children}
    </MuiThemeProvider>
  );
}

DarkMuiThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DarkMuiThemeProvider;
