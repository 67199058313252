import React from 'react';
import PropTypes from 'prop-types';

import SubscribeData from '../../../SubscribeData';

import { CoverTabView } from '@solar/poc-ui';

import EmployeeInfo from './EmployeeInfo';

const EmployeeSheet = ({ history, user, id, handleDetailViewToggle, ...other }) => {
  return (
    <SubscribeData>
      {({ employees, tickets, helpers: { get, all } }) => {
        const employee = get(employees, id);
        const eqTickets = all(tickets).filter((t) => t.employeeId === employee._id);
        const { title, coverSrc } = employee;
        const tabs = {
          info: {
            label: 'Info',
            component: <EmployeeInfo employee={employee} />,
          },
          tickets: {
            label: 'Tickets',
            component: () => 'no tickets',
          },
        };
        return <CoverTabView title={title} cover={coverSrc} tabs={tabs} />;
      }}
    </SubscribeData>
  );
};

EmployeeSheet.propTypes = {
  history: PropTypes.object,
  user: PropTypes.object,
  id: PropTypes.string,
};

export default EmployeeSheet;
