"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = require("react");

var useParsedJson = function useParsedJson(jsonString) {
  // keep a stable reference to the previous valid parsed state
  var ref = (0, _react.useRef)({});
  return (0, _react.useMemo)(function () {
    if (jsonString === undefined || typeof jsonString !== 'string' || !jsonString || jsonString === '' || jsonString === '{}') {
      return [null, true];
    }

    try {
      var parsedJson = JSON.parse(jsonString);
      ref.current = parsedJson;
      return [ref.current, true];
    } catch (e) {
      // console.log('error', jsonString);
      // console.log(e);
      return [ref.current, false];
    }
  }, [jsonString]);
};

var _default = useParsedJson;
exports["default"] = _default;