import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import { memoizeWith, identity } from 'ramda';
import Fuse from 'fuse.js';

import Typography from '@material-ui/core/Typography';

import { Flex, ScrollableContent } from '@solar/solar-react';

import { ResponsiveSearchField } from '@probeton/solar-react';

import SubscribeData from '../../../SubscribeData';
import { TabView } from '@probeton/solar-react';

import TicketCardList from './TicketCardList';

export const TicketSearch = ({
  tickets,
  onCreateCertificate,
  title = 'Certificaataanpassingen',
  tabs,
  ...other
}) => {
  const [tabIndex, setTabIndex] = useState(Object.keys(tabs)[0]);
  const fuseSearchMemoized = useMemo(() => {
    const options = {
      keys: ['title', 'status', 'manufacturer', 'certificate'],
      shouldSort: true,
      threshold: 0.3,
    };
    const fuse = new Fuse(tickets, options);
    return memoizeWith(identity, (search) => fuse.search(search));
  }, [tickets.length]);

  const [search, setSearch] = useState('');
  const filteredTickets = search === '' ? tickets : fuseSearchMemoized(search);
  const selectedTickets = filteredTickets.filter(({ status }) =>
    tabs[tabIndex].statuses.includes(status),
  );

  return (
    <TabView
      fullWidth={false}
      indicatorColor="secondary"
      appBarProps={{
        title: (
          <Flex alignItems="center" justifyContent="space-between" flexGrow={1}>
            <Typography variant="headline" color="inherit" noWrap>
              {title}
            </Typography>
            <ResponsiveSearchField
              InputProps={{
                id: 'searchTickets',
              }}
              placeholder="Zoeken"
              value={search}
              onChange={(value) => setSearch(value)}
            />
          </Flex>
        ),
      }}
      tabs={tabs}
      activeTab={tabIndex}
      onTabChange={(event, tab) => {
        setTabIndex(tab);
      }}
    >
      <ScrollableContent>
        <TicketCardList tickets={selectedTickets} {...other} />
      </ScrollableContent>
    </TabView>
  );
};

const TicketView = (props) => {
  return (
    <SubscribeData>
      {({ tickets, helpers: { all } }) => {
        return (
          <TicketSearch
            tickets={all(tickets)
              .filter(({ certificationId }) => !!certificationId)
              .map(({ certification, ...other }) => ({
                ...other,
                certification,
                certificate: certification().certificate().renderTitle(),
                manufacturer: certification().manufacturer().name,
              }))}
            {...props}
          />
        );
      }}
    </SubscribeData>
  );
};

TicketView.propTypes = {
  openCertificate: PropTypes.func,
};

export default TicketView;
