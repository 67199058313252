import React from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';

import SubscribeData from '../../../SubscribeData';

import { makeStyles } from '@material-ui/core/styles';

import { IconButton } from '@material-ui/core';

import CertificateIcon from '@material-ui/icons/AssignmentTurnedIn';

import { Flex, ChatDivider } from '@solar/solar-react';

import { TabView } from '@probeton/solar-react';

import TicketInfo from './TicketInfo';
import TicketChat from './TicketChat';
import ChatDocument from './ChatDocument';
import ChatStatus from './ChatStatus';

const useStyles = makeStyles((theme) => {
  return {
    root: {},
    wrapper: {
      width: '100%',
    },
  };
});
const TicketSheet = ({
  history,
  user,
  id,
  handleDetailViewToggle,
  openDialog,
  setPdfOpen,
  setImageOpen,
  ...other
}) => {
  const classes = useStyles();
  return (
    <SubscribeData>
      {({
        tickets,
        addMessage,
        acceptTicket,
        rejectTicket,
        approveTicket,
        reviewTicket,
        publishProvisionalTicket,
        publishTicket,
        helpers: { get, all },
      }) => {
        const ticket = tickets[id];
        if (!ticket) {
          return '404';
        }

        const { statusLabel, edits } = ticket;

        const actionProps = {
          onAccept: () => {
            acceptTicket(ticket, user);
          },
          onReject: () => {
            rejectTicket(ticket, user);
          },
          onApprove: () => {
            approveTicket(ticket, user);
          },
          onReview: () => {
            reviewTicket(ticket, user);
          },
          onPublishProvisional: () => {
            publishProvisionalTicket(ticket, user);
          },
          onPublish: () => {
            publishTicket(ticket, user);
          },
        };

        const tabs = {
          info: {
            label: 'Info',
            component: (
              <TicketInfo
                ticket={ticket}
                user={user}
                actionProps={actionProps}
                openFabricate={(id) => handleDetailViewToggle('fabricate', id)}
                openFabricateEdits={(id) =>
                  openDialog('fabricateTechSheetEdits', null, { propsForUrl: { fabricateId: id } })
                }
              />
            ),
          },
          chat: {
            label: 'Chat',
            component: (
              <TicketChat
                user={user}
                ticket={ticket}
                componentMap={{
                  system: (message, right) => (
                    <ChatDivider key={message._id}>{message.system}</ChatDivider>
                  ),
                  files: (message, right) => (
                    <ChatDocument key={message._id} files={message.files} setPdfOpen={setPdfOpen} />
                  ),
                  status: (message, right) => <ChatStatus key={message._id} {...message} />,
                }}
                messages={ticket
                  .conversation()
                  .messages.map(({ createdAt, message, author, ...other }) => ({
                    ...other,
                    time: format(createdAt, 'd/M HH:mm'),
                  }))}
                onAddMessage={(m) => addMessage(ticket, m, user)}
                actionProps={actionProps}
              />
            ),
            dontWrap: true,
          },
        };

        return (
          <TabView
            appBarProps={{
              title: `${statusLabel()} - ${edits.length} aanpassingen`,
              titleOnTop: false,
              extraToolbar: (
                <Flex className={classes.wrapper}>
                  <div style={{ flexGrow: 2 }} />
                  <IconButton
                    onClick={(event) => {
                      handleDetailViewToggle('certificate', ticket.certificationId);
                    }}
                    style={{ color: 'white' }}
                  >
                    <CertificateIcon style={{ color: 'inherit' }} />
                  </IconButton>
                </Flex>
              ),
            }}
            tabs={tabs}
            default="info"
          />
        );
      }}
    </SubscribeData>
  );
};

TicketSheet.propTypes = {
  history: PropTypes.object,
  user: PropTypes.object,
  id: PropTypes.string,
};

export default TicketSheet;
