import React from 'react';
import PropTypes from 'prop-types';

import Badge from '@material-ui/core/Badge';

import CertificateIcon from '@material-ui/icons/AssignmentTurnedIn';
import ChangeIcon from '@material-ui/icons/Edit';

import SupervisedUserIcon from '@material-ui/icons/SupervisedUserCircle';
import CustomerIcon from '@material-ui/icons/Face';
import RequestIcon from '@material-ui/icons/LockOpen';

import { NavigationDrawer } from '@probeton/ui';

import DarkMuiThemeProvider from './DarkMuiThemeProvider';
import SubscribeData from '../../SubscribeData';

import { navGroups as commonNavGroups } from './CommonNav';

const navGroups = (changeBadge = 2, unit) => {
  return [
    {
      links: [...commonNavGroups],
    },
    {
      links: [
        {
          label: unit.manufacturer().name,
          to: '/manufacturer',
          exact: true,
        },
        { icon: <SupervisedUserIcon />, label: 'Gebruikers', to: '/users', exact: true },
        { icon: <CertificateIcon />, label: 'Certificaten', to: '/certificates', exact: true },
        { icon: <CustomerIcon />, label: 'Klanten', to: '/customers', exact: true },
        {
          icon: (
            <Badge badgeContent={changeBadge} color="primary">
              <RequestIcon />
            </Badge>
          ),
          label: 'Aanvragen',
          to: '/aclRequest',
          exact: true,
        },
      ],
    },
  ];
};

const AppNavigationDrawer = ({ unit, ...props }) => {
  return (
    <SubscribeData>
      {({ aclRequests, helpers: { all } }) => {
        // TODO filter by current unit(s) of rep
        const allNew = all(aclRequests).filter(({ grants }) => !grants);
        return (
          <NavigationDrawer
            {...props}
            navGroups={navGroups(allNew.length, unit)}
            WrapperComponent={DarkMuiThemeProvider}
            app="Representative"
          />
        );
      }}
    </SubscribeData>
  );
};

AppNavigationDrawer.propTypes = {
  onClickLogout: PropTypes.func.isRequired,
  onDrawerRequestClose: PropTypes.func.isRequired,
  onDrawerRequestOpen: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  profile: PropTypes.object,
};

AppNavigationDrawer.defaultProps = {
  profile: null,
};

export default AppNavigationDrawer;
