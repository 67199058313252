import React from 'react';
import PropTypes from 'prop-types';

import { ProductSheet as CommonProductSheet } from '@probeton/ui';

import SubscribeData from '../../../SubscribeData';
import { certificateMapper } from '../Manufacturer/helpers';

const t = {
  confirmAction: {
    body: 'Dit kan niet ongedaan gemaakt worden.',
    cancelLabel: 'Annuleren',
    confirmLabel: 'Ok',
    title: 'Ben je zeker?',
  },
  info: {
    info: {
      subTitle: 'Titel',
      title: 'Info',
      validity: 'Aantal maanden geldig',
    },
    keywords: 'Zoekwoorden',
    labels: 'Product Types',
    refDocs: 'Referentiedocumenten',
  },
  tabs: {
    certificates: 'Certificaten',
    info: 'Info',
    data: 'Data',
  },
  title: 'Producten',
  certificates: {
    attest: 'attest',
    productTypes: 'Producttypes',
    table: {
      certificate: 'Certificaat',
      manufacturer: 'Fabrikant',
      unit: 'Productiezetel',
    },
  },
  validity: {
    inOpschorting: 'in opschorting vanaf {{date}}',
    inToelating: 'in toelatingsperiode',
    inValid: 'certificaat was geldig van {{date}}',
    valid: 'certificaat geldig van {{date}}',
  },
  data: {
    empty: 'Nog geen data-structuren voorzien voor dit product',
  },
};

const ProductSheet = ({ id, history, confirmAction, openSnack, ...other }) => {
  return (
    <SubscribeData>
      {({ certificates, editCertificate, helpers: { get } }) => {
        const c = get(certificates, id);
        const product = certificateMapper(c);

        return (
          <CommonProductSheet
            id={id}
            product={product}
            openCertificate={(id) => history.push(`/certificates/${id}`)}
            ProductKeywordProps={{
              keywords: [],
            }}
            openDataEditor={(id) => history.push(`/products/${id}`)}
            onPublishDataChanges={({ publishedFormSchema, config }) => {
              if (!config) {
                confirmAction({
                  ...t.confirmAction,
                  body:
                    'Wanneer je de data structuren voor dit product publiceert, worden fabrikanten met een certificaat voor dit product op de hoogte gebracht. Ze kunnen vanaf dat moment fabrikaten aanmaken volgens deze data structuur. Dit kan niet meer ongedaan gemaakt worden',
                })
                  .then(() => {
                    editCertificate(c, { publishedFormSchema });
                    openSnack(
                      'De data structuren werden gepubliceerd en fabrikanten werden op de hoogte gebracht.',
                    );
                  })
                  .catch((err) => {});
              } else {
                editCertificate(c, { publishedFormSchema, publicationConfig: config });
                openSnack(
                  config.notify
                    ? 'De data structuren werden aangepast en fabrikanten werden op de hoogte gebracht.'
                    : 'De data structuren werden aangepast.',
                );
              }
            }}
            labels={{
              tabs: t.tabs,
              info: t.info,
              attest: t.certificates.attest,
              productTypes: t.certificates.productTypes,
              validity: (key) => t.validity[key],
              table: t.certificates.table,
              data: t.data,
            }}
            // editProduct={editCertificate}
            // createProduct={createCertificate}
            {...other}
          />
        );
      }}
    </SubscribeData>
  );
};

ProductSheet.propTypes = {
  history: PropTypes.object,
  user: PropTypes.object,
  id: PropTypes.string,
};

export default ProductSheet;
