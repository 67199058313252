import React, { Fragment } from 'react';

import { Route, Switch } from 'react-router-dom';

import SearchIcon from '@material-ui/icons/Search';
import HeartIcon from '@material-ui/icons/Favorite';
import DownloadIcon from '@material-ui/icons/GetApp';
import RequestIcon from '@material-ui/icons/LockOpen';
import ProfileIcon from '@material-ui/icons/AssignmentInd';

import Search from './Search/Search';
import DownloadView from './Certificate/DownloadView';
import ACLRequestView from './Certificate/ACLRequestView';
import ProfileView from './ProfileView';

export const navGroups = [
  { icon: <SearchIcon />, label: 'ProductCatalogus', to: '/search', exact: true },
  { icon: <HeartIcon />, label: 'mijn favorieten', to: '/search?favourites=1', exact: true },
  { icon: <DownloadIcon />, label: 'mijn downloads', to: '/mydownloads', exact: true },
  { icon: <RequestIcon />, label: 'mijn aanvragen', to: '/myrequests', exact: true },
  { icon: <ProfileIcon />, label: 'mijn profiel', to: '/myprofile', exact: true },
];

export const CommonRoutes = ({
  history,
  handleDetailViewToggle,
  handleDialog,
  children,
  user,
  setImageOpen,
  setPdfOpen,
}) => (
  <Switch>
    <Route
      path="/search"
      exact
      render={(props) => (
        <Search
          noWallPaper
          history={history}
          openProduct={(id) => handleDetailViewToggle('fabricate', id)}
          user={user}
          {...props}
        />
      )}
    />
    <Route
      path="/mydownloads"
      exact
      render={(props) => (
        <DownloadView user={user} setImageOpen={setImageOpen} setPdfOpen={setPdfOpen} {...props} />
      )}
    />
    <Route
      path="/myrequests"
      exact
      render={(props) => (
        <ACLRequestView
          user={user}
          openProduct={(id) => handleDetailViewToggle('fabricate', id)}
          {...props}
        />
      )}
    />
    <Route path="/myprofile" exact render={(props) => <ProfileView user={user} {...props} />} />
    {children}
  </Switch>
);
