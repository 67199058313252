// @flow

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { makeStyles } from '@material-ui/core/styles';

import { Rounded } from '@solar/solar-react';

import { blueGrey, lightGreen, green, orange } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      ...theme.typography.subheading,
      display: 'block',
      padding: `${theme.increments.typography}px ${theme.increments.typography * 3}px`,
      backgroundColor: theme.palette.background.default,
      whiteSpace: 'pre-wrap',
      marginBottom: 4,
    },
    colorInherit: {
      color: 'inherit',
    },
    colorPrimary: {
      backgroundColor: theme.palette.primary[100],
      color: theme.palette.getContrastText(theme.palette.primary[100]),
    },
    colorSecondary: {
      backgroundColor: theme.palette.secondary[100],
      color: theme.palette.getContrastText(theme.palette.secondary[100]),
    },
    colorblue: {
      backgroundColor: blueGrey[100],
      color: theme.palette.getContrastText(blueGrey[100]),
    },
    colorlightGreen: {
      backgroundColor: lightGreen[100],
      color: theme.palette.getContrastText(lightGreen[100]),
    },
    colorgreen: {
      backgroundColor: green[100],
      color: theme.palette.getContrastText(green[100]),
    },
    colororange: {
      backgroundColor: orange[100],
      color: theme.palette.getContrastText(orange[100]),
    },
  };
});

const ChatStatus = ({ align, className, status, prevStatus, msg, ...other }) => {
  const classes = useStyles();
  const color = () => {
    if (status === 'approved' || status === 'reviewed') {
      return 'lightGreen';
    }

    if (status === 'rejected') {
      return 'orange';
    }
    if (status === 'published') {
      return 'green';
    }
    return 'blue';
  };
  return (
    <Rounded
      align={align}
      className={classNames(classes.root, className, classes[`color${color()}`])}
      {...other}
    >
      {msg}
    </Rounded>
  );
};

ChatStatus.propTypes = {
  align: PropTypes.string,
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
};

ChatStatus.defaultProps = {
  className: '',
};

// prettier-ignore
export default ChatStatus;
