import React from 'react';

import { Redirect, Route } from 'react-router-dom';

import sheets from '../../sheets';
import dialogs from '../../dialogs';

import { App } from '@probeton/ui';

import NavigationDrawerCustomer from './NavigationDrawerCustomer';

import { CommonRoutes } from './CommonNav';

const CustomerApp = ({ history, user, ...other }) => (
  <App
    history={history}
    user={user}
    app="CUSTOMER"
    sheets={sheets}
    dialogs={dialogs}
    navigationDrawer={NavigationDrawerCustomer}
    {...other}
  >
    {({ handleDetailViewToggle, handleDialog, setImageOpen, setPdfOpen }) => (
      <CommonRoutes
        handleDetailViewToggle={handleDetailViewToggle}
        handleDialog={handleDialog}
        history={history}
        user={user}
        setImageOpen={setImageOpen}
        setPdfOpen={setPdfOpen}
      >
        <Route path="/" exact render={() => <Redirect to="/search" />} />
      </CommonRoutes>
    )}
  </App>
);

// prettier-ignore
export default CustomerApp;
