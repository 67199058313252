"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.endeavour = exports.hollywoodcerise = exports.lima = exports.probetonBlue = void 0;
var probetonBlue = {
  50: '#3BB2DD',
  100: '#3BB2DD',
  200: '#3BB2DD',
  300: '#3BB2DD',
  400: '#3BB2DD',
  500: '#3BB2DD',
  600: '#3BB2DD',
  700: '#3BB2DD',
  800: '#3BB2DD',
  900: '#3BB2DD',
  A100: '#3BB2DD',
  A200: '#3BB2DD',
  A400: '#3BB2DD',
  A700: '#3BB2DD',
  contrastDefaultColor: 'dark',
  contrastText: '#ffffff'
};
exports.probetonBlue = probetonBlue;
var lima = {
  50: '#eef9e3',
  100: '#d5f0b9',
  200: '#bae78a',
  300: '#9edd5b',
  400: '#89d537',
  500: '#74ce14',
  600: '#6cc912',
  700: '#61c20e',
  800: '#57bc0b',
  900: '#44b006',
  A100: '#e6ffdb',
  A200: '#c3ffa8',
  A400: '#9fff75',
  A700: '#8eff5b',
  contrastDefaultColor: 'light'
};
exports.lima = lima;
var hollywoodcerise = {
  50: '#fde0f1',
  100: '#f9b3dd',
  200: '#f680c6',
  300: '#f24daf',
  400: '#ef269d',
  500: '#ec008c',
  600: '#ea0084',
  700: '#e70079',
  800: '#e4006f',
  900: '#df005c',
  A100: '#ffffff',
  A200: '#ffd3e2',
  A400: '#ffa0c1',
  A700: '#ff86b0',
  contrastDefaultColor: 'light'
};
exports.hollywoodcerise = hollywoodcerise;
var endeavour = {
  50: '#e0ebf3',
  100: '#b3cce2',
  200: '#80abce',
  300: '#4d89ba',
  400: '#266fac',
  500: '#00569d',
  600: '#004f95',
  700: '#00458b',
  800: '#003c81',
  900: '#002b6f',
  A100: '#9ebbff',
  A200: '#6b98ff',
  A400: '#3874ff',
  A700: '#1f62ff',
  contrastDefaultColor: 'light',
  contrastText: 'light'
};
exports.endeavour = endeavour;