"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.users = exports.mutations = exports.helpers = exports.generators = void 0;

var _faker = _interopRequireDefault(require("faker"));

var _users = _interopRequireDefault(require("./users"));

var _certificates = _interopRequireDefault(require("./certificates"));

var _manufacturers = _interopRequireDefault(require("./manufacturers"));

var _products = _interopRequireDefault(require("./products"));

var _userdata = _interopRequireDefault(require("./userdata"));

var _tickets = _interopRequireDefault(require("./tickets"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

// import admins from './admins';
_faker.default.locale = 'nl';
const generators = { ..._certificates.default.generators,
  ..._manufacturers.default.generators,
  ..._products.default.generators,
  ..._userdata.default.generators,
  ..._tickets.default.generators
};
exports.generators = generators;
const helpers = { ..._certificates.default.helpers,
  ..._manufacturers.default.helpers,
  ..._products.default.helpers,
  ..._userdata.default.helpers,
  ..._tickets.default.helpers
};
exports.helpers = helpers;
const mutations = { ..._manufacturers.default.mutations,
  ..._certificates.default.mutations,
  ..._products.default.mutations,
  ..._userdata.default.mutations,
  ..._tickets.default.mutations
};
exports.mutations = mutations;
const users = _users.default;
exports.users = users;