import React from 'react';
import PropTypes from 'prop-types';

import Badge from '@material-ui/core/Badge';

import CertificateIcon from '@material-ui/icons/AssignmentTurnedIn';
import ManufacturerIcon from '@material-ui/icons/Business';
import SupervisedUserIcon from '@material-ui/icons/SupervisedUserCircle';
import TicketIcon from '@material-ui/icons/Edit';

import { NavigationDrawer } from '@probeton/ui';

import DarkMuiThemeProvider from '../ui/DarkMuiThemeProvider';
import SubscribeData from '../../SubscribeData';

import { navGroups as commonNavGroups } from './CommonNav';

const navGroups = (ticketBadge) => [
  { links: [...commonNavGroups] },
  {
    links: [
      {
        label: 'Probeton VZW',
        to: '/manufacturer',
        exact: true,
      },
      { icon: <SupervisedUserIcon />, label: 'Gebruikers', to: '/users', exact: true },
      { icon: <CertificateIcon />, label: 'Produkten', to: '/products', exact: true },
      { icon: <CertificateIcon />, label: 'Certificaten', to: '/certificates', exact: true },
      { icon: <ManufacturerIcon />, label: 'Fabrikanten', to: '/manufacturers', exact: true },
      {
        icon: (
          <Badge badgeContent={ticketBadge} color="primary">
            <TicketIcon />
          </Badge>
        ),
        label: 'Aanpassingen',
        to: '/tickets',
        exact: true,
      },
    ],
  },
];

const AppNavigationDrawer = ({ ...props }) => (
  <SubscribeData>
    {({ certifications, tickets, helpers: { all } }) => {
      const t = all(tickets).filter(({ status }) => status === 'approved');
      // console.log(certifications);
      // const openTickets = all(certifications).filter(({ status }) => status === 'created').length;
      return (
        <NavigationDrawer
          {...props}
          navGroups={navGroups(t.length)}
          WrapperComponent={DarkMuiThemeProvider}
          app="Beheerder"
        />
      );
    }}
  </SubscribeData>
);

AppNavigationDrawer.propTypes = {
  onClickLogout: PropTypes.func.isRequired,
  onDrawerRequestClose: PropTypes.func.isRequired,
  onDrawerRequestOpen: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  profile: PropTypes.object,
};

AppNavigationDrawer.defaultProps = {
  profile: null,
};

export default AppNavigationDrawer;
