// TODO: port to ui lib

import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

import { FabricatePermissionForm } from '@probeton/ui';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      // maxWidth: 345,
    },
    sheet: {
      margin: 12,
      marginLeft: 24,
      marginRight: 24,
      padding: 12,
    },
  };
});

const FabricatePermissions = ({ fabricate, formJSON, formUIJSON, onChange, ...other }) => {
  const classes = useStyles();
  return (
    <FabricatePermissionForm
      data={fabricate.data}
      formJSON={formJSON}
      formUIJSON={formUIJSON}
      onChange={({ errors, data }) => onChange({ lang: 'nl', data })}
      classes={{ root: classes.sheet }}
    />
  );
};

FabricatePermissions.propTypes = {
  fabricate: PropTypes.object.isRequired,
};

FabricatePermissions.defaulProps = {};

export default FabricatePermissions;
