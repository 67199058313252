import { createStore } from 'react-contextual';

import store from 'store2';

import { generators, helpers, mutations, users } from '@probeton/mockdata';

import { scope } from '@probeton/core';

async function asyncForEach(array, callback) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}

let stores;
const models = Object.keys(generators);
models.push('person');

export const fillDataStore = async () => {
  // console.log('filling dataStore');
  await asyncForEach(models, async (model) => {
    if (!store(`${scope}.${model}`) && generators[model]) {
      switch (model) {
        case 'manufacturer':
          const { manufacturers, persons } = await generators[model]();
          store(
            `${scope}.${model}`,
            manufacturers.reduce((memo, entity) => ({ ...memo, [entity._id]: entity }), {}),
          );
          store(`${scope}.person`, {
            ...(store(`${scope}.person`) || {}),
            ...persons.reduce((memo, entity) => ({ ...memo, [entity._id]: entity }), {}),
          });
          break;
        case 'unit':
          const { units, persons: mPersons } = await generators[model](
            store,
            Object.values(store(`${scope}.manufacturer`)),
          );
          store(
            `${scope}.${model}`,
            units.reduce((memo, entity) => ({ ...memo, [entity._id]: entity }), {}),
          );
          store(`${scope}.person`, {
            ...(store(`${scope}.person`) || {}),
            ...mPersons.reduce((memo, entity) => ({ ...memo, [entity._id]: entity }), {}),
          });
          break;
        case 'certification':
          const c = await generators[model](
            Object.values(store(`${scope}.manufacturer`)),
            Object.values(store(`${scope}.unit`)),
          );
          store(
            `${scope}.${model}`,
            c.reduce((memo, entity) => ({ ...memo, [entity._id]: entity }), {}),
          );
          break;
        case 'product':
          const p = await generators[model](Object.values(store(`${scope}.certification`)));
          // console.log(p);
          store(
            `${scope}.${model}`,
            p.reduce((memo, entity) => ({ ...memo, [entity._id]: entity }), {}),
          );
          break;
        case 'ticket':
          const inspectors = users.filter(({ auth }) => auth.includes('inspector'));
          const admins = users.filter(({ auth }) => auth.includes('probeton'));
          const { tickets, messages } = await generators[model](
            Object.values(store(`${scope}.certification`)),
            Object.values(store(`${scope}.unit`)),
            inspectors,
            admins,
            Object.values(store(`${scope}.person`)),
          );
          store(
            `${scope}.${model}`,
            tickets.reduce((memo, entity) => ({ ...memo, [entity._id]: entity }), {}),
          );
          store(
            `${scope}.message`,
            messages.reduce((memo, entity) => ({ ...memo, [entity._id]: entity }), {}),
          );
          break;
        default:
          const r = await generators[model]();
          store(
            `${scope}.${model}`,
            r.reduce((memo, entity) => ({ ...memo, [entity._id]: entity }), {}),
          );
          break;
      }
    }
  });
  // console.log('done');
};

const createDataStore = () => {
  // read all data from localstorage, load in memory
  // and setup all the helpers for in-memory data-store
  stores = models.reduce(
    (memo, model) => {
      const s = store(`${scope}.${model}`);
      if (s) {
        return {
          ...memo,
          [`${model}s`]: Object.keys(s).reduce((memo2, key) => {
            const entity = s[key];
            return {
              ...memo2,
              [key]: helpers[model]
                ? {
                    ...entity,
                    ...(helpers[model] ? helpers[model](entity, () => stores) : {}),
                  }
                : entity,
            };
          }, {}),
        };
      }
    },
    {
      helpers: {
        get: (s, id) => {
          if (!id) {
            return null;
          }
          if (id instanceof Array) {
            return id.map((i) => s[i]);
          }
          return s[id];
        },
        all: (s) => Object.values(s),
      },
      mutations: {
        // addTicket: (ticket, createdBy) => {
        //   const addedTicket = mutations.ticket(() => stores).create(ticket, createdBy);
        //   return addedTicket;
        // },
      },
      crud: {
        create: (model) => (entity) => {
          // console.log(`creating ${model}`, entity._id);
          const collection = store(`${scope}.${model}`);
          store(`${scope}.${model}`, {
            ...collection,
            [entity._id]: entity,
          });
          const hydratedCollection = stores[`${model}s`];
          stores[`${model}s`] = {
            ...hydratedCollection,
            [entity._id]: {
              ...entity,
              ...(helpers[model] ? helpers[model](entity, () => stores) : {}),
            },
          };
          return stores[`${model}s`];
        },
        update: (model) => (entity) => {
          const collection = store(`${scope}.${model}`);
          store(`${scope}.${model}`, {
            ...collection,
            [entity._id]: entity,
          });
          const hydratedCollection = stores[`${model}s`];
          stores[`${model}s`] = {
            ...hydratedCollection,
            [entity._id]: {
              ...entity,
              ...(helpers[model] ? helpers[model](entity, () => stores) : {}),
            },
          };
        },
        delete: (model) => (entity) => {
          const collection = store(`${scope}.${model}`);
          store(
            `${scope}.${model}`,
            Object.keys(collection).reduce(
              (memo, id) => ({ ...memo, ...(id !== entity._id ? { [id]: collection[id] } : {}) }),
              {},
            ),
          );
        },
      },
    },
  );

  // console.log(stores);

  // const muts = Object.keys(mutations);

  return createStore({
    ...stores,
    user: store(`${scope}.accounts.currentUser`),
    // reset: () => {
    //   models.forEach(model => {
    //     store(`${scope}.${model}`, null);
    //   });
    //   // location.reload();
    //   return { tickets: [], messages: [] };
    // },
    createdId: null,
    createCertificate: (certificate, createdBy) => {
      mutations.certificate(() => stores).create(certificate, createdBy);
      return { certificates: stores.certificates };
    },
    editCertificate: (certificate, edits, editedBy) => {
      mutations.certificate(() => stores).edit(certificate, edits, editedBy);
      return { certificates: stores.certificates };
    },
    editCertification: (certification, edits, editedBy) => {
      mutations.certification(() => stores).edit(certification, edits, editedBy);
      return { certifications: stores.certifications };
    },
    changeCertificationACL: (certification, acl, editedBy) => {
      // console.log({ certification, acl, editedBy });
      mutations.certification(() => stores).editACL(certification, acl, editedBy);
      return { certifications: stores.certifications };
    },
    addProduct: (certification, product, editedBy) => {
      const p = mutations.product(() => stores).create(certification, product, editedBy, false);
      mutations
        .certification(() => stores)
        .edit(certification, { fabricateId: p._id }, editedBy, 'productAdd');
      return { products: stores.products, certifications: stores.certifications, createdId: p._id };
    },
    editProduct: (product, edits, editedBy) => {
      mutations.product(() => stores).edit(product, edits, editedBy);
      return { products: stores.products };
    },
    duplicateProduct: (product, editedBy, amount = 1) => {
      mutations.product(() => stores).duplicate(product, editedBy, amount);
      return { products: stores.products };
    },
    setFavourite: (productId, favourite, forUser) => {
      const n = mutations.favourite(() => stores).set(productId, favourite, forUser);
      return { favourites: n };
    },
    addACLRequest: (certification, forUser) => {
      const aclRequest = mutations.aclRequest(() => stores).create(certification, forUser);
      return { aclRequests: stores.aclRequests, createdId: aclRequest._id };
    },
    grantACLRequest: (aclRequest, access, byUser) => {
      const granted = mutations.aclRequest(() => stores).grant(aclRequest, access, byUser);
      return { aclRequests: stores.aclRequests };
    },
    addDownload: (certification, document, forUser) => {
      const download = mutations.download(() => stores).create(certification, document, forUser);
      return { downloads: stores.downloads, createdId: download._id };
    },
    updateDownload: (download) => {
      const granted = mutations.download(() => stores).update(download);
      return { downloads: stores.downloads };
    },
    shareProfile: (certification, forUser) => {
      const share = mutations.share(() => stores).create(certification, forUser);
      return { shares: stores.shares, createdId: share._id };
    },
    createTicket: (certification, forUser) => {
      mutations.ticket(() => stores).create(certification, forUser);
      // remove unrequestedChanges
      mutations.certification(() => stores).editsBundled(certification);
      console.log(
        'creating ticket',
        certification
          .products()
          .filter(
            ({ edits }) =>
              edits && edits.length > 0 && !!edits.find(({ status }) => status === 'created'),
          ),
      );
      certification
        .products()
        .filter(
          ({ edits }) =>
            edits && edits.length > 0 && !!edits.find(({ status }) => status === 'created'),
        )
        .forEach((product) => {
          mutations.product(() => stores).editsBundled(product);
        });
      return {
        certifications: stores.certifications,
        products: stores.products,
        tickets: stores.tickets,
        messages: stores.messages,
      };
    },
    acceptTicket: (ticket, forUser) => {
      mutations.ticket(() => stores).accept(ticket, forUser);
      return { tickets: stores.tickets, messages: stores.messages };
    },
    rejectTicket: (ticket, forUser) => {
      mutations.ticket(() => stores).reject(ticket, forUser);
      return { tickets: stores.tickets, messages: stores.messages };
    },
    approveTicket: (ticket, forUser) => {
      mutations.ticket(() => stores).approve(ticket, forUser);
      return { tickets: stores.tickets, messages: stores.messages };
    },
    reviewTicket: (ticket, forUser) => {
      mutations.ticket(() => stores).review(ticket, forUser);
      return { tickets: stores.tickets, messages: stores.messages };
    },
    publishProvisionalTicket: (ticket, forUser) => {
      mutations.ticket(() => stores).publishProvisional(ticket, forUser);
      // TODO: persist bundled changes in certification / fabricates
      return { tickets: stores.tickets, messages: stores.messages };
    },
    publishTicket: (ticket, forUser) => {
      mutations.ticket(() => stores).publish(ticket, forUser);
      // TODO: persist bundled changes in certification / fabricates
      return { tickets: stores.tickets, messages: stores.messages };
    },
    addMessage: (ticket, message, forUser) => {
      mutations.ticket(() => stores).addMessage(ticket, message, forUser);
      return { messages: stores.messages };
    },
    // acceptTicket: (ticket, acceptedBy) => {
    //   mutations.ticket(() => stores).accept(ticket, acceptedBy);
    //   return { tickets: stores.tickets, messages: stores.messages };
    // },
    // startTicket: ticket => {
    //   mutations.ticket(() => stores).start(ticket);
    //   return { tickets: stores.tickets, messages: stores.messages };
    // },
    // completeTicket: ticket => {
    //   mutations.ticket(() => stores).complete(ticket);
    //   return { tickets: stores.tickets, messages: stores.messages };
    // },
    // evaluateTicket: (ticket, evaluatedBy) => {
    //   mutations.ticket(() => stores).evaluate(ticket, evaluatedBy);
    //   return { tickets: stores.tickets, messages: stores.messages };
    // },
    // profile: store(`${scope}.${props.user.username}.profile`) || {},
    // updateTicket: ticket => ({ tickets }) => {
    //   const updatedTickets = {
    //     ...tickets,
    //     [ticket._id]: { ...tickets[ticket._id], ...ticket },
    //   };
    //   store(`${scope}.ticket`, updatedTickets);
    //   return {
    //     tickets: Object.values(updatedTickets).reduce(
    //       (memo, entity) => ({
    //         ...memo,
    //         [entity._id]: {
    //           ...entity,
    //           ...(helpers.ticket ? helpers.ticket(entity, () => stores) : {}),
    //         },
    //       }),
    //       {},
    //     ),
    //   };
    // },
  });
};

export default createDataStore();
