"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _helpers = require("@probeton/helpers");

var _faker = _interopRequireDefault(require("faker"));

var _fixtures = require("../fixtures");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const createCertificate = (certificate, createdBy) => {
  certificate.createdBy = createdBy._id;

  const _id = certificate._id || (0, _helpers.uuid)();

  const createdAt = certificate.createdAt || new Date();
  return {
    certificate: {
      _id,
      createdAt,
      ...certificate,
      status: 'created'
    }
  };
};

const editCertificate = (certificate, edits, editedBy) => {
  const editedAt = new Date();
  console.log('stroing certificate changes', edits);
  return {
    certificate: { ...certificate,
      ...edits,
      // edits: [...(certificate.edits || []), { editedAt, editedBy, state: edits }],
      lastEdited: editedAt
    }
  };
};

var _default = {
  generators: {
    domain: async () => _fixtures.domains,
    categorie: async () => _fixtures.categories,
    certificate: async () => _fixtures.certificates.map(c => ({ ...c,
      labels: c.labels ? c.labels.reduce((memo, l) => ({ ...memo,
        [l.key]: l.title
      }), {}) : {},
      coverSrc: `https://picsum.photos/id/${(0, _helpers.rnd)(300)}/800/400`
    }))
  },
  mutations: {
    certificate: stores => ({
      create: (certificate, createdBy) => {
        const {
          certificate: newlyCreatedCertificate
        } = createCertificate(certificate, createdBy);
        stores().crud.create('certificate')(newlyCreatedCertificate);
        return newlyCreatedCertificate;
      },
      edit: (certificate, edits, editedBy) => {
        const {
          certificate: updatedCertificate
        } = editCertificate(certificate, edits, editedBy);
        stores().crud.update('certificate')(updatedCertificate);
        return updatedCertificate;
      }
    })
  },
  helpers: {
    certificate: (certificate, stores) => ({
      renderTitle: () => `${certificate._id}. ${certificate.title}`,
      renderCodes: () => certificate.codes ? certificate.codes.join(', ') : '',
      category: () => stores().categories[certificate.categoryId],
      certifications: () => Object.values(stores().certifications).filter(c => c.certificateId === certificate._id) // products: () => Object.values(stores().products).filter(p => p.certificateId === certificate._id),

    }),
    categorie: (categorie, stores) => ({
      domain: () => stores().domains[categorie.domainId],
      certificates: () => Object.values(stores().certificates).filter(c => c.categoryId === categorie._id)
    }),
    domain: (domain, stores) => ({
      categories: () => Object.values(stores().categories).filter(c => c.domainId === domain._id)
    })
  }
};
exports.default = _default;