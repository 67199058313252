import { dialogs as commonDialogs } from '@probeton/core';
import EditFabricateDialog from './app/ui/Fabricate/EditFabricateDialog';
import FabricateTechDialog from './app/ui/Fabricate/FabricateTechDialog';
import { FabricateTechEditsDialog } from './app/ui/Fabricate/FabricateTechDialog';
// import CreateTicketDialog from './app/ui/CreateTicketDialog';
// import AssignTicketDialog from './app/ui/AssignTicketDialog';
// import CompleteTicketDialog from './app/ui/CompleteTicketDialog';
// import EvaluateTicketDialog from './app/ui/EvaluateTicketDialog';

export default {
  ...commonDialogs,
  editFabricate: {
    Component: EditFabricateDialog,
  },
  fabricateTechSheet: {
    Component: FabricateTechDialog,
  },
  fabricateTechSheetEdits: {
    Component: FabricateTechEditsDialog,
  },
  // createTicket: {
  //   Component: CreateTicketDialog,
  // },
  // assignTicket: {
  //   Component: AssignTicketDialog,
  // },
  // completeTicket: {
  //   Component: CompleteTicketDialog,
  // },
  // evaluateTicket: {
  //   Component: EvaluateTicketDialog,
  // },
};
