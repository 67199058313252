"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _styles = require("@material-ui/core/styles");
var _colors = require("@material-ui/core/colors");
var _solarReact = require("@solar/solar-react");
var _colors2 = require("../colors");
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }
function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
var palette = _objectSpread({
  primary: _colors2.probetonBlue,
  secondary: _colors.orange,
  error: _colors.red,
  warning: _colors.orange,
  valid: _colors.lightGreen
}, _solarReact.extraColors);
var hilightText = function hilightText(theme) {
  return {
    marginTop: 16,
    marginBottom: 16,
    padding: 16,
    paddingTop: 0,
    borderLeft: 'solid 4px',
    borderColor: theme.palette.secondary[600]
  };
};
var _default = (0, _styles.createTheme)(_objectSpread({
  palette: palette,
  hilightText: hilightText,
  overrides: {
    SolarChatText: {
      root: {
        backgroundColor: _colors2.probetonBlue[800]
        // backgroundColor: 'black',
        // color: 'rgba(255, 255, 255, 0.7)',
      },

      colorPrimary: {
        backgroundColor: _colors2.probetonBlue[800],
        color: '#fff'
      }
    }
  }
}, _solarReact.themeBase));
exports["default"] = _default;