import React from 'react';
import PropTypes from 'prop-types';

import Badge from '@material-ui/core/Badge';

import CertificateIcon from '@material-ui/icons/AssignmentTurnedIn';
import ChangeIcon from '@material-ui/icons/Edit';

import SupervisedUserIcon from '@material-ui/icons/SupervisedUserCircle';

import { NavigationDrawer } from '@probeton/ui';

import SubscribeData from '../../SubscribeData';
import DarkMuiThemeProvider from './DarkMuiThemeProvider';
import { navGroups as commonNavGroups } from './CommonNav';

const navGroups = (changeBadge = 2, user) => {
  return [
    {
      links: [...commonNavGroups],
    },
    {
      links: [
        {
          label: 'Betoncontrole NV',
          to: '/inspector',
          exact: true,
        },
        { icon: <SupervisedUserIcon />, label: 'Gebruikers', to: '/users', exact: true },
        { icon: <CertificateIcon />, label: 'Certificaten', to: '/certificates', exact: true },
        {
          icon: (
            <Badge badgeContent={changeBadge} color="primary">
              <ChangeIcon />
            </Badge>
          ),
          label: 'Aanpassingen',
          to: '/tickets',
          exact: true,
        },
      ],
    },
  ];
};

const AppNavigationDrawer = ({ user, ...props }) => {
  return (
    <SubscribeData>
      {({ tickets, helpers: { all } }) => {
        const t = all(tickets).filter(({ status }) => status === 'created');
        return (
          <NavigationDrawer
            {...props}
            navGroups={navGroups(t.length, user)}
            user={user}
            WrapperComponent={DarkMuiThemeProvider}
            app="Inspector"
          />
        );
      }}
    </SubscribeData>
  );
};

AppNavigationDrawer.propTypes = {
  onClickLogout: PropTypes.func.isRequired,
  onDrawerRequestClose: PropTypes.func.isRequired,
  onDrawerRequestOpen: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  profile: PropTypes.object,
};

AppNavigationDrawer.defaultProps = {
  profile: null,
};

export default AppNavigationDrawer;
