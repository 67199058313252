"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _default = function _default(theme) {
  return {
    '@global': {
      html: {
        height: '100%',
        boxSizing: 'border-box'
      },
      '*, *:before, *:after': {
        boxSizing: 'inherit'
      },
      body: {
        position: 'fixed',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        margin: 0,
        background: '#fafafa',
        fontFamily: theme.typography.fontFamily,
        color: theme.palette.text.primary,
        lineHeight: '1.2',
        WebkitFontSmoothing: 'antialiased',
        // Antialiasing.
        MozOsxFontSmoothing: 'grayscale',
        // Antialiasing.
        '& > *': {
          height: '100%'
        }
      },
      a: {
        color: theme.palette.primary[800],
        textDecoration: 'none'
      },
      'a:hover': {
        textDecoration: 'underline'
      },
      img: {
        // maxWidth: '100%',
        // height: 'auto',
        // width: 'auto',
      }
    },
    root: {
      height: '100%'
    }
  };
};
exports["default"] = _default;