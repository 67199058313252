import { sheets as commonSheets } from '@probeton/core';

import EmployeeSheet from './app/ui/Employee/EmployeeSheet';
import ContactSheet from './app/ui/Contact/ContactSheet';
import ManufacturerSheet from './app/ui/Manufacturer/ManufacturerSheet';
import UnitSheet from './app/ui/Unit/UnitSheet';
import FabricateSheet from './app/ui/Fabricate/FabricateSheet';
import ProductSheet from './app/ui/Product/ProductSheet';
import CertificateSheet from './app/ui/Certificate/CertificateSheet';
import ACLRequestSheet from './app/ui/Certificate/ACLRequestSheet';
import CustomerSheet from './app/ui/Customer/CustomerSheet';
import TicketSheet from './app/ui/Ticket/TicketSheet';

// import AdminSheet from './app/ui/AdminSheet';

export default {
  ...commonSheets,
  employee: {
    Component: EmployeeSheet,
  },
  contact: {
    Component: ContactSheet,
  },
  manufacturer: {
    Component: ManufacturerSheet,
  },
  unit: {
    Component: UnitSheet,
  },
  fabricate: {
    Component: FabricateSheet,
  },
  product: {
    Component: ProductSheet,
  },
  certificate: {
    Component: CertificateSheet,
  },
  aclRequest: {
    Component: ACLRequestSheet,
  },
  customer: {
    Component: CustomerSheet,
  },
  ticket: {
    Component: TicketSheet,
  },
  // changeRequest: {
  //   Component: changeRequestSheet,
  // }
  // admin: {
  //   Component: AdminSheet,
  // },
};
