"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _googleGeocoder = _interopRequireDefault(require("google-geocoder"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var KEY = 'AIzaSyCSM46stvKY8VakTahd2zQOQXe3nGBcU1U';
var options = {
  key: KEY // for Mapquest, OpenCage, Google Premier

};
var geo = (0, _googleGeocoder["default"])(options);

var promiseFn = function promiseFn(location) {
  return new Promise(function (resolve, reject) {
    geo.find(location, function (err, data) {
      if (err) {
        reject(err);
      }

      resolve(data);
    });
  });
};

var _default = {
  geocode: promiseFn
};
exports["default"] = _default;