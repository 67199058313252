import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router';
import queryString from 'query-string';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import SubscribeData from '../../../SubscribeData';
import { FabricateEditContainer } from './EditFabricateSheet';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      width: '90vw',
      maxWidth: 1024,
    },
    formControl: {
      width: '100%',
    },
  };
});

const EditFabricateDialogContainer = ({ id, ...other }) => {
  return (
    <SubscribeData>
      {({ products, editProduct, duplicateProduct, helpers: { get, all } }) => {
        const fabricate = get(products, id);
        if (!fabricate) {
          return '404';
        }

        // return 'TODO';
        return (
          <>
            <FabricateEditContainer
              fabricate={{
                ...fabricate,
                ...fabricate.data,
                data: { name: fabricate.name, code: fabricate.code, ...fabricate.data },
              }}
              editFabricate={editProduct}
              id={id}
              duplicateProduct={duplicateProduct}
              {...other}
            />
          </>
        );
      }}
    </SubscribeData>
  );
};

const EditFabricateDialog = ({ open, onClose, currentUser, ...other }) => {
  const classes = useStyles();
  const history = useHistory();
  const r = useRef();
  const qs = queryString.parse(history.location.search);
  return (
    <Dialog open={open} onClose={onClose} classes={{ paper: classes.root }}>
      {open ? (
        <>
          <div className="actionbar-wrapper" ref={r} />
          <EditFabricateDialogContainer
            id={qs.fabricateId}
            {...other}
            user={currentUser}
            getActionBarContainer={() => {
              return r.current;
            }}
          />
        </>
      ) : (
        ''
      )}
    </Dialog>
  );
};

export default EditFabricateDialog;
