import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';

import { parse, addYears, isBefore, format } from 'date-fns';
import EditIcon from '@material-ui/icons/Edit';

import { List, Avatar, Button } from '@material-ui/core';
import { ProfileItem } from '@probeton/ui';
import { Header, EmptyState } from '@solar/solar-react';

import EditList from './EditList';

const useStyles = makeStyles((theme) => {
  return {
    root: {},
  };
});

const CertificationTicketList = ({
  tickets,
  unrequestedChanges,
  onCreateTicket,
  openTicket,
  openFabricate,
  openFabricateEdits,
}) => {
  const classes = useStyles();
  if ((!tickets && !unrequestedChanges) || tickets.length + unrequestedChanges.length === 0) {
    return (
      <EmptyState
        Icon={EditIcon}
        label="Er werden nog geen aanpassingen gedaan voor dit gamma fabricaten."
      />
    );
  }
  return (
    <Fragment>
      {unrequestedChanges.length > 0 ? (
        <Fragment>
          <Header>Recente aanpassingen</Header>
          <EditList
            edits={unrequestedChanges}
            openFabricate={openFabricate}
            openFabricateEdits={openFabricateEdits}
          />
          <div style={{ display: 'flex' }}>
            <div style={{ flexGrow: 2 }} />
            <Button onClick={() => onCreateTicket()}>
              Aanvraag publicatie aanpassingen opstarten
            </Button>
          </div>
        </Fragment>
      ) : (
        ''
      )}

      {tickets.filter(({ status }) => status !== 'published').length > 0 ? (
        <Fragment>
          <Header>lopende aanvragen</Header>
          <List>
            {tickets
              .filter(({ status }) => status !== 'published')
              .map(({ _id, author, statusLabel, edits }) => {
                return (
                  <ProfileItem
                    icon={<Avatar src={author().avatarUrl} />}
                    textLabel={statusLabel()}
                    text={`${author().person.fullName} - ${edits.length} aanpassingen`}
                    onClick={() => openTicket(_id)}
                  />
                );
              })}
          </List>
        </Fragment>
      ) : (
        ''
      )}
      {tickets.filter(({ status }) => status === 'published').length > 0 ? (
        <Fragment>
          <Header>Gepubliceerde aanpassingen</Header>
          <List>
            {tickets
              .filter(({ status }) => status === 'published')
              .map(({ _id, author, statusLabel, edits }) => {
                return (
                  <ProfileItem
                    icon={<Avatar src={author().avatarUrl} />}
                    textLabel={statusLabel()}
                    text={`${author().fullName} - ${edits.length} aanpassingen`}
                    onClick={() => openTicket(_id)}
                  />
                );
              })}
          </List>
        </Fragment>
      ) : (
        ''
      )}
    </Fragment>
  );
};

CertificationTicketList.propTypes = {
  className: PropTypes.string,
  tickets: PropTypes.array,
  unrequestedChanges: PropTypes.array,
};

CertificationTicketList.defaultProps = {
  className: '',
  tickets: [],
  unrequestedChanges: [],
};

export default CertificationTicketList;
