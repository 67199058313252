import React from 'react';
import PropTypes from 'prop-types';

import SubscribeData from '../../../SubscribeData';

import { ManufacturerSearch } from '@probeton/ui';

import { mapper } from './helpers';

const ManufacturerView = (props) => {
  return (
    <SubscribeData>
      {({ manufacturers, helpers: { all } }) => {
        const m = all(manufacturers);
        // return <pre>{JSON.stringify(m[0], null, 2)}</pre>;
        // console.log(m[0]);
        // console.log(mapper(m[0]));
        return <ManufacturerSearch manufacturers={m.map(mapper)} {...props} />;
      }}
    </SubscribeData>
  );
};

ManufacturerView.propTypes = {
  openManufacturer: PropTypes.func,
};

export default ManufacturerView;
