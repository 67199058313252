"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _dateFns = require("date-fns");

var _helpers = require("@probeton/helpers");

var _faker = _interopRequireDefault(require("faker"));

var _fixtures = require("../fixtures");

var _Geocode = require("../Geocode");

var _users = _interopRequireDefault(require("./users"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const KEY = 'AIzaSyD22tu03W7ov8a3X9L8NBPaeY2lDZ26cfQ';

const generatePerson = (role = 'representative') => ({
  _id: (0, _helpers.uuid)(),
  fullName: _faker.default.fake('{{name.firstName}} {{name.lastName}}'),
  avatarUrl: _faker.default.image.avatar(),
  email: _faker.default.internet.email(),
  username: _faker.default.internet.userName(),
  auth: [role]
});

const createCertification = (certification, createdBy) => {
  certification.createdBy = createdBy._id;

  const _id = certification._id || (0, _helpers.uuid)();

  const createdAt = certification.createdAt || new Date();
  return {
    certification: {
      _id,
      createdAt,
      ...certification,
      status: 'created',
      acl: {
        technicalSheet: 'PUBLIC',
        images: 'PUBLIC',
        documents: 'PUBLIC'
      },
      crmChanges: []
    }
  };
};

const editCertification = (certification, edits, editedBy, type = 'certificationEdit') => {
  const editedAt = new Date();
  return {
    certification: { ...certification,
      edits: [...(certification.edits || []), {
        _id: (0, _helpers.uuid)(),
        editedAt,
        editedBy,
        state: edits,
        type,
        status: 'created'
      }],
      lastEdited: editedAt
    }
  };
};

const bundleEdits = certification => {
  return {
    certification: { ...certification,
      edits: certification.edits.map(({
        status,
        ...other
      }) => ({ ...other,
        status: 'bundled'
      }))
    }
  };
};

const editCertificationACL = (certification, acl, editedBy) => {
  const editedAt = new Date();
  return {
    certification: { ...certification,
      acl,
      aclChangedAt: editedAt
    }
  };
};

const createCRMChange = (editedBy, change) => {
  return { ...change,
    createdAt: new Date(),
    createdBy: editedBy
  };
};

const generateManufacturers = async () => {
  const persons = [];
  return {
    manufacturers: Object.values(_fixtures.certifications.reduce((memo, {
      name
    }) => {
      const companyName = name.split(', ')[0];

      if (memo[companyName]) {
        return memo;
      }

      const user = generatePerson();
      persons.push(user);
      return { ...memo,
        [companyName]: {
          _id: (0, _helpers.uuid)(),
          name: companyName,
          contact: user._id,
          coverSrc: `https://picsum.photos/id/${(0, _helpers.rnd)(500)}/800/400`,
          logoSrc: `https://placeimg.com/800/400/tech?t=${(0, _helpers.rnd)(100)}` // website:

        }
      };
    }, {})),
    persons
  };
};

const generateUnits = async (store, manufacturers) => {
  const cachedLookup = (0, _Geocode.lookup)(store);
  const persons = [];
  const d = await _fixtures.certifications.reduce(async (previousPromise, {
    name,
    phone
  }) => {
    const memo = await previousPromise;
    const [companyName, unitName] = name.split(', ');

    if (memo[unitName]) {
      return memo;
    }

    const geo = await cachedLookup(name);
    const comp = manufacturers.find(({
      name
    }) => name === companyName);
    const p = generatePerson();
    persons.push(p);
    return { ...memo,
      [unitName]: {
        _id: (0, _helpers.uuid)(),
        name: unitName,
        contact: p._id,
        manufacturerId: comp._id,
        geo,
        representatives: [] // website:

      }
    };
  }, Promise.resolve({}));

  const reps = _users.default.filter(u => u.auth.includes('representative'));

  reps.forEach(rep => {
    // find a random unit and assign rep
    const unit = (0, _helpers.getRandomElement)(Object.keys(d));
    d[unit] = { ...d[unit],
      representatives: [rep._id]
    };
  });
  return {
    units: Object.values(d),
    persons
  };
};

const generateCertifications = async (manufacturers, units) => _fixtures.certifications.map(({
  name,
  phone,
  number,
  ...other
}) => {
  const [companyName, unitName] = name.split(', ');
  const comp = manufacturers.find(({
    name
  }) => name === companyName);
  const unit = units.find(({
    name
  }) => name === unitName);
  const key = number.replace('/', '-');
  return {
    _id: key || (0, _helpers.uuid)(),
    key,
    ...other,
    number,
    manufacturerId: comp._id,
    unitId: unit._id,
    pdfSrc: `/certificates/${(0, _helpers.getRandomElement)(_fixtures.certificatesPdfs)}.pdf`,
    technicalSheet: (0, _helpers.getRandomElement)(_fixtures.technicalPdfs),
    coverSrc: `https://placeimg.com/800/400/tech?t=${(0, _helpers.rnd)(100)}`,
    images: Array(...Array(0 + (0, _helpers.rnd)(3))).map(i => ({
      src: `https://placeimg.com/800/400/tech?t=${(0, _helpers.rnd)(100)}`,
      label: _faker.default.random.words(1 + (0, _helpers.rnd)(2))
    })),
    documents: [],
    acl: {
      technicalSheet: 'PUBLIC',
      images: 'PUBLIC',
      documents: 'PUBLIC'
    },
    crmChanges: []
  };
});

const p = d => (0, _dateFns.parse)(d, 'yyyy.MM.dd', new Date());

const expiry = d => (0, _dateFns.addYears)(p(d), 3);

const f = d => (0, _dateFns.format)(d, 'yyyy.MM.dd');

var _default = {
  generators: {
    manufacturer: generateManufacturers,
    unit: generateUnits,
    certification: generateCertifications
  },
  mutations: {
    certification: stores => ({
      create: (certification, createdBy) => {
        const {
          certification: newlyCreatedCertification
        } = createCertification(certification, createdBy);
        stores().crud.create('certification')(newlyCreatedCertification);
        return newlyCreatedCertification;
      },
      edit: (certification, edits, editedBy, type) => {
        const {
          certification: updatedCertification
        } = editCertification(certification, edits, editedBy, type);
        stores().crud.update('certification')(updatedCertification);
        return updatedCertification;
      },
      editsBundled: certification => {
        const {
          certification: updatedCertification
        } = bundleEdits(certification);
        stores().crud.update('certification')(updatedCertification);
        return updatedCertification;
      },
      editACL: (certification, acl, editedBy) => {
        const {
          certification: updatedCertification
        } = editCertificationACL(certification, acl, editedBy);
        stores().crud.update('certification')(updatedCertification);
        return updatedCertification;
      },
      addCRMChange: (certification, editedBy, change) => {
        const {
          certification: updatedCertification
        } = editCertification({ ...certification,
          crmChanges: [...certification.crmChanges, createCRMChange(change, editedBy)]
        }, editedBy);
        stores().crud.update('certification')(updatedCertification);
        return updatedCertification;
      }
    })
  },
  helpers: {
    manufacturer: (manufacturer, stores) => ({
      units: () => Object.values(stores().units).filter(u => u.manufacturerId === manufacturer._id),
      certifications: () => Object.values(stores().certifications).filter(c => c.manufacturerId === manufacturer._id),
      // products: () => Object.values(stores().products).filter(p => p.manufacturerId === manufacturer._id),
      person: () => _users.default[manufacturer.contact] || stores().persons[manufacturer.contact]
    }),
    unit: (unit, stores) => ({
      address: () => unit.geo ? unit.geo.formatted_address : unit.address,
      streetviewSrc: () => unit.geo ? `https://img-staging.gimme.eu/unsafe/640x360/'${encodeURIComponent(`maps.googleapis.com/maps/api/streetview?size=640x360&location=${unit.geo.formatted_address}`)}'&key=${KEY}` : '',
      manufacturer: () => stores().manufacturers[unit.manufacturerId],
      certifications: () => Object.values(stores().certifications).filter(c => c.unitId === unit._id),
      person: () => _users.default[unit.contact] || stores().persons[unit.contact] // products: () => Object.values(stores().products).filter(p => p.unitId === unit._id),

    }),
    certification: (certification, stores) => ({
      f,
      p,
      draft: () => certification.edits && certification.edits.length ? certification.edits.reduce((memo, {
        state
      }) => ({ ...memo,
        ...state
      }), certification) : certification,
      validityRange: () => `${certification.date} - ${f(expiry(certification.date))}`,
      expiry: () => f(expiry(certification.date)),
      startDate: () => (0, _dateFns.parse)(certification.date, 'yyyy.MM.dd', new Date()),
      expiryDate: () => expiry(certification.date),
      isValid: () => (0, _dateFns.isBefore)(new Date(), expiry(certification.date)),
      certificate: () => stores().certificates[certification.certificateId],
      unit: () => stores().units[certification.unitId],
      manufacturer: () => stores().manufacturers[certification.manufacturerId],
      products: () => Object.values(stores().products).filter(p => p.certificationId === certification._id),
      downloads: () => Object.values(stores().downloads).filter(d => d.certificationId === certification._id),
      aclRequests: () => Object.values(stores().aclRequests).filter(r => r.certificationId === certification._id),
      unrespondedAclRequests: () => Object.values(stores().aclRequests).filter(({
        certificationId,
        grants
      }) => certificationId === certification._id && !grants),
      tickets: () => Object.values(stores().tickets).filter(({
        certificationId
      }) => certificationId === certification._id),
      unrequestedChanges: () => [// ...(certification.edits
      //   ? certification.edits.filter(({ status }) => status === 'created')
      //   : []),
      ...Object.values(stores().products).filter(p => p.certificationId === certification._id && p.isValid).reduce((memo, p) => [...memo, ...(p.edits ? p.edits.filter(({
        status
      }) => status === 'created') : [])], [])]
    })
  }
};
exports.default = _default;